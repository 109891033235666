/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {forwardRef} from 'react';
import {Stack, TextField} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';

export interface ISetLimitFormValues {
	limitRub: number;
}

interface ISetLimitFormProps {
	submitFunc: (values: ISetLimitFormValues) => void;
	// eslint-disable-next-line react/require-default-props
	label?: string;
}

const SetLimitForm = forwardRef(
	({submitFunc, label = "Лимит в рублях"}: ISetLimitFormProps, ref) => {
		const SetDebtorFormSchema = Yup.object().shape({
			limitRub: Yup.number().required('Поле является обязательным'),
		});

		const formik = useFormik<ISetLimitFormValues>({
			initialValues: {
				limitRub: 1,
			},
			validationSchema: SetDebtorFormSchema,
			validateOnChange: false,
			validateOnBlur: false,
			onSubmit: (values: ISetLimitFormValues) => submitFunc(values),
		});

		const {touched, errors, handleSubmit, getFieldProps} = formik;

		React.useImperativeHandle(ref, () => formik);

		return (
			<FormikProvider value={formik}>
				<Form style={{width: '100%'}} autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={2}>
						<TextField
							type='number'
							fullWidth
							label={label}
							InputProps={{ inputProps: { min: 1} }}
							{...getFieldProps('limitRub')}
							error={Boolean(touched.limitRub && errors.limitRub)}
							helperText={touched.limitRub && errors.limitRub}
						/>
					</Stack>
				</Form>
			</FormikProvider>
		);
	}
);

export default SetLimitForm;
