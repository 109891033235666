/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {IIssueWithExpandUsers, ILongUser, UserType} from "../../../types";

export const fillFinancingRequestFeed = (issue: IIssueWithExpandUsers, user: ILongUser) => [
	{id: 1, title: 'Получение поставок с подтверждающими документами', complete: true},
	{
		id: 2,
		title: 'Утверждение списка поставок Фактором',
		complete: issue.additionalStepsInProgress || issue.acknowledgmentParticipant,
		action: user?.type === UserType.FACTOR && issue.acknowledgmentParticipant === null && !issue.additionalStepsInProgress
	},
	{
		id: 3,
		title: 'Согласование с Поставщиком',
		complete: issue.additionalStepsInProgress || issue.acknowledgmentAuthor,
		action: user?.type === UserType.SUPPLIER && !issue.additionalStepsInProgress && issue.acknowledgmentParticipant
	},
	{
		id: 4,
		title: 'Подтверждение списка поставок Дебитором',
		complete: !!issue.additionalStepsDone,
		action: [UserType.DEBTOR, UserType.FACTOR].includes(user?.type!) && issue.additionalStepsInProgress && !issue.additionalStepsDone
	},
	{
		id: 5,
		title: 'Согласие финансирования Фактором',
		complete: !!issue.additionalStepsDone && issue.acknowledgmentParticipant,
		action: user?.type === UserType.FACTOR && !!issue.additionalStepsDone && issue.acknowledgmentParticipant === null
	},
	{
		id: 6,
		title: 'Подтверждение Поставщиком',
		complete: !!issue.additionalStepsDone && issue.acknowledgmentAuthor,
		action: user?.type === UserType.SUPPLIER && !!issue.additionalStepsDone && issue.acknowledgmentAuthor === null && issue.acknowledgmentParticipant !== null
	},
	{id: 7, title: 'Финансирование поставок', complete: issue.finished},
];

export const fillFinancingProcessFeed = (issue: IIssueWithExpandUsers, user: ILongUser) => [
	{id: 1, title: 'Передача денег Поставщику', complete: issue.financingSteps.supplierFinancing,},
	{
		id: 2,
		title: 'Подтверждение получения денег',
		complete: issue.financingSteps.supplierFinancingApprove,
		action: user?.type === UserType.SUPPLIER && !issue.financingSteps.supplierFinancingApprove
	},
	{id: 3, title: 'Возврат денег Дебитором Фактору', complete: issue.financingSteps.debtorToFactorPayment},
	{
		id: 4,
		title: 'Подтверждение Фактором получения денег',
		complete: issue.financingSteps.debtorToFactorPaymentApprove,
		action: user?.type === UserType.FACTOR && issue.financingSteps.supplierFinancingApprove && !issue.financingSteps.debtorToFactorPaymentApprove
	},
	{id: 5, title: 'Выплата остатка Поставщику', complete: issue.financingSteps.supplierRemainFinancing},
	{
		id: 6,
		title: 'Подтверждение Поставщиком получения остатка',
		complete: issue.financingSteps.supplierRemainFinancingApprove,
		action: user?.type === UserType.SUPPLIER && !issue.financingSteps.supplierRemainFinancingApprove && issue.financingSteps.supplierFinancingApprove && issue.financingSteps.debtorToFactorPaymentApprove
	},
]
