/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Dialog, DialogContent, Divider, IconButton, Stack, Tooltip} from '@mui/material';
import {useStore} from 'effector-react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {createEvent, createStore} from 'effector';
import {Form, FormikProvider, useFormik} from 'formik';
import {GetIcon} from '../../../helpers/other/GetIcon';
import {IIssueWithExpandUsers} from '../../../../types';
import GrowTransition from '../../../helpers/transitions/GrowTransition';
import {addResponseCompanyCardFx} from '../../../../models/issues';
import CompanyCardModal from '../../company-card/CompanyCardModal';

export const setOpenAddResponseCompanyCardModal = createEvent<boolean>();
export const $addResponseCompanyCardModal = createStore({
	open: false,
}).on(setOpenAddResponseCompanyCardModal, (state, open) => ({...state, open}));

export const handleAddResponseCompanyCardModalOpen = () => setOpenAddResponseCompanyCardModal(true);
export const handleAddResponseCompanyCardModalClose = () =>
	setOpenAddResponseCompanyCardModal(false);

interface IAddResponseCompanyCardModalProps {
	chatInfo: IIssueWithExpandUsers;
	fetchResponsesList: () => void;
}

const AddResponseCompanyCardModal = ({
	                                     chatInfo,
	                                     fetchResponsesList,
                                     }: IAddResponseCompanyCardModalProps): React.ReactElement => {
	const {open} = useStore($addResponseCompanyCardModal);

	const formik = useFormik<{ text: string }>({
		initialValues: {
			text: '',
		},
		onSubmit: async ({text}) => {
			await addResponseCompanyCardFx({issueId: chatInfo.id, text});
			fetchResponsesList();
			handleModalClose();
		},
	});

	const {handleSubmit, getFieldProps, resetForm} = formik;

	const handleModalClose = () => {
		handleAddResponseCompanyCardModalClose();
		resetForm();
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '400px'}}}
			onBackdropClick={handleModalClose}
			TransitionComponent={GrowTransition}
			open={open}
		>
			<DialogContent sx={{p: 0, position: 'relative'}}>
				<FormikProvider value={formik}>
					<Form
						autoComplete='off'
						noValidate
						onSubmit={handleSubmit}
						style={{height: '100%'}}
					>
						<Stack sx={{position: 'absolute', top: 8, right: 8}}>
							<Tooltip title='Закрыть' placement='top' arrow>
								<IconButton color='inherit' component='span' onClick={handleModalClose}>
									{GetIcon(closeIcon)}
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack
							sx={{
								pt: 2,
								pb: 1,
								justifyContent: 'space-between',
							}}
						>
							<Stack sx={{pl: 2.5, pr: 1}}>
								<CompanyCardModal/>
							</Stack>
							<Divider sx={{mt: 2, mb: 0.5}}/>
							<Stack
								direction='row'
								sx={{
									height: '100%',
									alignItems: 'center',
									pl: 2.5,
									pr: 1,
								}}
							>
								<input
									{...getFieldProps('text')}
									placeholder='Сообщение...'
									style={{
										width: '100%',
										height: '100%',
										marginRight: '5px',
										backgroundColor: 'transparent',
										outline: 'none',
										border: 'none',
										color: 'inherit',
										fontSize: '16px',
									}}
								/>
								<Stack>
									<Tooltip title='Отправить' placement='top' arrow>
										<IconButton color='inherit' type='submit'>
											{GetIcon(sendIcon)}
										</IconButton>
									</Tooltip>
								</Stack>
							</Stack>
						</Stack>
					</Form>
				</FormikProvider>
			</DialogContent>
		</Dialog>
	);
};

export default AddResponseCompanyCardModal;
