/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {checkInnValidityFx, fetchDebtorsListFx} from './index';
import {checkInnValidity, fetchDebtorsList} from '../../api';

fetchDebtorsListFx.use(fetchDebtorsList);
checkInnValidityFx.use(checkInnValidity);
