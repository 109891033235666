/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef} from 'react';
import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import mathPlusIcon from '@iconify-icons/gg/math-plus';
import {useStore} from 'effector-react';
import {createDebtorWithFactorFx, createSupplierWithFactorFx} from 'models/issues';
import {IFormikRef, ILongUser} from 'types';
import Modal, {IModalRef} from 'components/helpers/modals/Modal';
import {GetIcon} from 'components/helpers/other/GetIcon';
import CreateIssueForm, {ICreateIssueFormValues} from './CreateIssueForm';
import {$user} from '../../../../models/auth';
import CustomButton from "../../../styled/customButton/CustomButton";

interface ICreateIssueModalProps {
	row: ILongUser;
	fetchIssuesList: () => void;
}

const CreateIssueModal = ({row, fetchIssuesList}: ICreateIssueModalProps): React.ReactElement => {
	const {user} = useStore($user);
	const createSupplierWithFactorPending = useStore(createSupplierWithFactorFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef = useRef<IFormikRef>(null);

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const submitFunc = async (values: ICreateIssueFormValues) => {
		if (user && 'type' in user) {
			if (['supplier', 'debtor'].includes(user.type)) {
				const valuesWithStrType = {...values, type: values.type.value};
				if (user.type === 'supplier') await createSupplierWithFactorFx(valuesWithStrType);
				if (user.type === 'debtor') await createDebtorWithFactorFx(valuesWithStrType);
				if (formikRef.current) formikRef.current.resetForm();
				handleModalClose();
				await fetchIssuesList();
			}
		}
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
	};

	const modalContent = {
		toggleChildren: (
			<Tooltip title='Создать обсуждение' placement='top' arrow>
				<IconButton color='inherit' onClick={handleModalOpen}>
					{GetIcon(mathPlusIcon)}
				</IconButton>
			</Tooltip>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Создать обсуждение
					</Typography>
				</Stack>
				<CreateIssueForm ref={formikRef} row={row} submitFunc={submitFunc}/>
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{mt: 1, width: '100%'}}>
				<CustomButton
					onClick={handleModalClose}
				>
					Закрыть
				</CustomButton>
				<CustomButton
					onClick={handleSubmit}
				>
					Создать
				</CustomButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
			paperSx={{width: '500px'}}
		/>
	);
};

export default CreateIssueModal;
