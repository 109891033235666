/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, IconButton} from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

interface ITablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

const TablePaginationActions = ({
	                                count,
	                                page,
	                                rowsPerPage,
	                                onPageChange,
                                }: ITablePaginationActionsProps): React.ReactElement => {
	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{flexShrink: 0, ml: 2.5}}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
				<FirstPageIcon/>
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0}>
				<KeyboardArrowLeft/>
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			>
				<KeyboardArrowRight/>
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
			>
				<LastPageIcon/>
			</IconButton>
		</Box>
	);
};

export default TablePaginationActions;
