/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {useStore} from 'effector-react';
import {
	Avatar,
	Badge, Button,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Menu,
	Stack,
	Typography,
} from '@mui/material';
import notification from "@iconify/icons-eva/bell-outline";
import {Icon} from "@iconify/react";
import {DateTime} from 'luxon';
import {$notifications} from "../../../models/notifications";
// @ts-ignore
import UserAvatar from '../../../assets/images/userLogo.svg';
import {openChat} from "../../features/chat/Chat";
import {deleteNotificationFx} from "../../../models/profile";
import {$user} from "../../../models/auth";

const NotificationsDropdown = (): React.ReactElement => {
	const {user} = useStore($user);
	const notifications = useStore($notifications);
	const unread = notifications.filter(notif => notif.unread).length;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpen = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => setAnchorEl(null);

	return (
		<>
			<Badge sx={{mr: 2, mt: 0.25}} badgeContent={unread} color="error">
				<IconButton color='inherit' onClick={handleOpen}>
					<Icon icon={notification}/>
				</IconButton>
			</Badge>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						width: '360px',
						top: '70px !important',
					},
				}}
			>
				{!user?.tgChat && <Button sx={{m: 1}} href='https://t.me/sgtk_factoring_bot'>Включить уведомления в Телеграме</Button>}
				{user?.tgChat && <Typography sx={{m: 1}} variant='h4'>Телеграм бот подключен</Typography>}
				<Typography sx={{m: 1}} variant='h4'>{unread > 0 ? 'Уведомления' : 'Нет уведомлений'}</Typography>
				{unread > 0 && <Divider/>}
				{unread > 0 && <List sx={{width: '100%', maxHeight: 500, maxWidth: 360}}>
					{notifications.map((notif, i, arr) => (
						<>
							<ListItem sx={{
								transform: 'scale(1)',
								transition: '0.25s',
								':hover': {
									cursor: 'pointer',
									transform: 'scale(1.05)',
								},
							}} onClick={() => {
								deleteNotificationFx({id: notif.id})
								openChat(notif.issueId);
								handleClose();
							}} alignItems="flex-start">
								<ListItemAvatar>
									<Avatar src={UserAvatar}/>
								</ListItemAvatar>
								<ListItemText
									primaryTypographyProps={{fontSize: '1.25rem', }}
									primary={notif.author}
									secondary={
										<Stack>
											<Typography
												sx={{fontSize: '1rem', display: 'inline', mb: 0.5}}
												variant="body2"
											>
												{notif.text}
											</Typography>
											<Typography
												sx={{fontSize: '1rem', display: 'inline'}}
												variant="body2"
											>
												{DateTime.fromISO(notif.createdAt).toFormat('dd.MM.yyyy hh:mm')}
											</Typography>
										</Stack>
									}
								/>
							</ListItem>
							{(i !== arr.length - 1) && <Divider component="li" variant='inset' sx={{borderWidth: 2}}/>}
						</>
					))}
                </List>}
			</Menu>
		</>
	);
};

export default NotificationsDropdown;
