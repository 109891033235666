/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import * as Yup from 'yup';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {Stack, TextField} from '@mui/material';
import {ICompanyCard} from 'types';
import {patchUserCompanyCardFx} from 'models/profile';

type ICompanyCardEditFormValues = Pick<ICompanyCard,
	'name' | 'type' | 'site' | 'kpp' | 'ogrn' | 'directorFIO' | 'directorFIORP' | 'bankDetails' | 'locationAddress'>;

export const companyCardEditFormFields: Array<keyof ICompanyCardEditFormValues> = [
	'name', 'type', 'site', 'kpp', 'ogrn', 'directorFIO', 'directorFIORP', 'bankDetails', 'locationAddress'
];

export const companyCardEditFormFieldsTranslate = {
	name: 'Наименование компании в карточке',
	type: 'Правовая форма организации',
	site: 'Сайт',
	kpp: 'КПП',
	ogrn: 'ОГРН',
	directorFIO: 'ФИО директора',
	directorFIORP: 'ФИО директора в родительном падеже',
	bankDetails: 'Банковские реквизиты',
	locationAddress: 'Адрес',
};

interface ICompanyCardEditFormProps {
	companyCard: ICompanyCard;
	fetchUserCompanyCard: Function;
}

const CompanyCardEditForm = forwardRef(({companyCard, fetchUserCompanyCard}: ICompanyCardEditFormProps, ref) => {
	const CompanyCardEditFormSchema = Yup.object().shape({
		name: Yup.string(),
		type: Yup.string(),
		site: Yup.string(),
		kpp: Yup.string(),
		ogrn: Yup.string(),
		directorFIO: Yup.string(),
		directorFIORP: Yup.string(),
		bankDetails: Yup.string(),
		locationAddress: Yup.string(),
	});

	const formik = useFormik<ICompanyCardEditFormValues>({
		initialValues: {
			name: '',
			type: '',
			site: '',
			kpp: '',
			ogrn: '',
			directorFIO: '',
			directorFIORP: '',
			bankDetails: '',
			locationAddress: '',
		},
		validationSchema: CompanyCardEditFormSchema,
		onSubmit: async values => {
			await patchUserCompanyCardFx(values);
			setTimeout(() => {
				resetForm();
			}, 300);
			fetchUserCompanyCard();
		},
	});

	const {errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm} = formik;

	useImperativeHandle(ref, () => ({...formik}));

	const customSetFieldValue = <ICompanyCardKey extends keyof ICompanyCard>(
		property: ICompanyCardKey
	) => {
		if (companyCard[property]) setFieldValue(property, companyCard[property]);
	};

	useEffect(() => {
		companyCardEditFormFields.map(key => customSetFieldValue(key));
	}, [companyCard]);

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
				<Stack spacing={2.5}>
					{companyCardEditFormFields
						.map(f => ({
							label: companyCardEditFormFieldsTranslate[f],
							field: f,
						}))
						.map(item => (
							<TextField
								key={item.field}
								fullWidth
								label={item.label}
								{...getFieldProps(item.field)}
								error={Boolean(touched[item.field] && errors[item.field])}
								helperText={touched[item.field] && errors[item.field]}
							/>
						))}
				</Stack>
			</Form>
		</FormikProvider>
	);
});

export default CompanyCardEditForm;
