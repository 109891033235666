/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from "react";
import * as Yup from "yup";
import {Form, FormikProvider, useFormik} from "formik";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {Box} from "@mui/system";
import {useParams} from "react-router-dom";
import {useStore} from "effector-react";
import {$modals} from "../../../../models/modals";
import {closeSignNotificationRequestModal} from "../../../../models/modals/functions";
import CustomDialogTitle from "../../../helpers/other/CustomDialogTitle";
import CustomButton from "../../../styled/customButton/CustomButton";
// @ts-ignore
import {ReactComponent as PatchIcon} from "../../../../assets/images/buttons-icons/patch.svg";
import {IDebtor} from "../../../../models/suppliers";
import FormikAutocomplete from "../../../helpers/forms/FormikAutocomplete";
// @ts-ignore
import moduleStyles from './ChangePasswordModal.module.scss';
import {addResponseToIssueFx, createSupplierWithFactorFx} from "../../../../models/issues";
import {IssueType} from "../../../../types/enum/issueType";

const styles = moduleStyles as any;

const SignNotificationRequestModal = ({
	                                      debtors,
	                                      fetchUserInfo
                                      }: { debtors: IDebtor[], fetchUserInfo: Function }): React.ReactElement => {
	const {id} = useParams();
	const {SignNotificationRequestModal: modalState} = useStore($modals);

	const SignNotificationRequestSchema = Yup.object().shape({
		debtor: Yup.object().nullable().required('Поле является обязательным'),
	});

	const formik = useFormik<{ debtor: IDebtor }>({
		initialValues: {
			debtor: null as unknown as IDebtor
		},
		validationSchema: SignNotificationRequestSchema,
		onSubmit: async data => {
			if (data.debtor)
				createSupplierWithFactorFx({
					title: 'Подпись Уведомления',
					description: 'Подача Уведомления для подписи',
					factor: id!,
					type: IssueType.SIGN_NOTIFICATION,
					notifiers: [data.debtor.inn]
				}).then((issue) => {
					addResponseToIssueFx({id: issue.id, text: 'Добрый день. Прошу направить Уведомление для подписи'});
					fetchUserInfo();
				});
			closeModal();
		},
	});

	const {handleSubmit, resetForm} = formik;

	const closeModal = () => {
		closeSignNotificationRequestModal();
		setTimeout(() => {
			resetForm();
		}, 300);
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '100%', maxWidth: '700px'}}}
			onClose={closeModal}
			onBackdropClick={closeModal}
			aria-labelledby='customized-dialog-title'
			open={modalState.open}
		>
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
					<CustomDialogTitle onClose={closeModal}>Подпись Уведомления</CustomDialogTitle>
					<DialogContent sx={{p: 0}}>
						<Box className={styles.content}>
							<Box>
								<FormikAutocomplete textFieldProps={{label: 'Дебитор'}} sx={{p: 1}}
								                    getOptionLabel={option => option.name}
								                    name='debtor'
								                    options={debtors}/>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions className={styles.actions}>
						<CustomButton withStartIcon type='submit'>
							<PatchIcon/>
							<Box>
								<span>Запросить</span>
							</Box>
						</CustomButton>
					</DialogActions>
				</Form>
			</FormikProvider>
		</Dialog>
	);
};

export default SignNotificationRequestModal;