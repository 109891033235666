/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {$customizer, setThemeMode} from './index';
import {ActiveModeEnum} from './types';

$customizer.on(setThemeMode, (state, activeMode: ActiveModeEnum) => ({
	...state,
	activeMode,
}));
