/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	addFileRequestToDiadoc,
	fetchDiadocDocuments,
	fetchDiadocDocumentTypes,
	fetchDiadocOrgs,
	fetchDiadocPartners
} from "../../api";
import {
	addFileRequestToDiadocFx,
	fetchDiadocDocumentsFx,
	fetchDiadocDocumentTypesFx,
	fetchDiadocOrgsFx,
	fetchDiadocPartnersFx
} from "./index";

addFileRequestToDiadocFx.use(addFileRequestToDiadoc);
fetchDiadocOrgsFx.use(fetchDiadocOrgs);
fetchDiadocPartnersFx.use(fetchDiadocPartners);
fetchDiadocDocumentsFx.use(fetchDiadocDocuments);
fetchDiadocDocumentTypesFx.use(fetchDiadocDocumentTypes);