/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useRef, useState} from 'react';
import {
	Box,
	Dialog,
	DialogContent,
	Divider,
	IconButton,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	TextField,
	Tooltip,
	Typography
} from '@mui/material';
import {useStore} from 'effector-react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {createEvent, createStore} from 'effector';
import {Form, FormikProvider, useFormik} from 'formik';
import {DateTime} from "luxon";
import * as Yup from "yup";
import {IFormikRef} from "../../../../types";
import GrowTransition from "../../../helpers/transitions/GrowTransition";
import {GetIcon} from "../../../helpers/other/GetIcon";
import {addShipmentsFx, fetchSupplierDebtorShipmentsFx, IShipmentWithFiles} from "../../../../models/shipments";
import FormikAutocomplete from "../../../helpers/forms/FormikAutocomplete";
import {addResponseFileToIssueFx, addResponseShipmentsFx, createSupplierWithFactorFx} from "../../../../models/issues";
import {IssueType} from "../../../../types/enum/issueType";
import {PickedShipment} from "../../../../shared/variables";
import LoadAndParseShipmentsForm, {ILoadAndParseShipmentsFormValues} from "./LoadAndParseShipmentsForm";
import {parseFileToJsonFx} from "../../../../models/docPy";
import PaginationTable from "../../../helpers/table/PaginationTable";
import {createFormData, getDeclension} from "../../../../shared/functions";
import CustomButton from "../../../styled/customButton/CustomButton";
//@ts-ignore
import {ReactComponent as SelectIcon} from "../../../../assets/images/buttons-icons/select.svg";
//@ts-ignore
import moduleStyles from "../../../helpers/files/AddFileModal.module.scss";

const styles = moduleStyles as any;

export const setOpenRequestFinancingModal = createEvent<{ open: boolean, debtor: string, factor: string }>();
export const $requestFinancingModal = createStore({
	open: false,
	debtor: null as unknown as string,
	factor: null as unknown as string,
}).on(setOpenRequestFinancingModal, (state, payload) => ({...state, ...payload}));

export const handleRequestFinancingModalOpen = (debtor: string, factor: string) => setOpenRequestFinancingModal({
	open: true,
	debtor,
	factor
});
export const handleRequestFinancingModalClose = () =>
	setOpenRequestFinancingModal({open: false, debtor: null as unknown as string, factor: null as unknown as string});

const RequestFinancingModal = (): React.ReactElement => {
	const {open, debtor, factor} = useStore($requestFinancingModal);
	const [shipments, setShipments] = useState<IShipmentWithFiles[]>([]);
	const [finished, setFinished] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [filteredRowCount, setFilteredRowCount] = useState(0);
	const [parsedShipments, setParsedShipments] = useState<(IShipmentWithFiles & { id: string })[]>([]);
	const ref = useRef<IFormikRef>(null);

	const mapArrToObj = (fieldsArr: string[], idx: number) => ({
		id: idx.toString(),
		shipmentCode: fieldsArr[0],
		debtorName: fieldsArr[1],
		debtorInn: fieldsArr[2],
		monetaryClaim: parseInt(fieldsArr[3], 10),
		firstPayment: parseInt(fieldsArr[4], 10),
		agreementPaymentDate: fieldsArr[5],
		contractPaymentDate: fieldsArr[6],
		actShipmentDate: fieldsArr[7],
		actNumber: fieldsArr[8],
		contractNumber: fieldsArr[9],
		contractDate: fieldsArr[10],
		invoiceNumber: fieldsArr[11],
		invoiceDate: fieldsArr[12]
	})

	const steps = [
		{
			label: 'Загрузка поставок',
			// eslint-disable-next-line react/no-unstable-nested-components
			component: () => <LoadAndParseShipmentsForm ref={ref}
			                                            val={Math.random()}
			                                            submitFunc={async (values: ILoadAndParseShipmentsFormValues) => {
				                                            if (values.file) {
					                                            const formData = new FormData();
					                                            formData.set('file', values.file);
					                                            const response = await parseFileToJsonFx({form: formData});
					                                            const data = response.datas.slice(1).map(mapArrToObj);
					                                            const filteredData = data.filter((shipment: { debtorInn: string | any[]; }) => shipment.debtorInn.length > 0)
					                                            setParsedShipments(filteredData);
					                                            setFilteredRowCount(data.length - filteredData.length);
				                                            }
			                                            }}/>,
			onNext: () => ref?.current?.handleSubmit()
		},
		{
			label: 'Верификация загрузки',
			subLabel: parsedShipments.length > 10 ? `Удалено ${filteredRowCount} строк\nЗагружено ${parsedShipments.length} строк\nПоказаны первые 10 записей` : '',
			maxWidth: '75vw',
			// eslint-disable-next-line react/no-unstable-nested-components
			component: () => <PaginationTable selectable={false} options={[
				{
					key: 'shipmentCode',
					title: 'Код поставки',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'debtorName',
					title: 'Наименование дебитора',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'debtorInn',
					title: 'ИНН дебитора',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'monetaryClaim',
					title: 'Сумма уступленного денежного требования, руб.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'firstPayment',
					title: 'Размер первого платежа',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'agreementPaymentDate',
					title: 'Дата оплаты по Соглашению',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'contractPaymentDate',
					title: 'Дата оплаты по Договору',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'actShipmentDate',
					title: 'Дата отгрузки согласно накладной/акта и др.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'actNumber',
					title: 'Номер накладной/акта и др.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'contractNumber',
					title: 'Номер Договора',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'contractDate',
					title: 'Дата Договора',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'invoiceNumber',
					title: 'Номер счет-фактуры',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'invoiceDate',
					title: 'Дата счет-фактуры',
					sx: {textAlign: 'center'},
					isPopover: true,
				}
			]} rows={parsedShipments.slice(0, 10)
				.map(shipment => ({
					...shipment,
					agreementPaymentDate: DateTime.fromFormat(shipment.agreementPaymentDate, 'dd.MM.yyyy').toFormat('dd.MM.yyyy'),
					contractPaymentDate: DateTime.fromFormat(shipment.contractPaymentDate,'dd.MM.yyyy').toFormat('dd.MM.yyyy'),
					actShipmentDate: DateTime.fromFormat(shipment.actShipmentDate,'dd.MM.yyyy').toFormat('dd.MM.yyyy'),
					contractDate: DateTime.fromFormat(shipment.contractDate,'dd.MM.yyyy').toFormat('dd.MM.yyyy'),
					invoiceDate: shipment.invoiceDate && shipment.invoiceDate.length > 0 ? DateTime.fromFormat(shipment.invoiceDate,'dd.MM.yyyy').toFormat('dd.MM.yyyy') : ''
				}))}/>,
			onNext: () => {
			}
		}
	]

	useEffect(() => {
		setFinished(false);
		setActiveStep(0);
	}, [open]);

	const handleNext = async () => {
		if (activeStep === steps.length - 1) {
			if (parsedShipments && parsedShipments.length > 0) {
				const response = await addShipmentsFx({
					debtorId: debtor,
					data: parsedShipments.map((parsedShipment: any) => ({
						...parsedShipment,
						monetaryClaim: parsedShipment.monetaryClaim * 100,
						firstPayment: parsedShipment.firstPayment * 100
					}))
				});
				await formik.setFieldValue('pickedShipments', response.map(shipment => ({
					name: `Поставка от ${DateTime.fromISO(shipment.actShipmentDate).toFormat('dd.MM.yyyy')} (Номер акта ${shipment.actNumber})`,
					...shipment,
				})));
			}
			setFinished(true);
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		steps[activeStep].onNext();
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const fetchShipments = async () => {
		const response = await fetchSupplierDebtorShipmentsFx({id: debtor});
		setShipments(response.filter(shipment => shipment.status === null));
	}

	useEffect(() => {
		if (debtor !== null)
			Promise.all([fetchShipments()]).then();
	}, [debtor])

	const RequestFinancingSchema = Yup.object().shape({
		text: Yup.string().required('Поле является обязательным'),
		pickedShipments: Yup.array().min(1, 'Поле является обязательным'),
	});
	const formik = useFormik<{ text: string, files: FileList | null, pickedShipments: PickedShipment[] }>({
		initialValues: {
			text: `Добрый день. Направляю пакет документов для финансирования.`,
			files: null as unknown as FileList,
			pickedShipments: [],
		},
		validationSchema: RequestFinancingSchema,
		onSubmit: ({text, files, pickedShipments}) => {
			if (factor && debtor) {
				createSupplierWithFactorFx({
					title: `Запрос финансирования`,
					description: ``,
					factor,
					type: IssueType.REQUEST_FINANCING,
					debtor
				}).then(newIssue => {
					addResponseShipmentsFx({
						issueId: newIssue.id,
						text,
						shipments: pickedShipments,
						debtorId: debtor
					}).then(async r => {
						if (files)
							await Promise.all(Array.from(files).map(file => addResponseFileToIssueFx({
								issueId: newIssue.id,
								data: createFormData(file, '')
							})));
						handleModalClose();
					})
				});
			}
		},
	});

	const {handleSubmit, getFieldProps, resetForm, values, setFieldValue} = formik;

	const handleModalClose = () => {
		handleRequestFinancingModalClose();
		resetForm();
		setParsedShipments([]);
	};

	return (
		<>
			{/*<UserAction disabled={false} blocked={blocked} text="Запрос финансирования"*/}
			{/*            reusable*/}
			{/*            onClick={handleRequestFinancingModalOpen}/>*/}
			<Dialog
				PaperProps={{
					sx: {
						maxWidth: (activeStep <= steps.length - 1 && 'maxWidth' in steps[activeStep]) ? steps[activeStep]?.maxWidth : '25vw',
						width: (activeStep <= steps.length - 1 && 'maxWidth' in steps[activeStep]) ? steps[activeStep]?.maxWidth : '25vw',
						transition: 'max-width 0.5s'
					}
				}}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={open}
			>
				<DialogContent sx={{p: 2, position: 'relative'}}>
					{!finished && <Stepper activeStep={activeStep} orientation="vertical">
						{steps.map((step, index) => (
							<Step key={step.label}>
								<StepLabel>
									{step.label}
									{step.subLabel && (
										<Typography sx={{fontSize: '1.25rem'}}>
											{step.subLabel}
										</Typography>
									)}
								</StepLabel>
								<StepContent TransitionProps={{mountOnEnter: true, unmountOnExit: false}}>
									{step.component()}
									<Box sx={{mt: 2}}>
										<Stack direction='row' spacing={2}>
											<CustomButton
												onClick={() => setFinished(true)}
											>
												Пропустить
											</CustomButton>
											<CustomButton
												onClick={handleNext}
											>
												{index === steps.length - 1 ? 'Закончить' : 'Следующий шаг'}
											</CustomButton>
											{index !== 0 && <CustomButton
                                                onClick={handleBack}
                                            >
                                                Назад
                                            </CustomButton>}
										</Stack>
									</Box>
								</StepContent>
							</Step>
						))}
                    </Stepper>}
					{finished && <FormikProvider value={formik}>
                        <Form
                            autoComplete='off'
                            noValidate
                            onSubmit={handleSubmit}
                            style={{height: '100%'}}
                        >
                            <Stack sx={{position: 'absolute', top: 8, right: 8}}>
                                <Tooltip title='Закрыть' placement='top' arrow>
                                    <IconButton color='inherit' component='span' onClick={handleModalClose}>
										{GetIcon(closeIcon)}
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                            <Stack
                                sx={{
									pt: 2,
									pb: 1,
									justifyContent: 'space-between',
								}}
                            >
                                <Stack sx={{width: '90%', pl: 2.5, pr: 1}}>
									{parsedShipments.length === 0 ?
										<FormikAutocomplete groupByFunc={(option) => option.debtorName} multiple
										                    name='pickedShipments'
										                    options={shipments.map(shipment => ({
											                    name: `Поставка от ${DateTime.fromISO(shipment.actShipmentDate).toFormat('dd.MM.yyyy')} (Номер акта ${shipment.actNumber})`, ...shipment
										                    }))}/>
										: <Typography sx={{fontSize: '1.25rem'}}>Загружено
											{` ${getDeclension(parsedShipments.length, ['поставка', 'поставки', 'поставок'])}`}</Typography>}
                                </Stack>
                                <Box className={styles.content}>
                                    <Box sx={{padding: '1rem 2.1rem 0 1.3rem !important'}}
                                         className={styles.selectFileContainer}>
                                        <Box className={styles.selectFileInfo}>
                                            <Typography>{values.files ? `Выбрано ${values.files.length} файлов` : 'Файлы не выбраны'}</Typography>
                                        </Box>
                                        <input
                                            id='import-button-file-add-financing-files'
                                            type='file'
                                            multiple
                                            onChange={event => {
												if (event.currentTarget.files) {
													setFieldValue('files', event.currentTarget.files);
												}
											}}
                                        />
										{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                        <label htmlFor='import-button-file-add-financing-files'>
											<span className={styles.selectFileBtn}>
												<SelectIcon/>
												<div>Выбрать</div>
											</span>
                                        </label>
                                    </Box>
                                </Box>
                                <Divider sx={{mt: 2, mb: 0.5}}/>
                                <Stack
                                    direction='row'
                                    sx={{
										height: '100%',
										alignItems: 'center',
										pl: 2.5,
										pr: 1,
									}}
                                >
									<TextField
										multiline
										minRows={2}
										maxRows={3}
										{...getFieldProps('text')}
                                        style={{
											width: '100%',
											height: '100%',
											marginRight: '5px',
											backgroundColor: 'transparent',
											outline: 'none',
											border: 'none',
											color: 'inherit',
											fontSize: '16px',
										}}
                                    />
                                    <Stack>
                                        <Tooltip title='Отправить' placement='top' arrow>
                                            <IconButton color='inherit' type='submit'>
												{GetIcon(sendIcon)}
                                            </IconButton>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>}
				</DialogContent>
			</Dialog>
		</>
	);
};

export default RequestFinancingModal;
