/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {DateTime} from 'luxon';
import React from "react";
import {ILongUser, ISmallUser} from '../types';

export const getFileExt = (file: File | null, filename?: string): string => {
	const _filename = file ? file.name : filename;
	return (_filename as string).split('.').pop()!;
};

export function useOnceCall(cb: Function, condition = true) {
	const isCalledRef = React.useRef(false);

	React.useEffect(() => {
		if (condition && !isCalledRef.current) {
			isCalledRef.current = true;
			cb();
		}
	}, [cb, condition]);
}

export const strDateToDateTime = (date: string, option: number) => {
	switch (option) {
		case 0: {
			// ISO
			return DateTime.fromISO(date);
		}
		case 1: {
			// dd.MM.yyyy
			return DateTime.fromISO(date.split('.').reverse().join('-'));
		}
		default:
			return DateTime.now();
	}
};

export const createFormData = (file: File, description: string) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('description', description);
	return formData;
}

export const createShipmentAttachmentFormData = (fileType: string, file: File) => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('fileType', fileType);
	return formData;
}

export const getNDAFile = () => new File(['Документ НДА'], 'НДА.txt', {
	type: 'text/plain',
});

export const getCustomFile = (issueId: string, file: File, description: string) => {
	const formData = createFormData(file, description);
	return {
		issueId,
		data: formData,
	};
};

export const blobToFile = (blob: Blob, fileName: string): File => new File([blob], fileName, {
	lastModified: Date.now(),
	type: blob.type
});

export const downloadFile = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
};

export const formatBytes = (bytes: number, decimals = 2) => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const getDeclension = (count: number, words: string[]) => {
	const [one, few, many] = words;
	const isFactional = Math.round(count) !== count;
	let declension = many;
	if (isFactional) {
		declension = few;
	} else {
		const units = Math.abs(count % 10);
		const tens = Math.abs(count % 100);
		if (units === 1 && tens !== 11) {
			declension = one;
		} else if (units >= 2 && units <= 4 && (tens < 10 || tens >= 20)) {
			declension = few;
		}
	}
	return `${count} ${declension}`;
}

export const checkUserIsDebtorOrFactor = (user: ILongUser | ISmallUser | null) =>
	user && 'type' in user && (user.type === 'debtor' || user.type === 'supplier');
