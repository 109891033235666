/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {apiRequest, IData} from '../index';
import {
	IAddResponseFileToIssueParams,
	ICreateIssueParams,
	IDownloadCompanyCardSlotFileParams,
	IDownloadResponseFileParams,
	IFetchIssuesListResponse,
	IFetchResponsesListResponse,
	IFile,
	IIssue,
	IIssueCompanyCardResponse,
	IIssueDebtorsResponse,
	IIssueFileResponse,
	IIssueFileResponseFullStringParams,
	IIssueResponseFullStringParams,
	IIssueShipmentsResponse,
	IIssueWithExpandUsers,
	IReplaceFileParams,
	IReplaceSignedFileInIssueParams,
} from '../../types';
import {IDebtor} from "../../models/suppliers";
import {PickedShipment} from "../../shared/variables";

export * from './completeIssue';

export const fetchIssuesList = async (params: { participantId?: string; authorId?: string }) => {
	const response = await apiRequest<IData<IFetchIssuesListResponse>>({
		url: '/api/issue/list',
		method: 'GET',
		params,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchUserFinancingIssuesList = async () => {
	const response = await apiRequest<IData<IIssueWithExpandUsers[]>>({
		url: '/api/issue/financingList',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchIssue = async ({issueId}: { issueId: string }) => {
	const response = await apiRequest<IData<IIssueWithExpandUsers>>({
		url: `/api/issue/${issueId}`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const createSupplierWithFactor = async (data: ICreateIssueParams) => {
	const response = await apiRequest<IData<IIssue>>({
		url: '/api/issue/createSupplierWithFactor',
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const createDebtorWithFactor = async (data: ICreateIssueParams) => {
	const response = await apiRequest<IData<IIssue>>({
		url: '/api/issue/createDebtorWithFactor',
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchResponsesList = async ({issueId}: { issueId: string }) => {
	const response = await apiRequest<IData<IFetchResponsesListResponse>>({
		url: `/api/issue/${issueId}/responses`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const replaceFile = async (data: IReplaceFileParams) => {
	const response = await apiRequest<IFile>({
		url: `/api/issue/${data.issueId}/${data.responseId}/${data.attachmentId}/replace`,
		method: 'POST',
		data: data.formData,
	});
	return response.data;
};

export const addResponseToIssue = async ({id, text}: { id: string; text: string }) => {
	const response = await apiRequest<IData<IIssueResponseFullStringParams>>({
		url: `/api/issue/${id}/addResponse`,
		method: 'POST',
		data: {text},
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addResponseFileToIssue = async ({issueId, data}: IAddResponseFileToIssueParams) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/issue/${issueId}/addResponseFile`,
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addResponseSignedFileToIssue = async ({issueId, data}: IAddResponseFileToIssueParams) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/issue/${issueId}/addResponseSignedFile`,
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const replaceSignedFileInIssue = async ({
	                                               issueId,
	                                               issueResponseId,
	                                               attachmentId,
	                                               data
                                               }: IReplaceSignedFileInIssueParams) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/issue/${issueId}/${issueResponseId}/${attachmentId}/replaceSignedFile`,
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const makeFileSignedInIssue = async ({
	                                            issueId,
	                                            issueResponseId,
	                                            attachmentId,
	                                            data
                                            }: IReplaceSignedFileInIssueParams) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/issue/${issueId}/${issueResponseId}/${attachmentId}/makeFileSigned`,
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const downloadResponseFile = async ({
	                                           issueId,
	                                           responseId,
	                                           fileId,
                                           }: IDownloadResponseFileParams) => {
	const response = await apiRequest<Blob>({
		url: `/api/issue/${issueId}/response/${responseId}/file/${fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	return response.data;
};

export const setLimit = async ({issueId, limit}: { issueId: string; limit: number }) => {
	const response = await apiRequest<IData<IIssue>>({
		url: `/api/issue/${issueId}/setLimit`,
		method: 'POST',
		data: {limit},
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addResponseCompanyCard = async ({
	                                             issueId,
	                                             text,
                                             }: {
	issueId: string;
	text: string;
}) => {
	const response = await apiRequest<IData<IIssueCompanyCardResponse>>({
		url: `/api/issue/${issueId}/addResponseCompanyCard`,
		method: 'POST',
		data: {text},
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addResponseDebtors = async ({
	                                         issueId,
	                                         text,
	                                         debtors
                                         }: {
	issueId: string;
	text: string;
	debtors: IDebtor[];
}) => {
	const response = await apiRequest<IData<IIssueDebtorsResponse>>({
		url: `/api/issue/${issueId}/addResponseDebtors`,
		method: 'POST',
		data: {text, debtors},
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addResponseShipments = async ({
	                                           issueId,
	                                           text,
	                                           shipments,
	                                           debtorId
                                           }: {
	issueId: string;
	text: string;
	shipments: PickedShipment[];
	debtorId: string;
}) => {
	const response = await apiRequest<IData<IIssueShipmentsResponse>>({
		url: `/api/issue/${issueId}/addResponseShipments`,
		method: 'POST',
		data: {text, shipments, debtorId},
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const downloadCompanyCardSlotFile = async ({
	                                                  issueId,
	                                                  responseId,
	                                                  slotId,
	                                                  fileId,
                                                  }: IDownloadCompanyCardSlotFileParams) => {
	const response = await apiRequest<Blob>({
		url: `/api/issue/${issueId}/response/${responseId}/slot/${slotId}/file/${fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	return response.data;
};

export const createWithAdmin = async (data: Omit<ICreateIssueParams, 'factor' | 'type'>) => {
	const response = await apiRequest<IData<IIssue>>({
		url: '/api/issue/createWithAdmin',
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchIncomeFinalIssueResponses = async () => {
	const response = await apiRequest<IData<IIssueFileResponse[]>>({
		url: `/api/issue/incomeFinalResponses`,
		method: 'GET'
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchOutcomeFinalIssueResponses = async () => {
	const response = await apiRequest<IData<IIssueFileResponse[]>>({
		url: `/api/issue/outcomeFinalResponses`,
		method: 'GET'
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};