/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEvent, createStore} from 'effector';

interface Modal {
	open: boolean;
	externalId?: string;
	replaceMode?: boolean;
	issueId?: string;
	responseId?: string;
	attachmentId?: string;
}

interface ModalsState {
	[key: string]: Modal;
}

export const defaultState = {
	SuppliesLoadingModal: {
		open: false,
	},
	AddFileModal: {
		open: false,
		replaceMode: false
	},
	ChangeCompanyCardModal: {
		open: false,
	},
	ChangePasswordModal: {
		open: false,
	},
	SignNotificationRequestModal: {
		open: false
	}
};

export const toggleModal = createEvent<{ componentName: string; open: boolean, externalId?: string, replaceMode?: boolean, issueId?: string, responseId?: string, attachmentId?: string }>();

export const $modals = createStore<ModalsState>(defaultState);
