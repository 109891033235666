/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {set} from 'local-storage';
import {useStore} from 'effector-react';
import {useNavigate} from 'react-router-dom';
import {Box, FormControlLabel, IconButton, Menu, Stack, Switch, Typography,} from '@mui/material';
import {USER_THEME_MODE} from 'shared/variables';
import {$customizer, setThemeMode} from 'models/customizer';
import {$user, logout} from 'models/auth';
import Spinner from 'components/helpers/other/Spinner';
// @ts-ignore
import UserAvatar from '../../../assets/images/userLogo.svg';
import CustomButton from "../../styled/customButton/CustomButton";

const ProfileDropdown = (): React.ReactElement => {
	const navigate = useNavigate();

	const {user} = useStore($user);
	const customizer = useStore($customizer);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleOpen = async (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => setAnchorEl(null);

	const handleToggleTheme = (event: any) => {
		const mode = event.target.checked ? 'dark' : 'light';
		setThemeMode(mode);
		set(USER_THEME_MODE, mode);
	};

	const handleLogout = async () => {
		navigate('/login', {replace: true});
		await logout();
	};

	return (
		<>
			<IconButton color='inherit' onClick={handleOpen}>
				<Stack alignItems='center'>
					<Box component='img' src={UserAvatar} sx={{width: 30, height: 30}}/>
				</Stack>
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				sx={{
					'& .MuiMenu-paper': {
						width: '385px',
						right: 0,
						top: '70px !important',
					},
					'& .MuiList-padding': {p: 4},
				}}
			>
				<Stack spacing={2}>
					{!user || !('type' in user) ? (
						<Spinner/>
					) : (
						<Stack direction='row' alignItems='center'>
							<Box component='img' src={UserAvatar} sx={{width: 90, height: 90}}/>
							<Box sx={{ml: 2}}>
								<Typography variant='h4' sx={{lineHeight: '1.235', fontSize: '1.25rem'}}>
									{user.name || 'Имя не указано'}
								</Typography>
								<Typography variant='h4' sx={{lineHeight: '1.235', fontSize: '1.25rem'}}>
									{user.email || 'Почта не указана'}
								</Typography>
								<CustomButton onClick={() => {
									navigate("/profile");
									handleClose();
								}}>Профиль</CustomButton>
							</Box>
						</Stack>
					)}
					<Stack spacing={1}>
						<Stack>
							<CustomButton onClick={handleLogout}>
								Выйти
							</CustomButton>
						</Stack>
					</Stack>
				</Stack>
			</Menu>
		</>
	);
};

export default ProfileDropdown;
