/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEvent, createStore} from "effector";

export interface INotification {
	id: number;
	text: string;
	author: string;
	authorId: string;
	issueId: string;
	createdAt: string;
	unread: boolean;
}


export const updateNotifications = createEvent<INotification[]>();

export const $notifications = createStore<INotification[]>([]);