/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {IFetchUsersListResponse, ILimit, ILongUser} from '../../types';

export interface IDebtor {
	id: string;
	name: string;
	inn: string;
	email: string;
	supplyAmount: number | ILimit;
	verifiedAmount?: number | ILimit;
	supplyAmountHuman?: string;
	verifiedAmountHuman?: string;
	deferralPeriod: number;
	gracePeriod: number;
	appliedFactors?: string[];
	factor?: ILongUser;
	notificationSigned?: boolean;
}

export type IDebtorAddParams = Omit<IDebtor, 'id' | 'email'>;

export const fetchSuppliersListFx = createEffect<void, IFetchUsersListResponse>();
export const fetchSupplierFactorsFx = createEffect<void, IFetchUsersListResponse>();
export const fetchSupplierDebtorsFx = createEffect<void, IDebtor[]>();
export const addSupplierDebtorFx = createEffect<IDebtorAddParams, IDebtor>();

export const $suppliersEffectsFailData = merge(
	[fetchSuppliersListFx].map((item: Effect<any, any>) => item.failData)
);
