/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Card, Stack, Typography} from '@mui/material';
import NotFoundCard from 'components/helpers/not-found/NotFoundCard';
import PaginationTable, {IMinimalRow} from 'components/helpers/table/PaginationTable';
import requestFinancingIcon from "@iconify-icons/ic/outline-request-page";
import {addSupplierDebtorFx, IDebtor} from "../../../../models/suppliers";
import AddDebtorModal from "../../../helpers/debtors/AddDebtorModal";
import {IAddSupplierDebtorFormValues} from "../../../helpers/debtors/AddDebtorForm";
import RequestFinancingModal, {handleRequestFinancingModalOpen} from "../shipments/RequestFinancingModal";

interface IDebtors {
	selectable?: boolean;
	readonly?: boolean;
	debtors: IDebtor[];
	handleChange?: (item: IMinimalRow) => void;
	fetchSupplierDebtors?: () => void;
	cardComponent?: boolean;
}

const Debtors = ({
	                 cardComponent = false,
	                 selectable = false,
	                 readonly = false,
	                 debtors,
	                 handleChange = undefined,
	                 fetchSupplierDebtors = () => {
	                 }
                 }: IDebtors): React.ReactElement => {
	const rootElement = (children: React.ReactChild | React.ReactFragment | React.ReactPortal) => cardComponent ?
		<Card sx={{p: 3}}>{children}</Card> : children
	const handleAddDebtor = async (params: IAddSupplierDebtorFormValues) => {
		await addSupplierDebtorFx({...params, supplyAmount: params.supplyAmount * 100});
		await fetchSupplierDebtors();
	};

	const getStatus = (debtor: IDebtor) => {
		if (debtor.appliedFactors && debtor.appliedFactors.length > 0 && debtor.factor)
			return 'Запрошено обновление лимитов';
		if (debtor.appliedFactors?.length === 0 && !debtor.factor)
			return 'Не используется';
		if (debtor.factor && debtor.notificationSigned === null)
			return 'Принято в работу Фактором';
		if (debtor.factor && debtor.notificationSigned === false)
			return 'В процессе подписи Уведомления';
		if (debtor.factor && debtor.notificationSigned === true)
			return 'Готов к финансированию';
		return 'Запрошены лимиты';
	}

	return (
		<>
			{rootElement(
				<>
					<Stack
						sx={{
							mb: 3,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}
					>
						<Typography variant='h2'>{!readonly ? 'Мои дебиторы' : 'Дебиторы'}</Typography>
						{!readonly && (<AddDebtorModal addDebtorFunc={handleAddDebtor}/>)}
					</Stack>
					<RequestFinancingModal/>
					{!debtors?.length ? (
						<NotFoundCard name='Дебиторы'/>
					) : (
						<PaginationTable
							selectable={selectable}
							options={[
								{
									key: 'name',
									title: 'Наименование',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 1
								},
								{
									key: 'inn',
									title: 'ИНН',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 1
								},
								{
									key: 'verifiedAmountHuman',
									title: 'Установленный лимит',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 2
								},
								{
									key: 'deferralPeriod',
									title: 'Срок отсрочки платежа',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 2
								},
								{
									key: 'gracePeriod',
									title: 'Срок льготного периода',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 2
								},
								{
									key: 'supplyAmountHuman',
									title: 'Запрашиваемый лимит',
									sx: {textAlign: 'center'},
									isPopover: true,
									colSpan: 2
								},
								{
									key: 'status',
									title: 'Статус',
									sx: {textAlign: 'center'},
									isPopover: true,
									active: !readonly,
									colSpan: 2
								},
								{
									key: 'appliedFactors',
									title: 'На рассмотрении у факторов',
									sx: {textAlign: 'center'},
									isPopover: true,
									active: !readonly,
									colSpan: 2
								},
								{key: 'actions', title: '', active: !readonly, sx: {textAlign: 'center'}}
							]}
							rows={debtors.map(debtor => ({
								...debtor,
								appliedFactors: debtor.appliedFactors?.join(', '),
								status: getStatus(debtor)
							}))}
							clickRowFunc={handleChange}
							rowsActions={[
								{
									tooltip: 'Запросить финансирование',
									onClick: item => handleRequestFinancingModalOpen(item._id, item.factor.id),
									active: item => 'factor' in item,
									icon: requestFinancingIcon,
								},
							]}
						/>
					)}
				</>
			)}
		</>
	);
}

export default Debtors;
