/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {IssueType} from "../../../../types/enum/issueType";
import {IIssueWithExpandUsers, ILimitBetweenFactorAnd, ILongUser, UserType} from "../../../../types";
import {addResponseToIssueFx, createDebtorWithFactorFx, createSupplierWithFactorFx} from "../../../../models/issues";
import {openChat} from "../../../features/chat/Chat";
import {handleRequestLimitsModalOpen} from "../../limits/RequestLimitsModal";
import {openSignNotificationRequestModal} from "../../../../models/modals/functions";

const getIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => issues?.filter(issue => issue.type === type);
const haveIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => !!getIssues(issues, type)?.length;

const getOpenedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => getIssues(issues, type)?.filter(issue => !issue.finished);
const haveOpenedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => !!getOpenedIssues(issues, type)?.length;

const getFinishedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => getIssues(issues, type)?.filter(issue => issue.finished);
const haveFinishedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => !!getFinishedIssues(issues, type)?.length;

const getSuccessfullyFinishedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => getFinishedIssues(issues, type)?.filter(issue => issue.acknowledgmentParticipant && issue.acknowledgmentAuthor);
const haveSuccessfullyFinishedIssues = (issues: IIssueWithExpandUsers[], type: IssueType) => !!getSuccessfullyFinishedIssues(issues, type)?.length;

const haveLimits = (limits: ILimitBetweenFactorAnd[], targetUserId: string) => !!limits?.filter(limit => limit.factor.id === targetUserId).length

const fastAddContractor = async (user: { type: UserType; }, targetUserId: any) => {
	let newIssue;
	if (user?.type === UserType.SUPPLIER)
		newIssue = await createSupplierWithFactorFx({
			title: 'Подпись НДА',
			description: 'Процесс подписи документа НДА',
			factor: targetUserId,
			type: IssueType.SIGN_NDA,
		});
	else
		newIssue = await createDebtorWithFactorFx({
			title: 'Подпись НДА',
			description: 'Процесс подписи документа НДА',
			factor: targetUserId,
			type: IssueType.SIGN_NDA,
		});
	await addResponseToIssueFx({id: newIssue.id, text: 'Добрый день. Прошу направить документ НДА на подписание'});
};

export const calculateActions = (user: ILongUser, issues: IIssueWithExpandUsers[], limits: ILimitBetweenFactorAnd[], targetUser: ILongUser, reloadData: () => void) => [
	{
		id: 'signNda',
		title: 'Запросить НДА',
		actions: [
			{
				id: 'signNDARequest',
				title: 'Запросить',
				onClick: async () => {
					await fastAddContractor(user, targetUser.id);
					reloadData();
				},
				visible: user.type !== UserType.FACTOR,
				disabled: haveOpenedIssues(issues, IssueType.SIGN_NDA) || haveSuccessfullyFinishedIssues(issues, IssueType.SIGN_NDA)
			},
			{
				id: 'openChat',
				title: 'Открыть чат',
				onClick: () => {
					openChat(getOpenedIssues(issues, IssueType.SIGN_NDA)[0].id, reloadData);
				},
				disabled: !haveOpenedIssues(issues, IssueType.SIGN_NDA)
			},
		],
	},
	{
		id: 'requestLimits',
		title: 'Расчет лимитов',
		actions: [
			{
				id: 'calculate',
				title: 'Рассчитать',
				onClick: () => {
					handleRequestLimitsModalOpen(false);
				},
				visible: user.type !== UserType.FACTOR,
				disabled: !haveSuccessfullyFinishedIssues(issues, IssueType.SIGN_NDA) || haveSuccessfullyFinishedIssues(issues, IssueType.SET_LIMIT) || haveOpenedIssues(issues, IssueType.SET_LIMIT),
			},
			{
				id: 'recalculate',
				title: 'Обновить',
				onClick: () => {
					handleRequestLimitsModalOpen(true);
				},
				visible: user.type !== UserType.FACTOR,
				disabled: !haveSuccessfullyFinishedIssues(issues, IssueType.SET_LIMIT) || haveOpenedIssues(issues, IssueType.SET_LIMIT) || haveOpenedIssues(issues, IssueType.SIGN_NOTIFICATION),
			},
			{
				id: 'openChat',
				title: 'Открыть чат',
				onClick: () => {
					openChat(getOpenedIssues(issues, IssueType.SET_LIMIT)[0].id, reloadData);
				},
				disabled: !haveOpenedIssues(issues, IssueType.SET_LIMIT)
			},
		],
	},
	{
		id: 'signNotification',
		title: 'Подпись Уведомления',
		actions: [
			{
				id: 'signNotificationRequest',
				title: 'Подписать Уведомление',
				onClick: () => {
					openSignNotificationRequestModal();
				},
				visible: user.type === UserType.SUPPLIER,
				disabled:
					(!haveSuccessfullyFinishedIssues(issues, IssueType.SET_LIMIT) || haveSuccessfullyFinishedIssues(issues, IssueType.SIGN_NOTIFICATION) || haveOpenedIssues(issues, IssueType.SIGN_NOTIFICATION))
					//@ts-ignore
					&& (!haveSuccessfullyFinishedIssues(issues, IssueType.SET_LIMIT) || haveOpenedIssues(issues, IssueType.SIGN_NOTIFICATION) || (('debtors' in user) && user.debtors.filter(debtor => debtor.factor && debtor.factor === targetUser.id && debtor.notificationSigned === false).length === 0))
			},
			{
				id: 'openChat',
				title: 'Открыть чат',
				onClick: () => {
					openChat(getOpenedIssues(issues, IssueType.SIGN_NOTIFICATION)[0].id, reloadData);
				},
				disabled: !haveOpenedIssues(issues, IssueType.SIGN_NOTIFICATION)
			},
		],
	},
];