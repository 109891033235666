/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {parseFileToJsonFx, parseJsonToFileFx} from "./index";
import {parseFileToJson, parseJsonToFile} from "../../api";

parseFileToJsonFx.use(parseFileToJson);
parseJsonToFileFx.use(parseJsonToFile);