/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, FormControl, Input, InputAdornment, InputLabel} from '@mui/material';
// @ts-ignore
import {ReactComponent as SearchIcon} from '../../../assets/images/search.svg';
//@ts-ignore
import moduleStyles from './SearchBar.module.scss';

const styles = moduleStyles as any;

const SearchBar = ({setValue}: { setValue: (text: string) => void }): React.ReactElement => (
	<Box className={styles.search}>
		<FormControl sx={{width: '300px'}} variant='standard'>
			<InputLabel htmlFor='search-input'>Поиск</InputLabel>
			<Input
				onChange={event => setValue(event.target.value)}
				id='search-input'
				type='text'
				endAdornment={
					<InputAdornment position='end'>
						<SearchIcon/>
					</InputAdornment>
				}
				sx={{mt: '12px !important'}}
			/>
		</FormControl>
	</Box>
);

export default SearchBar;
