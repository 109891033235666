/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef, useState} from 'react';
import {Card, Chip, IconButton, Stack, Tooltip, Typography} from '@mui/material';
import browseIcon from '@iconify-icons/gg/browse';
import {useStore} from 'effector-react';
import {IIssueWithExpandUsers, ILongUser} from 'types';
import Modal, {IModalRef} from 'components/helpers/modals/Modal';
import {GetIcon} from 'components/helpers/other/GetIcon';
import CreateIssueModal from './CreateIssueModal';
import {fetchIssuesListFx} from '../../../../models/issues';
import Spinner from '../../../helpers/other/Spinner';
import {openChat} from '../../../features/chat/Chat';
import {$user} from '../../../../models/auth';
import {checkUserIsDebtorOrFactor} from '../../../../shared/functions';
import CustomButton from "../../../styled/customButton/CustomButton";

interface IIssuesModalProps {
	row: ILongUser;
}

const IssuesModal = ({row}: IIssuesModalProps): React.ReactElement => {
	const {user} = useStore($user);
	const fetchIssuesListPending = useStore(fetchIssuesListFx.pending);

	const modalRef = useRef<IModalRef>(null);

	const [issuesList, setIssuesList] = useState<null | IIssueWithExpandUsers[]>(null);

	const handleModalOpen = () => {
		if (modalRef.current) {
			fetchIssuesList().then();
			modalRef.current.handleModalOpen();
		}
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const fetchIssuesList = async () => {
		if (user && 'type' in user) {
			const authorId = user.type === 'factor' ? row.id : user.id;
			const participantId = user.type === 'factor' ? user.id : row.id;
			const response = await fetchIssuesListFx({participantId, authorId});
			setIssuesList(response.docs);
		}
	};

	const modalContent = {
		toggleChildren: (
			<Tooltip title='Обсуждения' placement='top' arrow>
				<IconButton color='inherit' onClick={handleModalOpen}>
					{GetIcon(browseIcon)}
				</IconButton>
			</Tooltip>
		),
		contentChildren: (
			<Stack spacing={2} sx={{height: '100%'}}>
				<Stack
					direction='row'
					sx={{
						justifyContent: checkUserIsDebtorOrFactor(user) ? 'space-between' : 'center',
						alignItems: 'center',
						px: 3,
					}}
				>
					<Typography align='center' variant='h4'>
						Список взаимодействий
					</Typography>
					{checkUserIsDebtorOrFactor(user) && (
						<CreateIssueModal row={row} fetchIssuesList={fetchIssuesList}/>
					)}
				</Stack>
				{(!issuesList || fetchIssuesListPending) && (
					<Stack sx={{height: '100%', justifyContent: 'center'}}>
						<Spinner/>
					</Stack>
				)}
				{
					// prettier-ignore
					(issuesList && !issuesList.length && !fetchIssuesListPending) ? (
						<Stack sx={{height: '100%', justifyContent: 'center'}}>
							<Typography variant='h6' align='center'>
								<strong>История взаимодействий пуста</strong>
							</Typography>
						</Stack>
					) : null
				}
				{
					// prettier-ignore
					(issuesList && issuesList.length && !fetchIssuesListPending) ? (
						<Stack
							sx={{
								height: '100%',
								overflowY: 'scroll',
								px: 3,
							}}
						>
							<Stack spacing={1}>
								{issuesList.map(issue => (
									<Card
										key={issue.id}
										sx={{
											m: 0,
											p: 2,
											transition: 'all .1s',
											':hover': {
												cursor: 'pointer',
												transform: 'scale(1.025)',
											},
										}}
										onClick={() => {
											openChat(issue.id);
											handleModalClose();
										}}
									>
										<Stack direction='row'
										       sx={{justifyContent: 'space-between', alignItems: 'center'}}
										>
											<Typography variant='h4' textAlign='left'
											            sx={{color: 'primary.main', fontSize: '1.25rem'}}>
												{issue.title}
											</Typography>
											{(issue.acknowledgmentAuthor === true && issue.acknowledgmentParticipant === true && !issue.finished) &&
                                                <Chip color='primary' label='В процессе подписи' variant="outlined"
                                                      sx={{height: 'auto', cursor: 'pointer'}}/>}
											{(issue.finished && !(issue.acknowledgmentAuthor === false || issue.acknowledgmentParticipant === false)) &&
                                                <Chip color='success' label='Успешно' variant="outlined"
                                                      sx={{height: 'auto', cursor: 'pointer'}}/>}
											{(issue.acknowledgmentAuthor === false || issue.acknowledgmentParticipant === false) &&
                                                <Chip color='error' label='Отклонено' variant="outlined"
                                                      sx={{height: 'auto', cursor: 'pointer'}}/>}
										</Stack>
										<Typography variant='caption' textAlign='left' sx={{fontSize: '1rem'}}>
											{issue.description}
										</Typography>
									</Card>
								))}
							</Stack>
						</Stack>
					) : null
				}
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{mt: 1, width: '100%'}}>
				<CustomButton
					onClick={handleModalClose}
				>
					Закрыть
				</CustomButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
			paperSx={{
				width: '500px',
				height: '500px',
				'.MuiDialogContent-root': {overflowY: 'hidden', px: 0},
			}}
		/>
	);
};

export default IssuesModal;
