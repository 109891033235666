/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

const breakpoints = {
	xs: 0,
	sm: 600,
	md: 960,
	lg: 1200,
	xl: 1800,
	xxl: 3200,
};

export default breakpoints;
