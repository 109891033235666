/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, Stack, TextField, Typography,} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {createEvent, createStore} from 'effector';
import {useStore} from 'effector-react';
import {IIssueWithExpandUsers} from '../../../../types';
import {setLimitFx} from '../../../../models/issues';
import GrowTransition from '../../../helpers/transitions/GrowTransition';
import CustomButton from "../../../styled/customButton/CustomButton";

export const setOpenSetLimitModal = createEvent<boolean>();
export const $setLimitModal = createStore({
	open: false,
}).on(setOpenSetLimitModal, (state, open) => ({...state, open}));

export const handleSetLimitModalOpen = () => setOpenSetLimitModal(true);
export const handleSetLimitModalClose = () => setOpenSetLimitModal(false);

interface ISetLimitModalModal {
	chatInfo: IIssueWithExpandUsers;
	fetchIssue: (timeout: number) => void;
	fetchResponsesList: () => void;
}

const SetLimitModal = ({chatInfo, fetchIssue, fetchResponsesList}: ISetLimitModalModal): React.ReactElement => {
	const {open} = useStore($setLimitModal);

	const SetLimitFormSchema = Yup.object().shape({
		limitRub: Yup.number().required('Поле является обязательным'),
	});

	const formik = useFormik<{ limitRub: number | '' }>({
		initialValues: {
			limitRub: chatInfo.limit && chatInfo.limit.amount ? chatInfo.limit.amount / 100 : '',
		},
		validationSchema: SetLimitFormSchema,
		onSubmit: async ({limitRub}) => {
			if (limitRub !== '') {
				await setLimitFx({issueId: chatInfo.id, limit: limitRub * 100});
				fetchResponsesList();
				handleModalClose();
				fetchIssue(0);
			}
		},
	});

	const {touched, errors, getFieldProps, resetForm, submitForm, setFieldValue} = formik;

	const handleModalClose = () => {
		handleSetLimitModalClose();
		resetForm();
	};

	useEffect(() => {
		if (chatInfo.limit && chatInfo.limit.amount) {
			setFieldValue('limitRub', chatInfo.limit.amount / 100);
		}
	}, [chatInfo]);

	return (
		<Dialog
			PaperProps={{sx: {width: '50vw'}}}
			onBackdropClick={handleModalClose}
			TransitionComponent={GrowTransition}
			open={open}
		>
			<DialogContent>
				<Stack spacing={1.5}>
					<Stack>
						<Typography variant='h4' align='center'>
							Установить лимит
						</Typography>
					</Stack>
					<Stack>
						<TextField
							type='number'
							fullWidth
							label='Лимит в рублях'
							{...getFieldProps('limitRub')}
							error={Boolean(touched.limitRub && errors.limitRub)}
							helperText={touched.limitRub && errors.limitRub}
						/>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions sx={{pt: 0}}>
				<Stack direction='row' spacing={1} sx={{width: '100%'}}>
					<CustomButton
						onClick={handleModalClose}
					>
						Отменить
					</CustomButton>
					<CustomButton onClick={submitForm}>
						Установить
					</CustomButton>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

export default SetLimitModal;
