/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {
	IAddFileParams,
	ICompanyCard,
	ICompanyCardSlotFile,
	IDiadocForm,
	IFetchProfileFilesResponse,
	IFile,
	IIDRequest,
	ILongUser,
	IPatchedFile,
	IPatchFileParams,
	IPatchUserCompanyCardParams,
	IPatchUserInfoParams,
} from 'types';

// user
export const fetchUserInfoFx = createEffect<void, ILongUser>();
export const fetchAnotherUserInfoFx = createEffect<IIDRequest, ILongUser>();
export const patchUserInfoFx = createEffect<IPatchUserInfoParams, ILongUser>();
export const addDiadocAccountFx = createEffect<IDiadocForm, any>();
export const deleteNotificationFx = createEffect<{ id: number }, any>();
export const fetchNDAFx = createEffect<void, any>();

// files
export const fetchProfileFilesFx = createEffect<void, IFetchProfileFilesResponse>();
export const getFileFx = createEffect<{ fileId: string }, Blob>();
export const addFileFx = createEffect<IAddFileParams, IFile>();
export const patchFileFx = createEffect<IPatchFileParams, IPatchedFile>();
export const deleteFileFx = createEffect<{ fileId: string }, number>();

// companyCard
export const fetchUserCompanyCardFx = createEffect<void, ICompanyCard>();
export const fetchAnotherUserCompanyCardFx = createEffect<IIDRequest, ICompanyCard>();
export const patchUserCompanyCardFx = createEffect<IPatchUserCompanyCardParams, ICompanyCard>();
export const addFileToSlotCompanyCardFx = createEffect<{
	slotId: string;
	data: FormData;
},
	ICompanyCardSlotFile>();
export const getFileFromSlotCompanyCardFx = createEffect<{ userId: string, slotId: string }, Blob>();
export const deleteFileFromSlotCompanyCardFx = createEffect<{ slotId: string }, number>();

export const $profileEffectsFailData = merge(
	[
		fetchUserInfoFx,
		patchUserInfoFx,
		fetchProfileFilesFx,
		getFileFx,
		addFileFx,
		patchFileFx,
		deleteFileFx,
		fetchUserCompanyCardFx,
		patchUserCompanyCardFx,
		addFileToSlotCompanyCardFx,
		getFileFromSlotCompanyCardFx,
		deleteFileFromSlotCompanyCardFx,
	].map((item: Effect<any, any>) => item.failData)
);
