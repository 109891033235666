/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Navigate, useRoutes} from 'react-router-dom';
import {useStore} from 'effector-react';
import {$user} from 'models/auth';
import DashboardLayout from 'components/layouts/DashboardLayout';
import HeaderLayout from 'components/layouts/HeaderLayout';
import ErrorPage from 'pages/Error';
import LoginPage from 'pages/Login';
import ProfilePage from 'pages/logged/Profile';
import SignaturePage from 'pages/Signature';
import DocumentsPage from '../pages/logged/Documents';
import DashboardPage from "../pages/logged/Dashboard";
import FinancingPage from "../pages/logged/FinancingPage";
import TgAuth from "../pages/TgAuth";

const Router = (): React.ReactElement | null => {
	const {user} = useStore($user);
	// eslint-disable-next-line no-nested-ternary
	const loggedDashboardLayout = !user ? <Navigate to='/login'/> : (!user.ndaSigned ? <Navigate to='/signature/nda'/> :
		<DashboardLayout/>);
	const loggedHeaderLayout = !user ? <Navigate to='/login'/> : <HeaderLayout/>;

	const routes = [
		{
			path: '/login',
			children: [{path: '/login', element: <LoginPage/>}],
		},
		{
			path: '/auth',
			element: loggedDashboardLayout,
			children: [{path: '/auth/:chatId', element: <TgAuth/>}],
		},
		{
			path: '/signature',
			element: loggedHeaderLayout,
			children: [{path: '/signature/nda', element: <SignaturePage/>}],
		},
		{
			path: '/dashboard',
			element: loggedDashboardLayout,
			children: [{path: '/dashboard', element: <DashboardPage/>}],
		},
		{
			path: '/profile',
			element: loggedDashboardLayout,
			children: [{path: '/profile', element: <ProfilePage/>}, {
				path: '/profile/:id',
				element: <ProfilePage isContactor/>
			}],
		},
		{
			path: '/documents',
			element: loggedDashboardLayout,
			children: [{path: '/documents', element: <DocumentsPage/>}],
		},
		{
			path: '/financing',
			element: loggedDashboardLayout,
			children: [{path: '/financing/:id', element: <FinancingPage/>}],
		},
		{
			path: '/',
			children: [
				{path: '404', element: <ErrorPage/>},
				{
					path: '',
					element: !user ? <Navigate to='/login'/> : <Navigate to='/dashboard'/>,
				},
			],
		},
		{path: '*', element: <Navigate to='/404' replace/>},
	];

	return useRoutes(routes);
};

export default Router;
