/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import * as Yup from 'yup';
import React, {forwardRef, useImperativeHandle} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {Stack, TextField} from '@mui/material';
import {ILongUser} from '../../../../types';
import FormikAutocomplete from '../../../helpers/forms/FormikAutocomplete';
import {IssueType} from "../../../../types/enum/issueType";

interface ICreateIssueFormProps {
	submitFunc: (values: ICreateIssueFormValues) => void;
	row: ILongUser;
}

export interface ICreateIssueFormValues {
	title: string;
	description: string;
	factor: string;
	type: { name: string; value: IssueType.CONVERSATION };
}

const CreateIssueForm = forwardRef(({row, submitFunc}: ICreateIssueFormProps, ref) => {
	const CreateIssueFormSchema = Yup.object().shape({
		title: Yup.string().required('Поле является обязательным'),
		description: Yup.string(),
	});

	const formik = useFormik<ICreateIssueFormValues>({
		initialValues: {
			title: '',
			description: '',
			factor: row.id,
			type: {name: 'Обсуждение', value: IssueType.CONVERSATION},
		},
		validationSchema: CreateIssueFormSchema,
		onSubmit: values => submitFunc(values),
	});

	const {errors, touched, handleSubmit, getFieldProps} = formik;

	useImperativeHandle(ref, () => ({...formik}));

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
				<Stack spacing={2.5}>
					<TextField
						fullWidth
						label='Название'
						{...getFieldProps('title')}
						error={Boolean(touched.title && errors.title)}
						helperText={touched.title && errors.title}
					/>

					<FormikAutocomplete
						name='type'
						options={[
							{name: 'Обсуждение', value: IssueType.CONVERSATION},
						]}
						textFieldProps={{label: 'Тип обсуждения'}}
					/>

					<TextField
						multiline
						minRows={3}
						fullWidth
						label='Описание'
						{...getFieldProps('description')}
						error={Boolean(touched.description && errors.description)}
						helperText={touched.description && errors.description}
					/>
				</Stack>
			</Form>
		</FormikProvider>
	);
});

export default CreateIssueForm;
