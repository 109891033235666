/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {fetchLimitListFx} from './index';
import {fetchLimitList} from '../../api';

fetchLimitListFx.use(fetchLimitList);
