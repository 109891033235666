/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	addResponseCompanyCardFx,
	addResponseDebtorsFx,
	addResponseFileToIssueFx,
	addResponseShipmentsFx,
	addResponseSignedFileToIssueFx,
	addResponseToIssueFx,
	completeIssueFx,
	createDebtorWithFactorFx,
	createSupplierWithFactorFx,
	createWithAdminFx,
	downloadCompanyCardSlotFileFx,
	downloadResponseFileFx,
	fetchIncomeFinalIssueResponsesFx,
	fetchIssueFx,
	fetchIssuesListFx,
	fetchOutcomeFinalIssueResponsesFx,
	fetchResponsesListFx,
	fetchUserFinancingIssuesListFx,
	makeFileSignedInIssueFx,
	replaceFileFx,
	replaceSignedFileInIssueFx,
	setLimitFx,
} from './index';
import {
	addResponseCompanyCard,
	addResponseDebtors,
	addResponseFileToIssue,
	addResponseShipments,
	addResponseSignedFileToIssue,
	addResponseToIssue,
	completeIssue,
	createDebtorWithFactor,
	createSupplierWithFactor,
	createWithAdmin,
	downloadCompanyCardSlotFile,
	downloadResponseFile,
	fetchIncomeFinalIssueResponses,
	fetchIssue,
	fetchIssuesList,
	fetchOutcomeFinalIssueResponses,
	fetchResponsesList,
	fetchUserFinancingIssuesList,
	makeFileSignedInIssue,
	replaceFile,
	replaceSignedFileInIssue,
	setLimit,
} from '../../api';

fetchIssuesListFx.use(fetchIssuesList);
fetchUserFinancingIssuesListFx.use(fetchUserFinancingIssuesList);
fetchIssueFx.use(fetchIssue);
createSupplierWithFactorFx.use(createSupplierWithFactor);
createDebtorWithFactorFx.use(createDebtorWithFactor);
fetchResponsesListFx.use(fetchResponsesList);
replaceFileFx.use(replaceFile);
addResponseToIssueFx.use(addResponseToIssue);
addResponseFileToIssueFx.use(addResponseFileToIssue);
addResponseSignedFileToIssueFx.use(addResponseSignedFileToIssue);
replaceSignedFileInIssueFx.use(replaceSignedFileInIssue);
makeFileSignedInIssueFx.use(makeFileSignedInIssue);
downloadResponseFileFx.use(downloadResponseFile);
completeIssueFx.use(completeIssue);
setLimitFx.use(setLimit);
addResponseCompanyCardFx.use(addResponseCompanyCard);
addResponseDebtorsFx.use(addResponseDebtors);
addResponseShipmentsFx.use(addResponseShipments);
downloadCompanyCardSlotFileFx.use(downloadCompanyCardSlotFile);
createWithAdminFx.use(createWithAdmin);
fetchIncomeFinalIssueResponsesFx.use(fetchIncomeFinalIssueResponses);
fetchOutcomeFinalIssueResponsesFx.use(fetchOutcomeFinalIssueResponses);
