/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {IBackendPagination} from './index';
import {IDebtor} from "../models/suppliers";

export enum UserType {
	ADMIN = 'admin',
	FACTOR = 'factor',
	SUPPLIER = 'supplier',
	DEBTOR = 'debtor'
}

export interface ISmallUser {
	id: string;
	email: string;
}

export interface IUser extends ISmallUser {
	createdAt: string;
	isAdmin: boolean;
	name: string;
	inn: string;
}

export interface ILongUser extends IUser {
	type: UserType;
	generated: boolean;
	ndaSigned: boolean;
	nda: string;
	debtors?: IDebtor[];
	tgChat: string;
	//diadocConnected: boolean;
}

export interface IFactorContragents {
	factor: ISmallUser;
	debtors: IUser[];
}

export interface IFetchFactorsContragentsResponse {
	docs: IFactorContragents[];
}

export interface IFetchUsersListResponse extends IBackendPagination {
	docs: ILongUser[];
}
