/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {MouseEventHandler} from 'react';
import {Children} from 'shared/types';
//@ts-ignore
import clsx from "clsx";
//@ts-ignore
import moduleStyles from './CustomButton.module.scss';

const styles = moduleStyles as any;

interface CustomButtonProps {
	type?: string;
	disabled?: boolean;
	children: Children;
	variant?: 'primary' | 'secondary' | 'third' | 'exception' | 'exception1' | 'icon';
	component?: 'button' | 'span';
	withStartIcon?: boolean;
	style?: any;
	onClick?: MouseEventHandler;
}


const CustomButton = ({
	                      type = 'button',
	                      disabled = false,
	                      children,
	                      variant = 'primary',
	                      withStartIcon,
	                      component = 'button',
	                      onClick = () => {
	                      },
	                      style
                      }: CustomButtonProps): React.ReactElement => (
	React.createElement(component, {
		type, display: disabled ? 'none' : 'block',
		className:
			clsx(
				styles.button,
				withStartIcon && styles.withStartIcon,
				disabled && styles.disabled,
				variant === 'primary' && styles.primary,
				variant === 'secondary' && styles.secondary,
				variant === 'third' && styles.third,
				variant === 'exception' && styles.exception,
				variant === 'exception1' && styles.exception1,
				variant === 'icon' && styles.icon
			),
		onClick: !disabled && onClick ? onClick : () => {
		},
		style: style || {}
	}, children)
);

export default CustomButton;