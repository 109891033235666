/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Stack, TextField} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';
import {isInn} from "../../../shared/validators/inn";
import {checkInnValidityFx} from "../../../models/debtors";

export interface IAddSupplierDebtorFormValues {
	name: string;
	inn: string;
	supplyAmount: number;
	deferralPeriod: number;
	gracePeriod: number;
}

interface IAddSupplierDebtorFormProps {
	submitFunc: (values: IAddSupplierDebtorFormValues) => void;
}

const helperTexts = {
	inn: 'Убедитесь в правильности ввода, найдено нечеткое совпадение'
}

const AddSupplierDebtorForm = forwardRef(
	({submitFunc}: IAddSupplierDebtorFormProps, ref) => {
		const SelectSupplierDebtorFormSchema = Yup.object().shape({
			name: Yup.string().required('Поле является обязательным'),
			inn: Yup.string().required('Поле является обязательным').test('isInn', '', isInn),
			supplyAmount: Yup.number().required('Поле является обязательным'),
			deferralPeriod: Yup.number().required('Поле является обязательным'),
			gracePeriod: Yup.number().required('Поле является обязательным')
		});

		const formik = useFormik<IAddSupplierDebtorFormValues>({
			initialValues: {
				name: '',
				inn: '',
				supplyAmount: 0,
				deferralPeriod: 0,
				gracePeriod: 0
			},
			validationSchema: SelectSupplierDebtorFormSchema,
			validateOnChange: false,
			validateOnBlur: false,
			onSubmit: (values: IAddSupplierDebtorFormValues) => submitFunc(values),
		});

		const {touched, errors, handleSubmit, getFieldProps, values} = formik;

		const [checkInn, setCheckInn] = useState(false);

		useEffect(() =>{
			setCheckInn(false);
			if (values.inn?.length > 9)
				checkInnValidityFx(values.inn).then(result => setCheckInn(result));
		}, [values.inn]);

		useImperativeHandle(ref, () => ({...formik}));

		return (
			<FormikProvider value={formik}>
				<Form style={{width: '100%'}} autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={2}>
						<TextField
							fullWidth
							multiline
							label='Наименование'
							{...getFieldProps('name')}
							error={Boolean(touched.name && errors.name)}
							helperText={touched.name && errors.name}
						/>
						<TextField
							fullWidth
							label='ИНН'
							inputProps={{maxLength: 12}}
							{...getFieldProps('inn')}
							error={Boolean(touched.inn && errors.inn)}
							helperText={(touched.inn && errors.inn) || (checkInn && helperTexts.inn)}
						/>
						<TextField
							type='number'
							fullWidth
							label='Сумма финансирования'
							{...getFieldProps('supplyAmount')}
							error={Boolean(touched.supplyAmount && errors.supplyAmount)}
							helperText={touched.supplyAmount && errors.supplyAmount}
						/>
						<TextField
							type='number'
							fullWidth
							label='Срок отсрочки платежа'
							{...getFieldProps('deferralPeriod')}
							error={Boolean(touched.deferralPeriod && errors.deferralPeriod)}
							helperText={touched.deferralPeriod && errors.deferralPeriod}
						/>
						<TextField
							type='number'
							fullWidth
							label='Срок льготного периода'
							{...getFieldProps('gracePeriod')}
							error={Boolean(touched.gracePeriod && errors.gracePeriod)}
							helperText={touched.gracePeriod && errors.gracePeriod}
						/>
					</Stack>
				</Form>
			</FormikProvider>
		);
	}
);

export default AddSupplierDebtorForm;
