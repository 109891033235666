/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

const components = {
	MuiCard: {
		styleOverrides: {
			root: {
				borderRadius: '14px',
				boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
			},
		},
	},
	MuiTypography: {
		styleOverrides: {
			root: {
				fontFamily: 'SF Pro Display, sans-serif',
				fontSize: '1.5rem',
				color: '#000000',
				lineHeight: 1,
			},
		},
	},
};

export default components;
