/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {
	Card,
	CardContent,
	Checkbox,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import {IconifyIcon} from '@iconify/react';
import {SxProps} from '@mui/system';
import _ from "lodash";
import NamePopover from '../other/NamePopover';
import {GetIcon} from "../other/GetIcon";
import TablePaginationActions from "./TablePaginationActions";
import {refreshSelected} from "../../features/chat/chat-actions/CompleteIssueModal";

export interface IMinimalRow {
	id: string | number;
	disabled?: boolean;
	[key: string]: any;
}

interface IDisengageable {
	active?: boolean | ((value: any) => boolean);
}

interface IOnClickAction extends IDisengageable {
	tooltip: string;
	onClick: (item: IMinimalRow) => void;
	icon: IconifyIcon;
}

interface IComponentAction extends IDisengageable {
	id: string;
	component: (props: any) => React.ReactElement;
	props?: {
		[key: string]: any;
	};
}

export interface IPaginationTableProps {
	cardComponent?: boolean;
	selectable?: boolean;
	options: {
		key: string;
		title: string;
		sx?: SxProps;
		valueSx?: (v: any) => SxProps;
		isPopover?: boolean;
		isHref?: boolean;
		active?: boolean;
		reduce?: any;
		showInnerTable?: boolean;
		colSpan?: number;
	}[];
	rows?: IMinimalRow[];
	rowsActions?: (IOnClickAction | IComponentAction)[];
	clickRowFunc?: (item: any) => void;
	title?: string;
}

const transformRowValue = (
	value: any,
	isPopover: boolean | undefined,
	isHref: boolean | undefined,
	valueSx?: SxProps,
	reduce: any = (v: any) => (Array.isArray(v) ? v[0] : v)
) => {
	if (!value) return '-';
	if (isHref) return <a target="_blank" style={{color: 'cornflowerblue'}} href={String(reduce(value))}
	                      rel="noreferrer">Ссылка на Диадок</a>;
	if (isPopover) return <NamePopover name={String(reduce(value))} sx={valueSx}/>;
	if (Array.isArray(value)) return String(reduce ? reduce(value) : value[0]);
	return String(value);
};

const PaginationTable = ({
	                         selectable = false,
	                         options,
	                         rows = [],
	                         rowsActions,
	                         clickRowFunc,
	                         title,
	                         cardComponent = false,
                         }: IPaginationTableProps): React.ReactElement => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [selected, setSelected] = useState<string[]>([]);
	const rootElement = (children: React.ReactChild | React.ReactFragment | React.ReactPortal) => cardComponent ?
		<Card sx={{m: '15px'}}><CardContent>{children}</CardContent></Card> : children

	const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) =>
		setPage(newPage);

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = rows.map((n) => n.id.toString());
			localStorage.setItem('selected', JSON.stringify(newSelecteds));
			setSelected(newSelecteds);
			refreshSelected();
			return;
		}
		setSelected([]);
		localStorage.setItem('selected', JSON.stringify([]));
		refreshSelected();
	};

	const onItemClick = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
		let newSelecteds;
		if (event.target.checked) {
			newSelecteds = [...selected, id];
			localStorage.setItem('selected', JSON.stringify(newSelecteds));
			setSelected(newSelecteds);
			refreshSelected();
			return;
		}
		newSelecteds = selected.filter(prevId => prevId !== id);
		localStorage.setItem('selected', JSON.stringify(newSelecteds));
		setSelected(newSelecteds);
		refreshSelected();
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	return (
		<>
			{rootElement(
				<>
					{!!title && <Typography variant='h2' sx={{mb: 3}}>{title}</Typography>}
					<TableContainer>
						<Table stickyHeader sx={{whiteSpace: 'nowrap'}}>
							<TableHead>
								<TableRow>
									{selectable && (
										<TableCell padding="checkbox">
											<Checkbox
												color="primary"
												indeterminate={selected.length > 0 && selected.length < rows.length}
												checked={rows.length > 0 && selected.length === rows.length}
												onChange={onSelectAllClick}
											/>
										</TableCell>
									)}
									{options
										.filter(opt => !('active' in opt) || ('active' in opt && opt.active))
										.map(opt => (
											<TableCell
												// eslint-disable-next-line no-nested-ternary
												colSpan={opt.colSpan === undefined ? (opt.key === 'actions' ? 1 : 2) : opt.colSpan}
												key={opt.key}
												sx={{...opt.sx}}
											>
												<Typography sx={{fontSize: '1rem'}} variant='h5'>{opt.title}</Typography>
											</TableCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
										? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										: rows
								).map(row => {
									const isItemSelected = isSelected(row.id.toString());
									return (<React.Fragment key={row.id}>
										<TableRow
											onClick={(e) => {
												if (!((e.target instanceof HTMLTableCellElement)))
													return null;
												return _.isFunction(clickRowFunc) ? clickRowFunc(row) : null;
											}
											}
											hover={_.isFunction(clickRowFunc)}
											sx={{cursor: _.isFunction(clickRowFunc) ? 'pointer' : 'default', opacity: (row.disabled === null || !row.disabled) ? '1' : '0.5', textDecoration: (row.disabled === null || !row.disabled) ? 'none' : 'line-through', backgroundColor: (row.disabled === null || !row.disabled) ? 'none' : 'rgba(0, 0, 0, 0.1)'}}
										>
											{selectable && (
												<TableCell padding="checkbox">
													<Checkbox
														color="primary"
														checked={isItemSelected}
														onChange={(event) => onItemClick(event, row.id.toString())}
													/>
												</TableCell>
											)}
											{options
												.filter(
													opt => !('active' in opt) || ('active' in opt && opt.active)
												)
												.map(opt => (
													<TableCell
														// eslint-disable-next-line no-nested-ternary
														colSpan={opt.colSpan === undefined ? (opt.key === 'actions' ? 1 : 2) : opt.colSpan}
														key={String(opt.key)}
														sx={{...opt.sx}}
													>
														{opt.key === 'actions' && rowsActions ? (
															rowsActions
																.filter(action => {
																	if (_.isNil(action.active)) return true;
																	if (_.isFunction(action.active))
																		return action.active(row);
																	return action.active;
																})
																.map(action => {
																	if ('component' in action) {
																		const Component = action.component;
																		return (
																			<Component
																				key={action.id}
																				row={row}
																				{...action.props}
																			/>
																		);
																	}
																	return (
																		<Tooltip
																			key={action.tooltip}
																			title={action.tooltip}
																			placement='top'
																			arrow
																		>
																			<IconButton
																				sx={{
																					width: '36px',
																					height: '36px',
																					p: 0
																				}}
																				color='inherit'
																				onClick={() => action.onClick(row)}
																			>
																				{GetIcon(action.icon)}
																			</IconButton>
																		</Tooltip>
																	);
																})
														) : (
															<Typography sx={{margin: 'auto', width: 'fit-content',fontSize: '1rem'}}
															            variant='h5'>
																{transformRowValue(
																	row[opt.key],
																	opt.isPopover,
																	opt.isHref,
																	opt.valueSx ? opt.valueSx(row[opt.key]) : {},
																	opt.reduce,
																)}
															</Typography>
														)}
													</TableCell>
												))}
										</TableRow>
									</React.Fragment>);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					{rows.length > 5 && (
						<TablePagination
							component='div'
							rowsPerPageOptions={[5, 10, 25]}
							colSpan={6}
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					)}
				</>)}
		</>
	);
};

export default PaginationTable;
