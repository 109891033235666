/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Box, Dialog, DialogContent, Divider, IconButton, Stack, Tooltip, Typography,} from '@mui/material';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {Form, FormikProvider, useFormik} from 'formik';
import {Icon} from '@iconify/react';
import fileIcon from '@iconify/icons-eva/file-fill';
import GrowTransition from '../../helpers/transitions/GrowTransition';
import {GetIcon} from '../../helpers/other/GetIcon';
import Spinner from '../../helpers/other/Spinner';
import {formatBytes} from '../../../shared/functions';
import {openErrorSnackbar} from "../../helpers/snackbars/ErrorSnackbar";
import {addResponseSignedFileToIssueFx} from "../../../models/issues";
import CustomButton from "../../styled/customButton/CustomButton";

const SignAndSendFile = ({
	                         disabled = false,
	                         issueId,
	                         fetchResponsesList
                         }: { disabled?: boolean, issueId: string; fetchResponsesList: () => void; }): React.ReactElement => {
	const [modalOpen, setModalOpen] = useState(false);

	const formik = useFormik<{ file: null | File, description: string }>({
		initialValues: {
			file: null,
			description: ''
		},
		onSubmit: async ({file, description}) => {
			if (file) {
				let signedData;
				try {
					//signedData = await createAttachedSignature(cert?.thumbprint, await file.arrayBuffer());
					// eslint-disable-next-line no-new
					// const signedFile = new File([signedData], `${file.name}.sig`, {
					// 	type: "multipart/signed",
					// });
					const formData = new FormData();
					formData.append('file', file);
					formData.append('description', description);
					await addResponseSignedFileToIssueFx({issueId, data: formData});
					fetchResponsesList();
					handleModalClose();
				} catch (e) {
					let message = 'Неизвестная ошибка с ЭЦП'
					if (e instanceof Error) message = e.message
					openErrorSnackbar(message);
				}
			}
		},
	});

	const {handleSubmit, values, getFieldProps, setFieldValue} = formik;

	const handleModalOpen = () => {
		setModalOpen(true);
	}
	const handleModalClose = () => setModalOpen(false);

	return (
		<>
			<label style={{width: 'fit-content', marginTop: '5px'}} htmlFor='import-button-signed-file'>
				<input
					disabled={disabled}
					style={{display: 'none'}}
					id='import-button-signed-file'
					type='file'
					onChange={event => {
						if (event.currentTarget.files) {
							const file = event.currentTarget.files[0];
							setFieldValue('file', file);
							handleModalOpen();
						}
					}}
				/>
				<CustomButton component='span' disabled={disabled}>Отправить документ на
					подписание</CustomButton>
			</label>
			<Dialog
				PaperProps={{sx: {width: '800px'}}}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={modalOpen}
			>
				<DialogContent sx={{p: 0, position: 'relative'}}>
					<FormikProvider value={formik}>
						<Form
							autoComplete='off'
							noValidate
							onSubmit={handleSubmit}
							style={{height: '100%'}}
						>
							<Stack
								sx={{
									pt: 2,
									pb: 1,
									justifyContent: 'space-between',
								}}
							>
								<Stack sx={{pl: 2.5, pr: 1}}>
									{!values.file ? (
										<Stack sx={{height: '100%', display: 'grid', placeItems: 'center'}}>
											<Spinner size='3rem'/>
										</Stack>
									) : (
										<Stack direction='row' sx={{my: 0.3, alignItems: 'center'}}>
											<Box
												sx={{
													width: 40,
													height: 40,
													backgroundColor: '#0088cc',
													borderRadius: '50%',
													display: 'grid',
													placeItems: 'center',
													mr: 1,
												}}
											>
												<Icon icon={fileIcon} width={24} height={24}/>
											</Box>
											<Stack sx={{overflow: 'hidden'}}>
												<Typography variant='body1' sx={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{values.file.name}</Typography>
												<Typography
													variant='caption'
													sx={{fontSize: '13px', lineHeight: 1.3}}
												>
													{formatBytes(values.file.size)}
												</Typography>
											</Stack>
										</Stack>
									)}
								</Stack>
								<Divider sx={{mt: 2, mb: 0.5}}/>
								<Stack
									direction='row'
									sx={{
										height: '100%',
										alignItems: 'center',
										pl: 2.5,
										pr: 1,
									}}
								>
									<input
										{...getFieldProps('description')}
										placeholder='Сообщение...'
										style={{
											width: '100%',
											height: '100%',
											marginRight: '5px',
											backgroundColor: 'transparent',
											outline: 'none',
											border: 'none',
											color: 'inherit',
											fontSize: '16px',
										}}
									/>
									<Stack>
										<Tooltip title='Отправить' placement='top' arrow>
											<IconButton color='inherit' type='submit'>
												{GetIcon(sendIcon)}
											</IconButton>
										</Tooltip>
									</Stack>
								</Stack>
							</Stack>
						</Form>
					</FormikProvider>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SignAndSendFile;
