/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Box, Card, Stack, Typography} from '@mui/material';
import clsx from 'clsx';
// @ts-ignore
import {downloadFile, getFileExt} from 'shared/functions';
import {deleteFileFromSlotCompanyCardFx, getFileFromSlotCompanyCardFx} from 'models/profile';
import {Accordion, AccordionDetails, AccordionSummary} from '../../styled/customAccordion/CustomAccordion';
// @ts-ignore
import {ReactComponent as AddIcon} from '../../../assets/images/buttons-icons/add.svg';
// @ts-ignore
import {ReactComponent as DownloadIcon} from '../../../assets/images/files-actions/download.svg';
// @ts-ignore
import {ReactComponent as DeleteIcon} from '../../../assets/images/files-actions/delete.svg';
// @ts-ignore
import moduleStyles from './CompanyCardSlots.module.scss';
import {CompanyCardSlot} from './CompanyCardSlots.types';
import {openAddFileModal} from '../../../models/modals/functions';
import CustomButton from "../../styled/customButton/CustomButton";
import FileIcon from "../../helpers/other/FileIcon";
import {ICompanyCard, ILongUser} from "../../../types";
import AddFileModal from "../../helpers/files/AddFileModal";

interface ICompanyCardSlotsProps {
	isContractor: boolean;
	user: ILongUser,
	companyCard: ICompanyCard;
	fetchUserCompanyCard: () => void;
}

const styles = moduleStyles as any;

const CompanyCardSlots = ({
	                          isContractor,
	                          user,
	                          companyCard,
	                          fetchUserCompanyCard
                          }: ICompanyCardSlotsProps): React.ReactElement => {
	const [expanded, setExpanded] = useState<null | number>(null);

	const handleChangeExpanded =
		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : null);
		};

	const handleAddFile = (slot: CompanyCardSlot) => () => {
		openAddFileModal(slot._id);
	};

	const handleDownloadFile = (slot: CompanyCardSlot) => async () => {
		if (slot.file) {
			const blob = await getFileFromSlotCompanyCardFx({
				userId: user.id,
				slotId: slot._id,
			});
			downloadFile(blob, slot.file.filename);
		}
	};

	const handleDeleteFile = (slot: CompanyCardSlot) => async () => {
		if (slot.file) {
			await deleteFileFromSlotCompanyCardFx({
				slotId: slot._id,
			});
			await fetchUserCompanyCard();
		}
	};

	return (
		<Card className={clsx(styles.card, isContractor && styles.cardIsContractor)}>
			<Box className={styles.title}>
				{companyCard.slots.length ? (
					<Typography>Документы карточки</Typography>
				) : (
					<Typography>Документы карточки отсутствуют</Typography>
				)}
			</Box>
			{!!companyCard.slots.length && (
				<Box sx={{mt: '1rem'}}>
					{companyCard.slots.map((slot, ind) => (
						<Accordion
							key={`${slot._id}-accordion`}
							expanded={ind === expanded}
							onChange={handleChangeExpanded(ind)}
						>
							<AccordionSummary>
								<Typography>{slot.title}</Typography>
							</AccordionSummary>
							<AccordionDetails>
								{!slot.file && !isContractor && (
									<Box className='d-flex-center'>
										<CustomButton withStartIcon onClick={handleAddFile(slot)}>
											<AddIcon/>
											<Box sx={{pl: 2, pr: 1}}>
												<span>Добавить файл</span>
											</Box>
										</CustomButton>
									</Box>
								)}
								{!slot.file && isContractor && (
									<Box className='d-flex-center'>
										<Typography>Файл отсутствует</Typography>
									</Box>
								)}
								{slot.file && (
									<Stack direction='row'>
										<Box className={styles.fileInfo}>
											<FileIcon ext={getFileExt(null, slot.file.filename)}/>
											<Typography>{slot.file.filename}</Typography>
										</Box>
										<Stack direction='row' spacing={1.5}>
											<CustomButton
												variant='icon'
												onClick={handleDownloadFile(slot)}
												style={{
													width: '30px',
													height: '30px',
												}}
											>
												<Box sx={{mt: '4px'}}>
													<DownloadIcon/>
												</Box>
											</CustomButton>
											{!isContractor && (
												<CustomButton
													variant='icon'
													onClick={handleDeleteFile(slot)}
													style={{width: '30px', height: '30px'}}
												>
													<DeleteIcon/>
												</CustomButton>
											)}
										</Stack>
									</Stack>
								)}
							</AccordionDetails>
						</Accordion>
					))}
				</Box>
			)}
		</Card>
	);
};

export default CompanyCardSlots;


// const CompanyCardSlots = ({
// 	                          targetUser,
// 	                          companyCard,
// 	                          readOnly = false,
// 	                          fetchUserCompanyCard,
//                           }: ICompanyCardSlotsProps): React.ReactElement => {
// 	const [expanded, setExpanded] = React.useState<null | number>(null);
//
// 	const handleChangeExpanded =
// 		(panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
// 			setExpanded(newExpanded ? panel : null);
// 		};
//
// 	const handleAddFileToSlotCompanyCard = (slotId: string) => async (data: FormData) => {
// 		await addFileToSlotCompanyCardFx({slotId, data});
// 	};
//
// 	const downloadFileFromSlotCompanyCard = (slot: ICompanyCardSlot) => async () => {
// 		if (slot.file) {
// 			const blob = await getFileFromSlotCompanyCardFx({
// 				userId: targetUser.id,
// 				slotId: slot._id,
// 			});
// 			downloadFile(blob, slot.file.filename);
// 		}
// 	};
//
// 	const deleteFileFromSlotCompanyCard = (slot: ICompanyCardSlot) => async () => {
// 		if (slot.file) {
// 			await deleteFileFromSlotCompanyCardFx({
// 				slotId: slot._id,
// 			});
// 			await fetchUserCompanyCard();
// 		}
// 	};
//
// 	return (
// 		<Stack>
// 			{!companyCard.slots.length ? (
// 				<NotFoundCard name='Документы карточки' variant='h5'/>
// 			) : (
// 				companyCard.slots.map((slot, ind) => (
// 					<Accordion
// 						key={`${slot._id}-accordion`}
// 						expanded={ind === expanded}
// 						onChange={handleChangeExpanded(ind)}
// 					>
// 						<AccordionSummary>
// 							<Typography>{slot.title}</Typography>
// 						</AccordionSummary>
// 						<AccordionDetails>
// 							{!slot.file && readOnly && (
// 								<Typography>Файл отсутствует</Typography>
// 							)}
// 							{!slot.file && !readOnly && (
// 								<Stack sx={{alignItems: 'center'}}>
// 									<AddFileModal
// 										addFileFunc={handleAddFileToSlotCompanyCard(slot._id)}
// 										fetchUpdatedFiles={fetchUserCompanyCard}
// 										noDescription
// 									/>
// 								</Stack>
// 							)}
// 							{slot.file && (
// 								<Stack direction='row' sx={{justifyContent: 'space-between'}}>
// 									<Stack direction='row' sx={{alignItems: 'center'}}>
// 										<Box
// 											sx={{
// 												width: 34,
// 												height: 34,
// 												backgroundColor: '#0088cc',
// 												borderRadius: '50%',
// 												display: 'grid',
// 												placeItems: 'center',
// 												mr: 1,
// 											}}
// 										>
// 											<Icon icon={fileIcon} width={20} height={20}/>
// 										</Box>
// 										<Stack>
// 											<Typography variant='body1' sx={{lineHeight: 1.3}}>
// 												{slot.file.filename}
// 											</Typography>
// 											<Stack direction='row' sx={{alignItems: 'center'}}>
// 												<Typography
// 													variant='caption'
// 													sx={{fontSize: '12px', lineHeight: 1.3}}
// 												>
// 													{formatBytes(slot.file.length)}
// 												</Typography>
// 												<Box
// 													sx={{
// 														width: '6px',
// 														height: '1px',
// 														mx: '5px',
// 														mt: '-1px',
// 														backgroundColor: 'white',
// 													}}
// 												/>
// 												<CustomButton onClick={downloadFileFromSlotCompanyCard(slot)}>
// 													Скачать
// 												</CustomButton>
// 											</Stack>
// 										</Stack>
// 									</Stack>
// 									{!readOnly && <Stack>
//                                         <Tooltip title='Удалить файл' placement='top' arrow>
//                                             <IconButton
//                                                 color='inherit'
//                                                 onClick={deleteFileFromSlotCompanyCard(slot)}
//                                             >
// 												{GetIcon(fileRemoveIcon)}
//                                             </IconButton>
//                                         </Tooltip>
//                                     </Stack>}
// 								</Stack>
// 							)}
// 						</AccordionDetails>
// 					</Accordion>
// 				))
// 			)}
// 		</Stack>
// 	);
// };
//
// export default CompanyCardSlots;
