/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Container} from '@mui/material';
import {Outlet} from 'react-router-dom';
import Header from './header/Header';
import MainWrapper from './styled/MainWrapper';
import PageWrapper from './styled/PageWrapper';

const HeaderLayout = (): React.ReactElement => (
		<MainWrapper>
			<Header/>
			<PageWrapper>
				<Container maxWidth={false}>
					<Box sx={{minHeight: 'calc(100vh - 70px)'}}>
						<Outlet/>
					</Box>
				</Container>
			</PageWrapper>
		</MainWrapper>
	);

export default HeaderLayout;
