/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Card, Divider, Link, Tooltip, Typography} from '@mui/material';
import _ from 'lodash';
// @ts-ignore
import {ReactComponent as ChangePasswordIcon} from '../../../../assets/images/profile-info-actions/change-password.svg';
// @ts-ignore
import {ReactComponent as ChangeCompanyCardIcon} from '../../../../assets/images/profile-info-actions/change-company-card.svg';
// @ts-ignore
import FactorIcon from '../../../../assets/images/userLogo.svg';
// @ts-ignore
import moduleStyles from './InfoContainer.module.scss';
import {InfoContainerProps} from './InfoContainer.types';
import {openChangeCompanyCardModal, openChangePasswordModal} from "../../../../models/modals/functions";

const styles = moduleStyles as any;

const userTypeTranslate = {
	'supplier': 'Поставщик',
	'factor': 'Фактор',
	'debtor': "Дебитор",
	'admin': 'Администратор'
}

const companyCardFieldsTranslate = {
	name: 'Название',
	type: 'Тип',
	site: 'Сайт',
	locationAddress: 'Адрес',
};

const InfoContainer = ({user, companyCard, isContactor}: InfoContainerProps): React.ReactElement => {
	const handleChangeAvatar = () => {
		console.log('ChangeAvatar');
	};

	return (
		<Card className={styles.card}>
			<Box className='d-flex-align-center'>
				<Box>
					<Box className={styles.userLogoIcon}>
						<Box component='img' src={FactorIcon}/>
					</Box>
					{!isContactor && false && (
						<Link href='/' component='button' underline='hover' onClick={handleChangeAvatar}>
							Сменить аватар
						</Link>
					)}
				</Box>
				<Box className={styles.userInfo}>
					<Typography component='h6'>{user.name}</Typography>
					<Typography>Почта: {user.email}</Typography>
					<Typography>Инн: {user.inn}</Typography>
					<Typography>Тип: {userTypeTranslate[user.type]}</Typography>
				</Box>
				{!isContactor && (
					<Box className={styles.actionsBlock}>
						<Box>
							<Tooltip title='Изменить пароль' placement='top' arrow>
								<button type='button' onClick={openChangePasswordModal}>
									<ChangePasswordIcon/>
								</button>
							</Tooltip>
						</Box>
						<Box>
							<Tooltip title='Изменить данные карточки компании' placement='top' arrow>
								<button type='button' onClick={openChangeCompanyCardModal}>
									<ChangeCompanyCardIcon/>
								</button>
							</Tooltip>
						</Box>
					</Box>
				)}
			</Box>
			<Divider/>
			<Box>
				<Box className={styles.companyCardTitle}>
					<Typography>О компании</Typography>
				</Box>
				<Box className={styles.companyCardList}>
					{_.entries(companyCard)
						.map(([key, value]) => ({
							// @ts-ignore
							label: companyCardFieldsTranslate[key],
							value,
						})).filter(item => item.label !== undefined)
						.map(item => (
							<Box key={item.label}>
								<Typography component='span' noWrap>
									{item.label}
								</Typography>
								<Typography noWrap>{item.value}</Typography>
							</Box>
						))}
				</Box>
			</Box>
		</Card>
	);
};

export default InfoContainer;
