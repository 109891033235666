/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Card, Container, Divider, Stack, Typography} from '@mui/material';
import LoginForm from 'components/pages/login/LoginForm';
import MHidden from 'components/helpers/other/MHidden';
// @ts-ignore
import {ReactComponent as LoginIllustration} from 'assets/images/LoginPageSVG.svg';

const LoginPage = (): React.ReactElement => (
    <Stack sx={{height: '100vh', overflow: 'hidden'}} direction='row'>
        <MHidden breakpoint='md' dir='down'>
            <Card
                sx={{
                    width: '100%',
                    maxWidth: 464,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    margin: theme => theme.spacing(2, 0, 2, 2),
                    backgroundColor: '#1D334A',
                    opacity: '85%',
                }}
            >
                <Stack sx={{my: 3}}>
                    <LoginIllustration style={{alignSelf: 'center', width: '96%', height: 'auto'}}/>
                </Stack>
            </Card>
        </MHidden>
        <Container maxWidth='sm'>
            <Box
                sx={{
                    maxWidth: 520,
                    margin: 'auto',
                    display: 'flex',
                    minHeight: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Stack sx={{mb: 2, alignItems: 'center'}}>
                    <Typography variant='h3'>Авторизация</Typography>
                </Stack>
                <LoginForm/>
                <Divider/>
                <div style={{textAlign:"center", color: "grey", paddingTop: "10px"}}>По вопросам сотрудничества: <div
                    style={{paddingTop: "5px"}}>Email: factoring@sgtk.su</div>
                    <div style={{paddingTop: "5px"}}>Телефон: +7(495)761-20-99</div>
                </div>
            </Box>
        </Container>
    </Stack>
);

export default LoginPage;
