/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {fetchSignedAttachmentsFx} from "./index";
import {fetchSignedAttachments} from "../../api";

fetchSignedAttachmentsFx.use(fetchSignedAttachments);