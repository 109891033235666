/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, IconButton, InputAdornment, TextField} from '@mui/material';
import {useStore} from 'effector-react';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';
import CustomButton from 'components/styled/customButton/CustomButton';
import {Box} from "@mui/system";
import {Visibility, VisibilityOff} from "@mui/icons-material";
// @ts-ignore
import {ReactComponent as PatchIcon} from '../../../../assets/images/buttons-icons/patch.svg';
import CustomDialogTitle from "../../../helpers/other/CustomDialogTitle";
// @ts-ignore
import moduleStyles from './ChangePasswordModal.module.scss';
import {$modals} from "../../../../models/modals";
import {ChangePasswordForm} from "./ChangePasswordModal.types";
import {closeChangePasswordModal} from "../../../../models/modals/functions";
import {patchUserInfoFx} from "../../../../models/profile";

const styles = moduleStyles as any;

const getInputAdornment = (showPassword: any, setShowPassword: any, field: string) => (
	<InputAdornment position='end' sx={{mr: 1}}>
		<IconButton
			onClick={() =>
				setShowPassword((prev: any) => ({
					...prev,
					[field]: !prev[field],
				}))
			}
			edge='end'
		>
			{showPassword[field] ? <VisibilityOff/> : <Visibility/>}
		</IconButton>
	</InputAdornment>
);

const ChangePasswordModal = ({fetchUserInfo}: { fetchUserInfo: Function }): React.ReactElement => {
	const {ChangePasswordModal: modalState} = useStore($modals);

	const [showPassword, setShowPassword] = useState({
		oldPassword: false,
		password: false,
	});

	const ChangePasswordSchema = Yup.object().shape({
		oldPassword: Yup.string().min(8, 'Длина пароля должна быть не меньше 8 символов').required('Поле является обязательным'),
		password: Yup.string().min(8, 'Длина пароля должна быть не меньше 8 символов').required('Поле является обязательным'),
	});

	const formik = useFormik<ChangePasswordForm>({
		initialValues: {
			oldPassword: '',
			password: '',
		},
		validationSchema: ChangePasswordSchema,
		onSubmit: async data => {
			await patchUserInfoFx({data});
			closeChangePasswordModal();
			fetchUserInfo();
		},
	});

	const {errors, touched, handleSubmit, getFieldProps, resetForm} = formik;

	const closeModal = () => {
		closeChangePasswordModal();
		setTimeout(() => {
			resetForm();
		}, 300);
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '100%', maxWidth: '700px'}}}
			onClose={closeModal}
			aria-labelledby='customized-dialog-title'
			open={modalState.open}
		>
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
					<CustomDialogTitle onClose={closeModal}>Изменить пароль</CustomDialogTitle>
					<DialogContent sx={{p: 0}}>
						<Box className={styles.content}>
							<Box>
								<TextField
									fullWidth
									label='Введите старый пароль'
									{...getFieldProps('oldPassword')}
									error={Boolean(touched.oldPassword && errors.oldPassword)}
									helperText={touched.oldPassword && errors.oldPassword}
									type={showPassword.oldPassword ? 'text' : 'password'}
									InputProps={{
										endAdornment: getInputAdornment(
											showPassword,
											setShowPassword,
											'oldPassword'
										),
									}}
								/>
								<TextField
									fullWidth
									label='Новый пароль'
									{...getFieldProps('password')}
									error={Boolean(touched.password && errors.password)}
									helperText={touched.password && errors.password}
									type={showPassword.password ? 'text' : 'password'}
									InputProps={{
										endAdornment: getInputAdornment(
											showPassword,
											setShowPassword,
											'password'
										),
									}}
								/>
							</Box>
						</Box>
					</DialogContent>
					<DialogActions className={styles.actions}>
						<CustomButton withStartIcon type='submit'>
							<PatchIcon/>
							<Box>
								<span>Изменить</span>
							</Box>
						</CustomButton>
					</DialogActions>
				</Form>
			</FormikProvider>
		</Dialog>
	);
};

export default ChangePasswordModal;
