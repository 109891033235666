/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect} from "effector";

export const addFileRequestToDiadocFx = createEffect<{ data: FormData }, any>();
export const fetchDiadocOrgsFx = createEffect<void, any>();
export const fetchDiadocPartnersFx = createEffect<{ orgId: string }, any>();
export const fetchDiadocDocumentsFx = createEffect<{ boxId: string, partnerBoxId: string, filterCategory: string, fromDocumentDate: string, toDocumentDate: string }, any>();
export const fetchDiadocDocumentTypesFx = createEffect<{ boxId: string }, any>();