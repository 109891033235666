/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Box, Card, Divider, Stack, Typography} from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';
// @ts-ignore
import {ReactComponent as AddIcon} from '../../../../assets/images/buttons-icons/add.svg';
// @ts-ignore
import {ReactComponent as DownloadIcon} from '../../../../assets/images/files-actions/download.svg';
// @ts-ignore
import {ReactComponent as DeleteIcon} from '../../../../assets/images/files-actions/delete.svg';
// @ts-ignore
import moduleStyles from './FilesContainer.module.scss';
import CustomButton from "../../../styled/customButton/CustomButton";
import SearchBar from "../../../styled/searchBar/SearchBar";
import FileIcon from "../../../helpers/other/FileIcon";
import {downloadFile, getFileExt} from "../../../../shared/functions";
import {openAddFileModal} from "../../../../models/modals/functions";
import {deleteFileFx, getFileFx} from "../../../../models/profile";
import {ISmallFile} from "../../../../types";
import NotFoundCard from "../../../helpers/not-found/NotFoundCard";

const styles = moduleStyles as any;

const FilesContainer = ({
	                        readonly = false, files, fetchProfileFiles = () => {
	}, fileDownload
                        }: { readonly?: boolean, files: ISmallFile[], fetchProfileFiles?: Function, fileDownload?: Function }): React.ReactElement => {
	const [filterValue, setFilterValue] = useState("");
	const handleAddFile = () => {
		openAddFileModal();
	};

	const handleDownloadFile = (file: ISmallFile) => async () => {
		let blob;
		if (fileDownload === undefined)
			blob = await getFileFx({fileId: String(file.id)});
		else
			blob = await fileDownload(file);
		downloadFile(blob, file.filename);
	};

	const handleDeleteFile = (file: ISmallFile) => async () => {
		await deleteFileFx({fileId: String(file.id)});
		await fetchProfileFiles();
	};

	return (
		<Card className={styles.card}>
			<Box className={clsx(styles.header, files.length && styles.headerItemsIsFound)}>
				{!!files.length && (
					<Box>
						<SearchBar setValue={setFilterValue}/>
					</Box>
				)}
				{/* eslint-disable-next-line no-nested-ternary */}
				<Box sx={{textAlign: readonly ? 'start !important' : 'inherit'}}>
					<Typography>{readonly ? 'Файлы' : 'Мои файлы'}</Typography>
				</Box>
				{!readonly && <Box>
                    <CustomButton withStartIcon onClick={handleAddFile}>
                        <AddIcon/>
                        <Box sx={{pl: 2, pr: 1}}>
                            <span>Добавить файл</span>
                        </Box>
                    </CustomButton>
                </Box>}
			</Box>
			{!!files.length && (
				<Stack direction='row' sx={{mt: 2, overflowX: 'auto'}} spacing={4}>
					{_.chunk(files.filter(file => filterValue.length === 0 || file.filename.includes(filterValue)), 4).map((chunk, chunkIndex) => (
						<Stack key={chunkIndex}>
							{chunk.map((file, i) => (
								<Box key={file.id}>
									{i !== 0 && <Divider/>}
									<Stack direction='row' sx={{py: 2}}>
										<Box className={styles.fileInfo}>
											<FileIcon ext={getFileExt(null, file.filename)}/>
											<Typography noWrap>{file.filename}</Typography>
										</Box>
										<Stack sx={{mt: '2px'}} direction='row' spacing={1.5}
										       className={styles.fileActions}>
											<CustomButton variant='icon' onClick={handleDownloadFile(file)}>
												<Box sx={{mt: '4px'}}>
													<DownloadIcon/>
												</Box>
											</CustomButton>
											{!readonly && <CustomButton variant='icon' onClick={handleDeleteFile(file)}>
                                                <DeleteIcon/>
                                            </CustomButton>}
										</Stack>
									</Stack>
								</Box>
							))}
						</Stack>
					))}
				</Stack>
			)}
			{!files.length && <Box sx={{p: 3}}><NotFoundCard name='Файлы'/></Box>}
		</Card>
	);
};

export default FilesContainer;
