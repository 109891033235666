/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useCallback, useMemo, useState} from 'react';
import {Box, Card, Divider, Stack, Typography} from '@mui/material';
import clsx from 'clsx';
// @ts-ignore
import requestFinancingIcon from "@iconify-icons/ic/outline-request-page";
//@ts-ignore
import {isFunction} from "lodash";
//@ts-ignore
import {ReactComponent as Confirm1Icon} from '../../../assets/images/buttons-icons/confirm1.svg';
//@ts-ignore
import moduleStyles from './TableContainer.module.scss';
import {initialTabs,} from './TableContainer.content';
import SearchBar from '../searchBar/SearchBar';
import {Tab} from './TableContainer.types';
import {Column, Row} from '../paginationTable/PaginationTable.types';
//@ts-ignore
import CustomButton from '../customButton/CustomButton';
import NewPaginationTable from '../paginationTable/PaginationTable';
import RequestFinancingModal, {
	handleRequestFinancingModalOpen
} from "../../pages/profile/shipments/RequestFinancingModal";
import AddDebtorModal from "../../helpers/debtors/AddDebtorModal";
import {IAddSupplierDebtorFormValues} from "../../helpers/debtors/AddDebtorForm";
import {addSupplierDebtorFx} from "../../../models/suppliers";
import IssuesModal from "../../pages/contractors/issues/IssuesModal";
import {ILongUser} from "../../../types";
import {openChat} from "../../features/chat/Chat";

const styles = moduleStyles as any;

interface TableContentConfig {
	[key: string]: { columns: Array<Column>; rows: Array<Row>, active?: boolean, rowOnClick?: (row: Row) => void };
}

const TableContainer = ({
	                        tableContentConfig, fetchSupplierDebtors = () => {
	}, reloadOnTabChange = () => {
	}
                        }: { tableContentConfig: TableContentConfig, fetchSupplierDebtors?: Function, reloadOnTabChange?: Function }): React.ReactElement => {
	const [filterValue, setFilterValue] = useState("");

	const [tabs, setTabs] = useState<Array<Tab>>(
		initialTabs.filter(tab => Object.keys(tableContentConfig).filter(key => tableContentConfig[key].active === undefined || tableContentConfig[key].active).includes(tab.id)).map((tab, idx, arr) => ({
			...tab,
			active: (arr.findIndex(fTab => fTab.id === localStorage.getItem('activeTab')) === -1 || localStorage.getItem('activeTab') === null) ? idx === 0 : tab.id === localStorage.getItem('activeTab')
		}))
	);

	const activeTab = useMemo(() => tabs.find(tab => tab.active)!, [tabs]);

	const handleClickTab = useCallback(
		(selectedTab: Tab) => () => {
			if (!selectedTab.active) {
				reloadOnTabChange();
				setTabs(prev => prev.map(tab => ({...tab, active: tab.id === selectedTab.id})));
				localStorage.setItem('activeTab', selectedTab.id);
			}
		},
		[initialTabs]
	);

	const handleAddDebtor = async (params: IAddSupplierDebtorFormValues) => {
		await addSupplierDebtorFx({...params, supplyAmount: params.supplyAmount * 100});
		await fetchSupplierDebtors();
	};

	return (
		<Box className={styles.tableContainer}>
			<Card>
				<Box className={styles.header}>
					<Box className={styles.tabs}>
						{tabs.map(tab => (
							<React.Fragment key={tab.id}>
								<Divider orientation='vertical'/>
								<Stack
									className={clsx(styles.tab, tab.active && styles.tabActive)}
									onClick={handleClickTab(tab)}
								>
									{tab.active ? tab.activeIcon : tab.icon}
									<Box>
										<Typography>{tab.title}</Typography>
									</Box>
								</Stack>
							</React.Fragment>
						))}
						<Divider orientation='vertical'/>
					</Box>

					<Box className={styles.title}>
						<Typography>{activeTab.title}</Typography>
						<span>{tableContentConfig[activeTab.id].rows.length}</span>
					</Box>
					<SearchBar setValue={setFilterValue}/>
				</Box>
				{activeTab.id === 'myDebtors' && <AddDebtorModal addDebtorFunc={handleAddDebtor}/>}
				<NewPaginationTable
					columns={tableContentConfig[activeTab.id].columns.filter(col => col.active === undefined || col.active)}
					rows={tableContentConfig[activeTab.id].rows.filter(row =>
						filterValue.length === 0
						|| ('inn' in row && row.inn.includes(filterValue))
						|| ('name' in row && row.name.includes(filterValue))
						|| ('author' in row && row.author.includes(filterValue))
						|| ('participant' in row && row.participant.includes(filterValue))
						|| ('debtor' in row && row.debtor.includes(filterValue))
						|| ('createdAt' in row && row.createdAt.includes(filterValue))
					)}
					rowOnClick={tableContentConfig[activeTab.id].rowOnClick}
					rowsActions={[
						{
							key: 'chatList',
							element: (row: Row) => (
								<Stack alignItems='center'>
									<IssuesModal row={row as ILongUser}/>
								</Stack>
							),
						},
						{
							key: 'openChat',
							element: (row: Row) => (
								<Stack alignItems='center'>
									<CustomButton
										key={row.id}
										onClick={() => openChat(row.chatId.toString())}
									>
										<span>Открыть чат</span>
									</CustomButton>
								</Stack>
							),
						},
						{
							key: 'financingRequest',
							active: (row: Row) => !!row.factor && !!row.notificationSigned,
							element: (row: Row) => (
								<Stack alignItems='center'>
									<RequestFinancingModal/>
									<CustomButton
										variant='exception'
										withStartIcon
										onClick={() => {
											handleRequestFinancingModalOpen(row._id, row.factor.id)
										}}
									>
										<Confirm1Icon/>
										<Box sx={{pl: 1.5, pr: 0.5}}>
											<span>Запросить финансирования</span>
										</Box>
									</CustomButton>
								</Stack>
							),
						},
					]}
				/>
			</Card>
		</Box>
	);
};

export default TableContainer;
