/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {addSupplierDebtorFx, fetchSupplierDebtorsFx, fetchSupplierFactorsFx, fetchSuppliersListFx} from './index';
import {addSupplierDebtor, fetchSupplierDebtorsList, fetchSupplierFactorsList, fetchSuppliersList} from '../../api';

fetchSuppliersListFx.use(fetchSuppliersList);
fetchSupplierDebtorsFx.use(fetchSupplierDebtorsList);
fetchSupplierFactorsFx.use(fetchSupplierFactorsList);
addSupplierDebtorFx.use(addSupplierDebtor);