/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {IAuthResponse, ILoginRequestParams, ILongUser} from 'types';
import {apiRequest, IData} from './index';

export const login = async (values: ILoginRequestParams) => {
	const response = await apiRequest<IAuthResponse>({
		url: '/api/auth/login',
		method: 'POST',
		data: values,
	});
	return response.data;
};

export const fetchCurrentUser = async () => {
	const response = await apiRequest<IData<ILongUser>>({
		url: '/api/auth/currentUser',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};
