/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Card, IconButton, List, ListItem, ListItemText, Stack, Tooltip} from '@mui/material';
import fileDocumentIcon from '@iconify-icons/gg/file-document';
import {ICompanyCard, IIssueWithExpandUsers, IResponsesListItem} from 'types';
import {GetIcon} from '../../helpers/other/GetIcon';
import NotFoundCard from '../../helpers/not-found/NotFoundCard';
import {downloadCompanyCardSlotFileFx} from '../../../models/issues';
import {downloadFile} from '../../../shared/functions';
import {getFileFromSlotCompanyCardFx} from '../../../models/profile';

interface ISlotsTabProps {
	chatInfo?: IIssueWithExpandUsers;
	responseItem?: IResponsesListItem;
	companyCard: ICompanyCard;
}

const SlotsTab = ({chatInfo, responseItem, companyCard}: ISlotsTabProps): React.ReactElement => (
	<Stack>
		{!companyCard.slots.length ? (
			<NotFoundCard name='Документы карточки' variant='h5'/>
		) : (
			<List sx={{p: 0}}>
				<Stack spacing={1}>
					{companyCard.slots.map(slot => (
						<Card sx={{p: 0.5, m: 0}} key={slot._id}>
							<ListItem
								secondaryAction={
									slot.file && (
										<Tooltip title='Скачать файл' placement='top' arrow>
											<IconButton
												edge='end'
												onClick={async () => {
													const filename =
														slot.file && slot.file.filename
															? slot.file.filename
															: 'Без названия';
													const blob =
														chatInfo && responseItem
															? await downloadCompanyCardSlotFileFx({
																issueId: chatInfo.id,
																responseId: responseItem.id,
																slotId: slot._id,
																fileId: slot.file!._id,
															})
															: await getFileFromSlotCompanyCardFx({
																userId: companyCard.user,
																slotId: slot._id,
															});
													downloadFile(blob, filename);
												}}
											>
												{GetIcon(fileDocumentIcon)}
											</IconButton>
										</Tooltip>
									)
								}
							>
								<ListItemText primary={slot.title}/>
							</ListItem>
						</Card>
					))}
				</Stack>
			</List>
		)}
	</Stack>
);

export default SlotsTab;
