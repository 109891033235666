/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {openErrorSnackbar} from 'components/helpers/snackbars/ErrorSnackbar';
import {$user, logout} from 'models/auth';

import {axios} from './axios';
import {INotification} from "../models/notifications";

export * from './attachments';
export * from './profile';
export * from './suppliers';
export * from './factors';
export * from './debtors';
export * from './issues/issues';
export * from './limit';
export * from './diadoc';
export * from './auth';
export * from './docPy';

export interface IData<T> {
	data: T;
	notifications: INotification[];
}

interface IError {
	error: string;
	errors: null;
	message: string | Array<string>;
	path: string;
	stack: string;
	status: string;
	statusCode: number;
	timestamp: string;
}

export async function apiRequest<T>(
	_config: AxiosRequestConfig,
	ignoreErrors = false
): Promise<AxiosResponse<T>> {
	const {token} = $user.getState();
	let cbResponse;
	const config = {
		headers: {'Content-type': 'application/json', Authorization: ''},
		..._config,
	};
	if (token) config.headers.Authorization = `Bearer ${token}`;
	try {
		cbResponse = await axios.request<T>(config);
	} catch (err: any) {
		cbResponse = err.response;
		if (!cbResponse) openErrorSnackbar('Ошибка соединения с сервером, ответ не получен');
		else if (cbResponse.data.statusCode === 401 && token) {
			openErrorSnackbar(cbResponse.data.error);
			logout();
			window.history.pushState('', '', '/login');
			window.history.pushState('', '', '/login');
			window.history.go(-1);
		} else if (cbResponse.data.statusCode === 500)
			openErrorSnackbar('Ошибка на сервере');
	}
	if (cbResponse.data && !ignoreErrors && 'error' in cbResponse.data) {
		openErrorSnackbar(cbResponse.data.error);
		throw new Error(cbResponse.data.error);
	}
	return cbResponse;
}
