/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, Stack, Tab, Tabs, TextField, Typography,} from '@mui/material';
import {ICompanyCard, IIssueWithExpandUsers, IPatchUserCompanyCardParams, IResponsesListItem} from 'types';
import {Icon} from '@iconify/react';
import fileIcon from '@iconify/icons-eva/file-fill';
import {useStore} from 'effector-react';
import CustomButton from 'components/styled/customButton/CustomButton';
import GrowTransition from '../../helpers/transitions/GrowTransition';
import {TabPanel} from '../../helpers/other/Tabs';
import SlotsTab from './SlotsTab';
import {fetchUserCompanyCardFx} from '../../../models/profile';
import Spinner from '../../helpers/other/Spinner';
import {companyCardEditFormFields, companyCardEditFormFieldsTranslate} from "./CompanyCardEditForm";

interface ICompanyCardModalProps {
	chatInfo?: IIssueWithExpandUsers;
	responseItem?: IResponsesListItem;
	companyCardFromProps?: ICompanyCard;
}

const CompanyCardModal = ({
	                          chatInfo,
	                          responseItem,
	                          companyCardFromProps,
                          }: ICompanyCardModalProps): React.ReactElement => {
	const fetchUserCompanyCardPending = useStore(fetchUserCompanyCardFx.pending);

	const [modalOpen, setModalOpen] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [companyCard, setCompanyCard] = useState<null | ICompanyCard>(
		companyCardFromProps || null
	);

	const handleModalOpen = () => setModalOpen(true);
	const handleModalClose = () => setModalOpen(false);

	const fetchUserCompanyCard = async () => {
		const response = await fetchUserCompanyCardFx();
		setCompanyCard(response);
	};

	useEffect(() => {
		if (modalOpen) {
			setTabIndex(0);
			if (!companyCardFromProps) fetchUserCompanyCard().then();
		}
	}, [modalOpen]);

	return (
		<>
			<Stack direction='row' sx={{my: 0.3}}>
				<Box
					sx={{
						width: 30,
						height: 30,
						backgroundColor: '#8cd3ff',
						borderRadius: '50%',
						display: 'grid',
						placeItems: 'center',
						mr: 0.5,
					}}
				>
					<Icon icon={fileIcon} width={18} height={18}/>
				</Box>
				<Stack>
					<Typography variant='body2' sx={{fontSize: '14px', lineHeight: 1.3}}>
						Карточка компании
					</Typography>
					<CustomButton variant='exception1' onClick={handleModalOpen}>
						Открыть
					</CustomButton>
				</Stack>
			</Stack>
			<Dialog
				PaperProps={{sx: {width: '50vw', position: 'absolute', top: 0}}}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={modalOpen}
			>
				<DialogContent sx={{py: 2.5}}>
					{!companyCard || fetchUserCompanyCardPending ? (
						<Spinner size='3rem'/>
					) : (
						<Stack spacing={2}>
							<Stack sx={{borderBottom: 1, borderColor: 'divider', alignItems: 'center'}}>
								<Tabs
									value={tabIndex}
									onChange={(event: React.SyntheticEvent, newValue: number) =>
										setTabIndex(newValue)
									}
								>
									<Tab label='Данные карточки'/>
									<Tab label='Документы карточки'/>
								</Tabs>
							</Stack>
							<TabPanel value={tabIndex} index={0} sx={{pt: 1}}>
								<Stack
									spacing={2}
									sx={{
										'.MuiInputLabel-root': {
											color: 'inherit !important',
										},
										'.MuiInputBase-input': {
											WebkitTextFillColor: 'inherit !important',
										},
									}}
								>
									{companyCardEditFormFields
										.filter(f => !['directorFIORP', 'bankDetails'].includes(f))
										.map(f => ({
											label: companyCardEditFormFieldsTranslate[f],
											value: companyCard[f],
										}))
										.map(item => (
											<TextField
												key={item.label}
												fullWidth
												label={item.label}
												value={item.value || '-'}
												disabled
											/>
										))}
								</Stack>
							</TabPanel>
							<TabPanel value={tabIndex} index={1}>
								<SlotsTab
									chatInfo={chatInfo}
									responseItem={responseItem}
									companyCard={companyCard}
								/>
							</TabPanel>
						</Stack>
					)}
				</DialogContent>
				<DialogActions sx={{mt: 0}}>
					<Stack direction='row' spacing={1} sx={{width: '100%'}}>
						<CustomButton onClick={handleModalClose}>
							Закрыть
						</CustomButton>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CompanyCardModal;
