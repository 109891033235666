/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {apiRequest, IData} from "./index";
import {updateNotifications} from "../models/notifications";

export const fetchSignedAttachments = async () => {
	const response = await apiRequest<IData<any>>({
		url: '/api/attachments/signed',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};