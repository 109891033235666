/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Divider, Stack, Tooltip, Typography} from '@mui/material';
import {DateTime} from 'luxon';
import fileIcon from '@iconify/icons-eva/file-fill';
import {Icon} from '@iconify/react';
import {Effect} from "effector";
import CustomButton from 'components/styled/customButton/CustomButton';
import {IIssueWithExpandUsers, ILongUser, IResponsesListItem, UserType} from '../../../types';
import {downloadResponseFileFx, makeFileSignedInIssueFx, replaceSignedFileInIssueFx} from '../../../models/issues';
import {blobToFile, downloadFile, formatBytes, getFileExt} from '../../../shared/functions';
import CompanyCardModal from '../company-card/CompanyCardModal';
import DebtorsModal from "../modals/DebtorsModal";
import ShipmentsModal from "../modals/ShipmentsModal";
import {handleAddSignAndSendFileModalOpen} from "./AddSignAndSendFile";
import FileIcon from "../../helpers/other/FileIcon";
// @ts-ignore
import {ReactComponent as DownloadIcon} from '../../../assets/images/files-actions/download.svg';
// @ts-ignore
import {ReactComponent as ReplaceIcon} from '../../../assets/images/files-actions/delete.svg';
// @ts-ignore
import {ReactComponent as SignIcon} from '../../../assets/images/files-actions/sign.svg';
// @ts-ignore
import {ReactComponent as InfoIcon} from '../../../assets/images/files-actions/info.svg';
import {IssueType} from "../../../types/enum/issueType";
import {openAddFileModal} from "../../../models/modals/functions";

const chatMessageItemColorPalette = ['#ff8686', '#ffb585', '#ffdc5f', '#2ce879', '#8cd3ff'];

interface IMessageItemProps {
	chatInfo: IIssueWithExpandUsers;
	prevItem: IResponsesListItem | undefined;
	item: IResponsesListItem;
	user: ILongUser | null;
	activeMode: string;
}

const MessageItem = ({chatInfo, prevItem, item, user, activeMode}: IMessageItemProps): React.ReactElement => {
	const getInitials = () => item.author.name.slice(0, 2).toUpperCase();

	const downloadResponseFile = async () => {
		if ('file' in item) {
			const blob = await downloadResponseFileFx({
				issueId: chatInfo.id,
				responseId: item.id,
				fileId: item.file._id,
			});
			downloadFile(blob, item.file.filename);
		}
	};

	const handleReplaceFile = () => {
		if ('file' in item) {
			openAddFileModal(
				undefined,
				true,
				chatInfo.id,
				item.id,
				item.file._id
			);
		}
	};

	const getButtonText = (btnField: string) => {
		let field;
		if (user?.id === item.author.id)
			field = 'signedByAuthor';
		else if ([chatInfo.participant.id, chatInfo.author.id].includes(user?.id as string))
			field = 'signedByParticipant';
		else
			field = 'signedByNotifier';
		if (field === btnField) { // @ts-ignore
			return item[btnField] ? 'Подписано' : 'Подписать';
		}
		// @ts-ignore
		return item[btnField] ? 'Подписано' : 'Не подписано';
	}

	const handleSignClick = async (signFuncName: string, field: string, apiFunc: Effect<any, any>) => {
		if ('file' in item) {
			handleAddSignAndSendFileModalOpen(
				signFuncName,
				apiFunc,
				blobToFile(await downloadResponseFileFx({
					issueId: chatInfo.id,
					responseId: item.id,
					fileId: item.file._id,
				}), item.file.filename),
				field,
				chatInfo.id,
				item.id,
				item.file._id
			);
		}
	}

	return (
		<Stack direction='row' spacing={0.8}
		       sx={{mt: (!prevItem || prevItem.author.id === item.author.id) ? '1px' : '12px'}}>
			{(!prevItem || (prevItem.author.id !== item.author.id)) && (<Stack>
				<Box
					sx={{
						width: 30,
						height: 30,
						backgroundColor:
							item.author.id === chatInfo.author.id
								? chatMessageItemColorPalette[4]
								: chatMessageItemColorPalette[0],
						borderRadius: '50%',
						display: 'grid',
						placeItems: 'center',
					}}
				>
					<Typography variant='caption' sx={{lineHeight: 1, color: '#ffffff', fontSize: '1rem'}}>
						<strong>{getInitials()}</strong>
					</Typography>
				</Box>
			</Stack>)}
			<Stack sx={{width: '90%', ml: '35px'}} spacing={0.1}>
				<Stack direction='row' sx={{justifyContent: 'space-between'}}>
					{(!prevItem || (prevItem.author.id !== item.author.id)) && (<Typography
						sx={{
							fontSize: '1.25rem',
							lineHeight: 1.2,
							color:
								item.author.id === chatInfo.author.id
									? chatMessageItemColorPalette[4]
									: chatMessageItemColorPalette[0],
						}}
					>
						{item.author.name}
					</Typography>)}
					{(!prevItem || (prevItem.author.id !== item.author.id) || (DateTime.fromISO(item.createdAt).diff(DateTime.fromISO(prevItem.createdAt), ['minutes']).minutes > 5)) && (
						<Typography sx={{ml: 'auto', lineHeight: 1.2, fontSize: '1rem'}}>
							{DateTime.fromISO(item.createdAt).toLocaleString(DateTime.TIME_24_SIMPLE)}
						</Typography>)}
				</Stack>
				<Stack sx={{pr: 1}}>
					{
						// prettier-ignore
						(item.type === 'text' && 'text' in item)
						&& <Typography sx={{fontSize: '1rem'}}>{item.text}</Typography>
					}
					{
						// prettier-ignore
						(item.type === 'companyCard' && 'companyCard' in item)
						&& (
							<Stack>
								<CompanyCardModal
									chatInfo={chatInfo}
									responseItem={item}
									companyCardFromProps={item.companyCard}
								/>
								<Stack>
									<Typography sx={{fontSize: '1rem'}}>{item.text}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'debtors' && 'debtors' in item)
						&& (
							<Stack>
								<DebtorsModal
									debtorsFromProps={item.debtors}
									updated={item.updated}
								/>
								<Stack>
									<Typography sx={{fontSize: '1rem'}}>{item.text}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'shipments' && 'shipments' in item)
						&& (
							<Stack>
								<ShipmentsModal
									shipmentsFromProps={item.shipments}
								/>
								<Stack>
									<Typography sx={{fontSize: '1rem'}}>{item.text}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'file' && 'file' in item)
						&& (
							<Stack sx={{maxWidth: '100%'}}>
								<Stack direction='row' sx={{my: 0.3}}>
									<FileIcon ext={getFileExt(null, item.file.filename)}/>
									<Stack sx={{maxWidth: '45%'}}>
										<Typography noWrap variant='body2'
										            sx={{fontSize: '1rem', lineHeight: 1.3}}>
											{item.file.filename}
										</Typography>
										<Typography
											variant='caption'
											sx={{fontSize: '0.75rem', lineHeight: 1.3}}
										>
											{formatBytes(item.file.length)}
										</Typography>
									</Stack>
									{item.updated && (
										<Stack sx={{m: 'auto 0px auto auto'}} direction='row'>
											<InfoIcon/>
											<Typography sx={{mt: 0.5,ml: 0.5, fontSize: '1rem', fontWeight: 500}} color='primary' variant='body1'>
												Файл заменён
											</Typography>
										</Stack>
									)}
								</Stack>
								<Divider/>
								<Stack direction='row' display='flex' spacing={1} sx={{mt: 1, mb: 1}}>
									<CustomButton style={{flex: 1}} withStartIcon variant='icon'
									              onClick={downloadResponseFile}>
										<DownloadIcon/>
										<Box>
											<span>Скачать</span>
										</Box>
									</CustomButton>
									{!chatInfo.finished && (user?.id !== item.author.id || (user?.type === UserType.DEBTOR && chatInfo.type === IssueType.VERIFY_SHIPMENTS))
										&& (item.file.contentType.startsWith("image/*")
											|| item.file.contentType.startsWith("application/vnd.*")
											|| item.file.contentType.startsWith("application/msword")
											|| item.file.contentType.startsWith("application/pdf")
											|| item.file.contentType.startsWith("application/octet-stream")) &&
										(
										<CustomButton
											style={{flex: 1}} withStartIcon variant='icon'
											onClick={() => handleSignClick('create', (user?.type === UserType.DEBTOR && chatInfo.type === IssueType.VERIFY_SHIPMENTS) ? `signedByAuthor` : `signedByParticipant`, makeFileSignedInIssueFx)}>
											<SignIcon/>
											<Box>
												<span>Подписать</span>
											</Box>
										</CustomButton>
									)}
									{!chatInfo.finished && item.author.id === user?.id && chatInfo.type !== IssueType.VERIFY_SHIPMENTS &&
                                        <CustomButton style={{flex: 1}} withStartIcon variant='icon'
                                                      onClick={handleReplaceFile}>
                                            <ReplaceIcon/>
                                            <Box>
                                                <span>Заменить</span>
                                            </Box>
                                        </CustomButton>}
								</Stack>
								<Stack>
									<Typography sx={{fontSize: '1rem'}}>{item.file.metadata.description}</Typography>
								</Stack>
							</Stack>
						)
					}
					{
						// prettier-ignore
						(item.type === 'signedFile' && 'file' in item)
						&& (
							<Stack>
								<Stack direction='row' sx={{my: 0.3}}>
									<Box
										sx={{
											width: 30,
											height: 30,
											backgroundColor: '#0088cc',
											borderRadius: '50%',
											display: 'grid',
											placeItems: 'center',
											mr: 0.5,
										}}
									>
										<Icon icon={fileIcon} width={18} height={18}/>
									</Box>
									<Stack sx={{maxWidth: '35%'}}>
										<Tooltip sx={{maxWidth: 'none'}} title={item.file.filename} arrow>
											<Typography variant='body2' sx={{
												overflow: 'hidden',
												textOverflow: 'ellipsis', fontSize: '1rem', lineHeight: 1.3
											}}>
												{item.file.filename}
											</Typography>
										</Tooltip>
										<Stack direction='row' sx={{alignItems: 'center'}}>
											<Typography
												variant='caption'
												sx={{fontSize: '0.75rem', lineHeight: 1.3}}
											>
												{formatBytes(item.file.length)}
											</Typography>
											<Box
												sx={{
													width: '6px',
													height: '1px',
													mx: '5px',
													mt: '-1px',
													backgroundColor: 'white',
												}}
											/>
										</Stack>
									</Stack>
									{item.updated && (
										<Stack sx={{m: 'auto 0px auto auto'}} direction='row'>
											<InfoIcon/>
											<Typography sx={{mt: 0.5, ml: 0.5, fontSize: '1rem', fontWeight: 500}}
											            color='primary' variant='body1'>
												Данные обновлены
											</Typography>
										</Stack>
									)}
								</Stack>
								<Divider/>
								<Stack direction='row' display='flex' spacing={1} sx={{mt: 1, mb: 1}}>
									<CustomButton style={{flex: 1}} withStartIcon variant='icon'
									              onClick={downloadResponseFile}>
										<DownloadIcon/>
										<Box>
											<span>Скачать</span>
										</Box>
									</CustomButton>
									{!chatInfo.finished && item.author.id === user?.id && !item.signedByAuthor && !item.signedByParticipant && !item.signedByNotifier &&
                                        <CustomButton style={{flex: 1}} withStartIcon variant='icon'
                                                      onClick={handleReplaceFile}>
                                            <ReplaceIcon/>
                                            <Box>
                                                <span>Заменить</span>
                                            </Box>
                                        </CustomButton>}
								</Stack>
								<Stack sx={{mt: 0.5}}>
									<Typography sx={{fontSize: '1rem'}}>{item.file.metadata.description}</Typography>
									<Stack sx={{
										display: 'flex',
										p: 0.5,
										mb: 1,
										mt: 0.5,
										borderRadius: '5px',
										borderStyle: 'solid',
										borderWidth: '1px',
									}} direction='row'>
										<Stack sx={{flex: 1}}>
											<Typography sx={{fontSize: '1rem'}} textAlign='center'>
												{item.author.name}
											</Typography>
											<Divider sx={{m: 0.5, borderColor: 'text.primary'}}/>
											{item.author.id === user?.id && !item.signedByAuthor
												? (<CustomButton
													disabled={item.author.id === user?.id && !item.signedByParticipant}
													onClick={() => handleSignClick(item.signedByParticipant && !chatInfo.author.generated ? 'add' : 'create', 'signedByAuthor', replaceSignedFileInIssueFx)}>
													{getButtonText('signedByAuthor')}
												</CustomButton>)
												: (<Typography
													sx={{
														fontSize: '1.25rem',
														m: 0,
														lineHeight: '26.25px',
														p: '6px',
														color: item.signedByAuthor ? 'success.main' : 'error.main'
													}}
													textAlign='center'>
													{getButtonText('signedByAuthor')}
												</Typography>)}
										</Stack>
										<Divider sx={{mr: 1, ml: 1, borderColor: 'text.primary'}} orientation='vertical'/>
										<Stack sx={{flex: 1}}>
											<Typography sx={{fontSize: '1rem'}} textAlign='center'>
												{item.author.id === chatInfo.author.id ? chatInfo.participant.name : chatInfo.author.name}
											</Typography>
											<Divider sx={{m: 0.5, borderColor: 'text.primary'}}/>
											{item.author.id !== user?.id && !item.signedByParticipant
												? (<CustomButton
													onClick={() => handleSignClick(item.signedByAuthor ? 'add' : 'create', 'signedByParticipant', replaceSignedFileInIssueFx)}>
													{getButtonText('signedByParticipant')}
												</CustomButton>)
												: (<Typography
													sx={{
														fontSize: '1.25rem',
														m: 0,
														lineHeight: '26.25px',
														p: '6px',
														color: item.signedByParticipant ? 'success.main' : 'error.main'
													}}
													textAlign='center'>
													{getButtonText('signedByParticipant')}
												</Typography>)}
										</Stack>
									</Stack>
								</Stack>
							</Stack>
						)
					}
				</Stack>
			</Stack>
		</Stack>
	);
};

export default MessageItem;
