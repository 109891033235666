/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {
	IAddResponseFileToIssueParams,
	ICompleteIssueParams,
	ICreateIssueParams,
	IDownloadCompanyCardSlotFileParams,
	IDownloadResponseFileParams,
	IFetchIssuesListResponse,
	IFetchResponsesListResponse,
	IFile,
	IIssue,
	IIssueCompanyCardResponse,
	IIssueDebtorsResponse,
	IIssueFileResponse,
	IIssueFileResponseFullStringParams,
	IIssueResponseFullStringParams,
	IIssueShipmentsResponse,
	IIssueWithExpandUsers,
	IReplaceFileParams,
	IReplaceSignedFileInIssueParams,
} from '../../types';
import {IDebtor} from "../suppliers";
import {PickedShipment} from "../../shared/variables";

export const fetchIssuesListFx = createEffect<{ participantId?: string; authorId?: string },
	IFetchIssuesListResponse>();
export const fetchUserFinancingIssuesListFx = createEffect<void,
	IIssueWithExpandUsers[]>();
export const fetchIssueFx = createEffect<{ issueId: string }, IIssueWithExpandUsers>();
export const createSupplierWithFactorFx = createEffect<ICreateIssueParams, IIssue>();
export const createDebtorWithFactorFx = createEffect<ICreateIssueParams, IIssue>();
export const fetchResponsesListFx = createEffect<{ issueId: string },
	IFetchResponsesListResponse>();
export const addResponseToIssueFx = createEffect<{ id: string; text: string },
	IIssueResponseFullStringParams>();
export const replaceFileFx = createEffect<IReplaceFileParams, IFile>();
export const addResponseFileToIssueFx = createEffect<IAddResponseFileToIssueParams,
	IIssueFileResponseFullStringParams>();
export const addResponseSignedFileToIssueFx = createEffect<IAddResponseFileToIssueParams,
	IIssueFileResponseFullStringParams>();
export const replaceSignedFileInIssueFx = createEffect<IReplaceSignedFileInIssueParams,
	IIssueFileResponseFullStringParams>();
export const makeFileSignedInIssueFx = createEffect<IReplaceSignedFileInIssueParams,
	IIssueFileResponseFullStringParams>();
export const downloadResponseFileFx = createEffect<IDownloadResponseFileParams, Blob>();
export const completeIssueFx = createEffect<ICompleteIssueParams, IIssue>();
export const setLimitFx = createEffect<{ issueId: string; limit: number }, IIssue>();
export const addResponseCompanyCardFx = createEffect<{ issueId: string; text: string },
	IIssueCompanyCardResponse>();
export const addResponseDebtorsFx = createEffect<{ issueId: string; text: string, debtors: IDebtor[] },
	IIssueDebtorsResponse>();
export const addResponseShipmentsFx = createEffect<{ issueId: string; text: string, debtorId: string, shipments: PickedShipment[] },
	IIssueShipmentsResponse>();
export const downloadCompanyCardSlotFileFx = createEffect<IDownloadCompanyCardSlotFileParams,
	Blob>();
export const createWithAdminFx = createEffect<Omit<ICreateIssueParams, 'factor' | 'type'>,
	IIssue>();
export const fetchIncomeFinalIssueResponsesFx = createEffect<void,
	IIssueFileResponse[]>();
export const fetchOutcomeFinalIssueResponsesFx = createEffect<void,
	IIssueFileResponse[]>();

export const $issuesEffectsFailData = merge(
	[
		fetchIssuesListFx,
		fetchIssueFx,
		createSupplierWithFactorFx,
		createDebtorWithFactorFx,
		fetchResponsesListFx,
		addResponseToIssueFx,
		addResponseFileToIssueFx,
		downloadResponseFileFx,
		completeIssueFx,
		setLimitFx,
		addResponseCompanyCardFx,
		downloadCompanyCardSlotFileFx,
		createWithAdminFx,
	].map((item: Effect<any, any>) => item.failData)
);
