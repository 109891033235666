/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {toggleModal} from './index';
import SignNotificationRequestModal from "../../components/pages/profile/user-info/SignNotificationRequestModal";

export const openSuppliesLoadingModal = () => {
	toggleModal({componentName: 'SuppliesLoadingModal', open: true});
};

export const closeSuppliesLoadingModal = () => {
	toggleModal({componentName: 'SuppliesLoadingModal', open: false});
};

export const openAddFileModal = (externalId?: string, replaceMode?: boolean, issueId?: string, responseId?: string, attachmentId?: string) => {
	toggleModal({
		componentName: 'AddFileModal',
		open: true,
		externalId,
		replaceMode,
		issueId,
		responseId,
		attachmentId
	});
};

export const closeAddFileModal = () => {
	toggleModal({componentName: 'AddFileModal', open: false});
};

export const openChangeCompanyCardModal = () => {
	toggleModal({componentName: 'ChangeCompanyCardModal', open: true});
};

export const closeChangeCompanyCardModal = () => {
	toggleModal({componentName: 'ChangeCompanyCardModal', open: false});
};

export const openChangePasswordModal = () => {
	toggleModal({componentName: 'ChangePasswordModal', open: true});
};

export const closeChangePasswordModal = () => {
	toggleModal({componentName: 'ChangePasswordModal', open: false});
};

export const openSignNotificationRequestModal = () => {
	toggleModal({componentName: 'SignNotificationRequestModal', open: true});
};

export const closeSignNotificationRequestModal = () => {
	toggleModal({componentName: 'SignNotificationRequestModal', open: false});
};
