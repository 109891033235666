/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {IFetchLimitListResponse, IIssueFileResponse, IIssueWithExpandUsers, ILongUser, UserType} from 'types';
import {useStore} from "effector-react";
import {DateTime} from "luxon";
import {useNavigate} from "react-router-dom";
import {Effect} from "effector";
import {fetchSupplierDebtorsFx, fetchSuppliersListFx, IDebtor} from "../../models/suppliers";
import {$user} from "../../models/auth";
import {
	fetchIncomeFinalIssueResponsesFx,
	fetchOutcomeFinalIssueResponsesFx,
	fetchUserFinancingIssuesListFx
} from "../../models/issues";
import TableContainer from "../../components/styled/tableContainer/TableContainer";
import {fetchDebtorsListFx} from "../../models/debtors";
import {fetchFactorsListFx} from "../../models/factors";
import {fetchLimitListFx} from "../../models/limit";
import {Row} from "../../components/styled/paginationTable/PaginationTable.types";

const DashboardPage = (): React.ReactElement => {
	const navigate = useNavigate();
	const {user} = useStore($user);
	const [factors, setFactors] = useState<ILongUser[]>([]);
	const [debtors, setDebtors] = useState<ILongUser[]>([]);
	const [suppliers, setSuppliers] = useState<ILongUser[]>([]);
	const [myDebtors, setMyDebtors] = useState<IDebtor[]>([]);
	const [issues, setIssues] = useState<IIssueWithExpandUsers[]>([]);
	const [incomeResponses, setIncomeResponses] = useState<IIssueFileResponse[]>([]);
	const [outcomeResponses, setOutcomeResponses] = useState<IIssueFileResponse[]>([]);
	const setLimitsForContractors = async (agents: any[], fx: Effect<void, IFetchLimitListResponse>) => {
		const limits = await fx();
		return agents.map(agent => {
			const limitBetween = limits.docs.find(lim => {
				const possibleUsersIds = [lim.factor.id];
				if ('supplier' in lim) possibleUsersIds.push(lim.supplier.id);
				if ('debtor' in lim) possibleUsersIds.push(lim.debtor.id);
				return possibleUsersIds.includes(agent.id);
			});
			return {
				...agent,
				limitHuman: limitBetween && limitBetween.limitHuman ? limitBetween.limitHuman : '-',
				usedLimitHuman:
					limitBetween && limitBetween.usedLimitHuman ? limitBetween.usedLimitHuman : '-',
				remainLimitHuman:
					limitBetween && limitBetween.remainLimitHuman ? limitBetween.remainLimitHuman : '-',
			};
		});
	};

	const fetchDebtors = async () => {
		const response = await fetchDebtorsListFx();
		const contractorsWithLimits = await setLimitsForContractors(response.docs, fetchLimitListFx);
		setDebtors(contractorsWithLimits);
	};

	const fetchFactors = async () => {
		const response = await fetchFactorsListFx();
		const contractorsWithLimits = await setLimitsForContractors(response.docs, fetchLimitListFx);
		setFactors(contractorsWithLimits);
	};

	const fetchSuppliers = async () => {
		const response = await fetchSuppliersListFx();
		const contractorsWithLimits = await setLimitsForContractors(response.docs, fetchLimitListFx);
		setSuppliers(contractorsWithLimits);
	};

	const fetchSupplierDebtors = async () => {
		const response = await fetchSupplierDebtorsFx();
		setMyDebtors(response);
	};

	const fetchFinancingIssues = async () => {
		const response = await fetchUserFinancingIssuesListFx();
		setIssues(response.sort((issue1, issue2) => DateTime.fromISO(issue2.createdAt).toMillis() - DateTime.fromISO(issue1.createdAt).toMillis()));
	};

	const fetchIncomeFinalIssueResponses = async () => {
		const response = await fetchIncomeFinalIssueResponsesFx();
		setIncomeResponses(response);
	};

	const fetchOutcomeFinalIssueResponses = async () => {
		const response = await fetchOutcomeFinalIssueResponsesFx();
		setOutcomeResponses(response);
	};

	const reload = () => {
		fetchFinancingIssues().then();
		fetchIncomeFinalIssueResponses().then();
		fetchOutcomeFinalIssueResponses().then();
		if (user?.type !== UserType.FACTOR)
			fetchFactors().then();
		if (user?.type === UserType.FACTOR) {
			fetchDebtors().then();
			fetchSuppliers().then();
		}
		if (user?.type === UserType.SUPPLIER)
			fetchSupplierDebtors().then();
	}

	useEffect(reload, [user]);

	const getStatus = (debtor: IDebtor) => {
		if (debtor.appliedFactors && debtor.appliedFactors.length > 0 && debtor.factor)
			return 'Запрошено обновление лимитов';
		if (debtor.appliedFactors?.length === 0 && !debtor.factor)
			return 'Не используется';
		if (debtor.factor && debtor.notificationSigned === null)
			return 'В процессе подписи Уведомления';
		if (debtor.factor && debtor.notificationSigned === false)
			return 'Отказ подписи Уведомления';
		if (debtor.factor && debtor.notificationSigned === true)
			return 'Готов к финансированию';
		return 'Запрошены лимиты';
	}

	const getIssueStatus = (issue: IIssueWithExpandUsers) => {
		if (issue.finished) {
			if (issue.acknowledgmentParticipant === false)
				return `Отклонено ${issue.participant.name}`;
			if (issue.acknowledgmentAuthor === false)
				return `Отклонено ${issue.author.name}`;
			if (issue.financingFinished)
				return 'Закончено';
			return 'Профинансировано, в процессе возврата';
		}
		return 'В процессе';
	}

	const getResponseFileStatus = (response: IIssueFileResponse) => {
		if (response.signedByAuthor && response.signedByParticipant)
			return 'Подписан';
		if (response.signedByParticipant)
			return user?.id === response.author.id ? 'Ожидается Ваша подпись' : 'Ожидается подпись контрагента';
		if (response.signedByAuthor)
			return user?.id === response.author.id ? 'Ожидается подпись контрагента' : 'Ожидается Ваша подпись';
		return 'Направлен на подписание';
	}

	return (
		<Box sx={{height: '100%'}}>
			<TableContainer
				reloadOnTabChange={reload}
				fetchSupplierDebtors={fetchSupplierDebtors}
				tableContentConfig={
					{
						'financing': {
							active: true,
							columns: [
								{key: 'author', title: 'Поставщик', active: user?.type !== UserType.SUPPLIER},
								{key: 'participant', title: 'Фактор', active: user?.type !== UserType.FACTOR},
								{key: 'debtor', title: 'Дебитор', active: user?.type !== UserType.DEBTOR},
								{key: 'finished', title: 'Статус'},
								{key: 'createdAt', title: 'Дата создания'},
								{key: 'openChat', title: '', isAction: true},
							],
							rows: issues.map(issue => ({
								...issue,
								chatId: issue.id,
								author: issue.author.name,
								participant: issue.participant.name,
								debtor: issue.debtor.name,
								// eslint-disable-next-line no-nested-ternary
								finished: getIssueStatus(issue),
								createdAt: DateTime.fromISO(issue.createdAt).toFormat('dd.MM.yyyy')
							})),
							rowOnClick: (row: Row) => {
								(row.acknowledgmentParticipant! === false || row.acknowledgmentAuthor! === false) ? (() => {
								})() : navigate(`/financing/${row.id}`)
							}
						},
						'factors': {
							active: user?.type !== UserType.FACTOR,
							columns: [
								{key: 'name', title: 'Имя компании'},
								{key: 'inn', title: 'ИНН'},
								{key: 'limitHuman', title: 'Установленный лимит'},
								{key: 'usedLimitHuman', title: 'Использованный лимит'},
								{key: 'remainLimitHuman', title: 'Оставшийся лимит'},
								{key: 'chatList', title: '', isAction: true},
							],
							rows: factors,
							rowOnClick: (row: Row) => navigate(`/profile/${row.id}`)
						},
						'debtors': {
							active: user?.type === UserType.FACTOR,
							columns: [
								{key: 'name', title: 'Имя компании'},
								{key: 'inn', title: 'ИНН'},
								{key: 'limitHuman', title: 'Установленный лимит'},
								{key: 'usedLimitHuman', title: 'Использованный лимит'},
								{key: 'remainLimitHuman', title: 'Оставшийся лимит'},
								{key: 'chatList', title: '', isAction: true},
							],
							rows: debtors,
							rowOnClick: (row: Row) => navigate(`/profile/${row.id}`)
						},
						'suppliers': {
							active: user?.type === UserType.FACTOR,
							columns: [
								{key: 'name', title: 'Имя компании'},
								{key: 'inn', title: 'ИНН'},
								{key: 'limitHuman', title: 'Установленный лимит'},
								{key: 'usedLimitHuman', title: 'Использованный лимит'},
								{key: 'remainLimitHuman', title: 'Оставшийся лимит'},
								{key: 'chatList', title: '', isAction: true},
							],
							rows: suppliers,
							rowOnClick: (row: Row) => navigate(`/profile/${row.id}`)
						},
						'myDebtors': {
							active: user?.type === UserType.SUPPLIER,
							columns: [
								{key: 'name', title: 'Наименование'},
								{key: 'inn', title: 'ИНН'},
								{key: 'verifiedAmountHuman', title: 'Установленный лимит'},
								{key: 'deferralPeriod', title: 'Срок отсрочки платежа'},
								{key: 'gracePeriod', title: 'Срок льготного периода'},
								{key: 'supplyAmountHuman', title: 'Запрашиваемый лимит'},
								{key: 'status', title: 'Статус'},
								{key: 'appliedFactors', title: 'На рассмотрении у факторов'},
								{key: 'financingRequest', title: '', isAction: true}
							],
							rows: myDebtors.map(debtor => ({
								...debtor,
								appliedFactors: debtor.appliedFactors?.join(', '),
								status: getStatus(debtor)
							})),
						},
						'incomeFiles': {
							active: true,
							columns: [
								{key: 'author', title: 'От кого'},
								{key: 'name', title: 'Наименование'},
								{key: 'status', title: 'Статус'},
								{key: 'date', title: 'Дата отправки'},
								{key: 'openChat', title: '', isAction: true}
							],
							rows: incomeResponses.map(response => ({
								id: response.id,
								chatId: response.issue.id,
								name: response.file.filename,
								status: getResponseFileStatus(response),
								author: response.issue.author.id === response.author.id ? response.issue.author.name : response.issue.participant.name,
								date: DateTime.fromISO(response.createdAt).toFormat('dd.MM.yyyy')
							})),
						},
						'outcomeFiles': {
							active: true,
							columns: [
								{key: 'receiver', title: 'Кому'},
								{key: 'name', title: 'Наименование'},
								{key: 'status', title: 'Статус'},
								{key: 'date', title: 'Дата отправки'},
								{key: 'openChat', title: '', isAction: true}
							],
							rows: outcomeResponses.map(response => ({
								id: response.id,
								chatId: response.issue.id,
								name: response.file.filename,
								status: getResponseFileStatus(response),
								receiver: response.issue.author.id === response.author.id ? response.issue.participant.name : response.issue.author.name,
								date: DateTime.fromISO(response.createdAt).toFormat('dd.MM.yyyy')
							})),
						},
					}}/>
		</Box>
	);
};

export default DashboardPage;
