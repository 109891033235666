/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef} from 'react';
import {IconButton, Stack, Tooltip, Typography} from '@mui/material';
import {useStore} from 'effector-react';
import editOutlineIcon from '@iconify/icons-eva/edit-outline';
import {IFormikRef, ISmallFile} from 'types';
import Modal, {IModalRef} from 'components/helpers/modals/Modal';
import {GetIcon} from 'components/helpers/other/GetIcon';
import EditFileForm, {IEditFileFormValues} from './EditFileForm';
import {patchFileFx} from '../../../../models/profile';
import CustomButton from "../../../styled/customButton/CustomButton";

interface IEditFileModalProps {
	row: ISmallFile;
	fetchProfileFiles: () => void;
}

const EditFileModal = ({row, fetchProfileFiles}: IEditFileModalProps): React.ReactElement => {
	const patchFilePending = useStore(patchFileFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef = useRef<IFormikRef>(null);

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const submitFunc = async (data: IEditFileFormValues) => {
		await patchFileFx({fileId: row.id, data});
		if (formikRef.current) formikRef.current.resetForm();
		handleModalClose();
		await fetchProfileFiles();
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
	};

	const modalContent = {
		toggleChildren: (
			<Tooltip title='Изменить данные файла' placement='top' arrow>
				<IconButton color='inherit' onClick={handleModalOpen}>
					{GetIcon(editOutlineIcon)}
				</IconButton>
			</Tooltip>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Изменить данные файла
					</Typography>
				</Stack>
				<EditFileForm ref={formikRef} submitFunc={submitFunc} file={row}/>
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' spacing={1} sx={{mt: 1, width: '100%'}}>
				<CustomButton
					onClick={handleModalClose}
				>
					Отменить
				</CustomButton>
				<CustomButton
					onClick={handleSubmit}
				>
					Изменить
				</CustomButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
		/>
	);
};

export default EditFileModal;
