/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Dialog, DialogActions, DialogContent, Typography} from '@mui/material';
import {useStore} from 'effector-react';
import CustomButton from 'components/styled/customButton/CustomButton';
import {closeAddFileModal} from 'models/modals/functions';
import {Box} from "@mui/system";
import {getFileExt} from 'shared/functions';
//@ts-ignore
import moduleStyles from "./AddFileModal.module.scss";
import {$modals} from "../../../models/modals";
import CustomDialogTitle from '../other/CustomDialogTitle';
// @ts-ignore
import {ReactComponent as FurtherIcon} from '../../../assets/images/buttons-icons/further.svg';
// @ts-ignore
import {ReactComponent as SelectIcon} from '../../../assets/images/buttons-icons/select.svg';
import FileIcon from '../other/FileIcon';
import {addFileFx, addFileToSlotCompanyCardFx} from "../../../models/profile";
import {replaceFileFx} from "../../../models/issues";

interface IAddFileModalProps {
	fetchUpdatedFiles: () => void;
}

const styles = moduleStyles as any;

const AddFileModal = ({
	                      fetchUpdatedFiles,
                      }: IAddFileModalProps): React.ReactElement => {
	const {AddFileModal: modalState} = useStore($modals);
	const [file, setFile] = useState<File | null>(null);

	const closeModal = () => {
		setTimeout(() => {
			setFile(null);
		}, 300);
		closeAddFileModal();
	};

	const sendForm = async () => {
		const formData = new FormData();
		if (file) formData.append('file', file);
		if (modalState.replaceMode && modalState.issueId && modalState.responseId && modalState.attachmentId)
			await replaceFileFx({
				formData,
				issueId: modalState.issueId,
				responseId: modalState.responseId,
				attachmentId: modalState.attachmentId
			});
		else if (modalState.externalId === undefined)
			await addFileFx({formData});
		else
			await addFileToSlotCompanyCardFx({slotId: modalState.externalId, data: formData});
		closeModal();
		await fetchUpdatedFiles();
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '100%', maxWidth: '700px'}}}
			onClose={closeModal}
			aria-labelledby='customized-dialog-title'
			open={modalState.open}
		>
			<CustomDialogTitle onClose={closeModal}>Добавить файл</CustomDialogTitle>
			<DialogContent sx={{p: 0}}>
				<Box className={styles.content}>
					<Box sx={{overflow: 'hidden'}} className={styles.selectFileContainer}>
						<Box sx={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							whiteSpace: 'nowrap'
						}} className={styles.selectFileInfo}>
							{file && <FileIcon ext={getFileExt(file)}/>}
							<Typography sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden'
							}}>{file ? file.name : 'Файл не выбран'}</Typography>
						</Box>
						<input
							id='import-button-file-add'
							type='file'
							onChange={event => {
								if (event.currentTarget.files) {
									setFile(event.currentTarget.files[0]);
								}
							}}
						/>
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor='import-button-file-add'>
							<span className={styles.selectFileBtn}>
								<SelectIcon/>
								<div>Выбрать</div>
							</span>
						</label>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions className={styles.actions}>
				<CustomButton withStartIcon onClick={sendForm}>
					<FurtherIcon/>
					<Box>
						<span>Добавить</span>
					</Box>
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
};

export default AddFileModal;
