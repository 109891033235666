/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import * as Yup from 'yup';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
import _ from 'lodash';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {IconButton, InputAdornment, Stack, TextField} from '@mui/material';
import {loginFx} from 'models/auth';
import CustomButton from 'components/styled/customButton/CustomButton';

const LoginForm = (): React.ReactElement => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const LoginSchema = Yup.object().shape({
		email: Yup.string().email('Почта введена некорректно').required('Поле является обязательным'),
		password: Yup.string()
			.required('Поле является обязательным')
			.min(8, 'Длина пароля должна быть не менее 8 символов'),
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			// nda: { name: 'Нет', value: 0 },
		},
		validationSchema: LoginSchema,
		onSubmit: async values => {
			const response = await loginFx(_.omit(values, 'nda'));
			navigate(response.user.ndaSigned ? '/dashboard' : '/signature/nda', {replace: true});
		},
	});

	const {errors, touched, handleSubmit, getFieldProps} = formik;

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
				<Stack spacing={2.5}>
					<TextField
						fullWidth
						type='email'
						label='Почта'
						{...getFieldProps('email')}
						error={Boolean(touched.email && errors.email)}
						helperText={touched.email && errors.email}
					/>

					<TextField
						fullWidth
						type={showPassword ? 'text' : 'password'}
						label='Пароль'
						{...getFieldProps('password')}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
										<Icon icon={showPassword ? eyeFill : eyeOffFill}/>
									</IconButton>
								</InputAdornment>
							),
						}}
						error={Boolean(touched.password && errors.password)}
						helperText={touched.password && errors.password}
					/>

					<CustomButton type='submit'>
						Войти
					</CustomButton>
				</Stack>
			</Form>
		</FormikProvider>
	);
};

export default LoginForm;
