/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import {useStore} from 'effector-react';
import {createEvent, createStore} from 'effector';
import {IFormikRef, IIssueWithExpandUsers, ILongUser, UserType} from '../../../../types';
import {addResponseFileToIssueFx, completeIssueFx} from '../../../../models/issues';
import {$user} from '../../../../models/auth';
import GrowTransition from '../../../helpers/transitions/GrowTransition';
import {IssueType} from "../../../../types/enum/issueType";
import Debtors from "../../../pages/profile/debtors/Debtors";
import {IDebtor} from "../../../../models/suppliers";
import SetDebtorForm, {ISetDebtorFormValues} from "./forms/SetDebtorForm";
import SetLimitForm, {ISetLimitFormValues} from "./forms/SetLimitForm";
import Shipments from "../../../pages/profile/shipments/Shipments";
import {parseJsonToFileFx} from "../../../../models/docPy";
import {blobToFile, createFormData} from "../../../../shared/functions";
import CustomButton from "../../../styled/customButton/CustomButton";
import {refreshChat} from "../Chat";

interface ICompleteIssueModalStore {
	open: boolean;
	data: any;
	asGeneratedUser: boolean;
}

interface ICompleteIssueModalProps {
	chatInfo: IIssueWithExpandUsers;
	fetchIssue: (timeout: number) => void;
	fetchResponsesList: () => void;
}

export const setOpenCompleteIssueModal = createEvent<{ open: boolean, data: any, asGeneratedUser: boolean }>();
export const refreshSelected = createEvent<void>();
export const setIssueModalData = createEvent<any>();
export const $completeIssueModal = createStore<ICompleteIssueModalStore>({
	open: false,
	data: [],
	asGeneratedUser: false
})
	.on(setOpenCompleteIssueModal, (state, payload) => ({
		...state, ...payload,
		data: payload.data === null ? [] : payload.data
	}))
	.on(setIssueModalData, (state, data) => ({...state, data}));
export const handleCompleteIssueModalOpen = (data: any = [], asGeneratedUser = false) => {
	setOpenCompleteIssueModal({open: true, data, asGeneratedUser});
};
export const handleCompleteIssueModalClose = (data: any = []) => {
	setOpenCompleteIssueModal({open: false, data, asGeneratedUser: false});
};
export const handleSetIssueModalData = (data: any = []) => {
	setIssueModalData(data);
};

const CompleteIssueModal = ({
	                            chatInfo,
	                            fetchIssue,
	                            fetchResponsesList
                            }: ICompleteIssueModalProps): React.ReactElement => {
	const store = useStore($completeIssueModal);
	const {user} = useStore($user);
	const [finished, setFinished] = useState(true);
	const [activeStep, setActiveStep] = useState(-1);
	const [nextStepDisabled, setNextStepDisabled] = useState(false);
	const ref = useRef<IFormikRef>(null);

	useEffect(() => {
		setFinished(true);
		setActiveStep(-1);
		localStorage.setItem('selected', JSON.stringify([]));
		setNextStepDisabled(false);
	}, [store.open]);

	const defaultCreateAdditionalStep = () => ({
		label: '',
		// eslint-disable-next-line react/no-unstable-nested-components
		component: () => <span/>,
		maxWidth: '25vw',
		onNext: () => {
		},
		checkDisabled: () => {}
	});

	const defaultSteps = {
		startStep: null,
		createAdditionalStep: null,
		endStep: null
	};

	const defaultTypeSteps = {
		[UserType.ADMIN]: defaultSteps,
		[UserType.DEBTOR]: defaultSteps,
		[UserType.FACTOR]: defaultSteps,
		[UserType.SUPPLIER]: defaultSteps,
	}

	const defaultTypes = {
		[UserType.ADMIN]: defaultTypeSteps,
		[UserType.DEBTOR]: defaultTypeSteps,
		[UserType.FACTOR]: defaultTypeSteps,
		[UserType.SUPPLIER]: defaultTypeSteps,
	}

	const stepsMap = {
		[IssueType.CONVERSATION]: defaultTypes,
		[IssueType.SIGN_NDA]: defaultTypes,
		[IssueType.REFUSE_NDA]: defaultTypes,
		[IssueType.SIGN_NOTIFICATION]: defaultTypes,
		[IssueType.VERIFY_SHIPMENTS]: {
			[UserType.ADMIN]: defaultTypeSteps,
			[UserType.DEBTOR]: {
				[UserType.ADMIN]: defaultSteps,
				[UserType.FACTOR]: defaultSteps,
				[UserType.DEBTOR]: {
					startStep: {
						label: 'Выберите поставки, которые согласны профинансировать',
						component: () => <Shipments selectable readonly shipments={store.data}/>,
						maxWidth: '75vw',
						onNext: () => {
							parseJsonToFileFx({data: store.data.filter((obj: { id: any; }) => JSON.parse(localStorage.getItem('selected') as string).includes(obj.id))}).then(r => {
								addResponseFileToIssueFx({
									issueId: chatInfo.id,
									data: createFormData(blobToFile(r, 'shipments.xlsx'), 'Сгенерированный реестр для подписи')
								}).then(() => handleCompleteIssue(true, null))
							});
						},
						checkDisabled: () => setNextStepDisabled(true)
					},
					createAdditionalStep: null,
					endStep: null
				},
				[UserType.SUPPLIER]: defaultSteps,
			},
			[UserType.FACTOR]: defaultTypeSteps,
			[UserType.SUPPLIER]: defaultTypeSteps,
		},
		[IssueType.REQUEST_FINANCING]: {
			[UserType.FACTOR]: {
				[UserType.SUPPLIER]: {
					startStep: chatInfo.additionalStepsDone ? null : {
						label: 'Выберите поставки, которые согласны профинансировать',
						component: () => <Shipments selectable readonly shipments={store.data}/>,
						maxWidth: '75vw',
						onNext: () => {
						},
						checkDisabled: () => setNextStepDisabled(true)
					},
					createAdditionalStep: null,
					endStep: chatInfo.additionalStepsDone ? null : {
						label: 'Выберите ставку финансирования поставок',
						// eslint-disable-next-line react/no-unstable-nested-components
						component: () => <SetLimitForm ref={ref}
						                               label='Процент финансирования'
						                               submitFunc={(values: ISetLimitFormValues) => {
							                               handleCompleteIssue(true, values.limitRub / 100);
						                               }}/>,
						maxWidth: '25vw',
						onNext: () => ref?.current?.handleSubmit(),
						checkDisabled: () => {}
					}
				},
				[UserType.DEBTOR]: defaultSteps,
				[UserType.ADMIN]: defaultSteps,
				[UserType.FACTOR]: defaultSteps,
			},
			[UserType.ADMIN]: defaultTypeSteps,
			[UserType.DEBTOR]: defaultTypeSteps,
			[UserType.SUPPLIER]: defaultTypeSteps,
		},
		[IssueType.SET_LIMIT]:
			{
				[UserType.FACTOR]: {
					[UserType.SUPPLIER]: {
						startStep: {
							label: 'Выберите дебиторов, на которых выдадите лимиты',
							// eslint-disable-next-line react/no-unstable-nested-components
							component: () => <Debtors selectable readonly debtors={store.data}/>,
							maxWidth: '75vw',
							onNext: () => {
							},
							checkDisabled: () => setNextStepDisabled(true)
						},
						createAdditionalStep: (idx: number, obj: IDebtor) => ({
							label: `Установите условия работы по дебитору ${obj.name}`,
							// eslint-disable-next-line react/no-unstable-nested-components
							component: () => <SetDebtorForm ref={ref} debtor={obj}
							                                submitFunc={(values: ISetDebtorFormValues) => {
								                                store.data[idx] = {
									                                ...store.data[idx], ...values,
									                                verifiedAmount: values.verifiedAmount * 100
								                                };
								                                handleSetIssueModalData(store.data);
							                                }}/>,
							maxWidth: '75vw',
							onNext: () => ref?.current?.handleSubmit(),
							checkDisabled: () => {}
						}),
						endStep: {
							label: 'Установите лимит на контрагента',
							// eslint-disable-next-line react/no-unstable-nested-components
							component: () => <SetLimitForm ref={ref}
							                               submitFunc={(values: ISetLimitFormValues) => handleCompleteIssue(true, values.limitRub * 100)}/>,
							maxWidth: '25vw',
							onNext: () => ref?.current?.handleSubmit(),
							checkDisabled: () => {}
						}
					},
					[UserType.DEBTOR]: {
						startStep: {
							label: 'Установите лимит на контрагента',
							// eslint-disable-next-line react/no-unstable-nested-components
							component: () => <SetLimitForm ref={ref}
							                               submitFunc={(values: ISetLimitFormValues) => handleCompleteIssue(true, values.limitRub * 100)}/>,
							maxWidth: '25vw',
							onNext: () => ref?.current?.handleSubmit(),
							checkDisabled: () => {}
						},
						createAdditionalStep: defaultCreateAdditionalStep,
						endStep: null
					},
					[UserType.ADMIN]: defaultSteps,
					[UserType.FACTOR]: defaultSteps,
				},
				[UserType.ADMIN]: defaultTypeSteps,
				[UserType.DEBTOR]: defaultTypeSteps,
				[UserType.SUPPLIER]: defaultTypeSteps,
			}
	};

	const getSteps = useMemo(() => {
		if (chatInfo.author.generated && store.asGeneratedUser)
			return stepsMap[chatInfo.type][chatInfo.author.type][(user as ILongUser)?.type];
		return stepsMap[chatInfo.type][(user as ILongUser)?.type][chatInfo.author.type]
	}, [stepsMap, chatInfo, user, store.asGeneratedUser]);

	const [steps, setSteps] = useState(dropNullAndUndefined([getSteps?.startStep, getSteps?.endStep]));

	refreshSelected.watch(() => {
		setNextStepDisabled(JSON.parse(localStorage.getItem('selected') as string)?.length === 0);
		if (store.data.length > 0 && getSteps?.createAdditionalStep !== null)
			setSteps(dropNullAndUndefined([getSteps?.startStep, ...JSON.parse(localStorage.getItem('selected') as string).map((objId: string) => {
				const idx = store.data.findIndex((dataObj: any) => dataObj.id === objId);
				const obj: any = store.data[idx];
				return getSteps?.createAdditionalStep(idx, obj);
			}), getSteps?.endStep]));
	});

	useEffect(() => {
		setSteps(dropNullAndUndefined([getSteps?.startStep, getSteps?.endStep]))
	}, [store.data]);

	const handleCompleteIssue = (res: boolean, formRes?: any) => {
		if (user) {
			const params = {
				issueId: chatInfo.id,
				result: res,
				data: {data: null, result: null, asGeneratedUser: store.asGeneratedUser}
			};
			if ((user.id === chatInfo.participant.id && (chatInfo.type !== IssueType.VERIFY_SHIPMENTS) && !(user.type === UserType.FACTOR && chatInfo.type === IssueType.REQUEST_FINANCING && !!chatInfo.additionalStepsDone)) || (chatInfo.type === IssueType.VERIFY_SHIPMENTS && user.type === UserType.DEBTOR)) {
				params.data = {
					data: store.data.filter((obj: { id: any; }) => JSON.parse(localStorage.getItem('selected') as string).includes(obj.id)),
					result: formRes,
					asGeneratedUser: store.asGeneratedUser
				}
			}
			completeIssueFx(params).then((updatedIssue) => {
				fetchIssue(0);
				setTimeout(() => {
					fetchResponsesList();
					refreshChat();
				}, 500);
				handleCompleteIssueModalClose();
			});
		}
	}

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		steps[activeStep].onNext();
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	function dropNullAndUndefined<T>(arr: (T | null | undefined)[]): T[] {
		return arr.flatMap((f) => (f === null || f === undefined ? [] : f));
	}

	const handleSuccess = () => {
		if (!chatInfo.finished && getSteps.startStep !== null) {
			setFinished(false);
			setActiveStep(0);
			getSteps.startStep.checkDisabled();
		} else
			handleCompleteIssue(true);
	}

	return (
		<Dialog
			PaperProps={{
				sx: {
					maxWidth: activeStep > -1 ? steps[activeStep]?.maxWidth : '25vw',
					transition: 'max-width 0.5s'
				}
			}}
			onBackdropClick={handleCompleteIssueModalClose}
			TransitionComponent={GrowTransition}
			open={store.open}
		>
			<DialogContent>
				{!finished && <Stepper activeStep={activeStep} orientation="vertical">
					{steps.map((step, index) => (
						<Step key={step.label}>
							<StepLabel>
								{step.label}
							</StepLabel>
							<StepContent TransitionProps={{mountOnEnter: true, unmountOnExit: false}}>
								{step.component()}
								<Box sx={{mb: 2}}>
									<div>
										<CustomButton
											disabled={nextStepDisabled}
											onClick={handleNext}
										>
											{index === steps.length - 1 ? 'Закончить' : 'Следующий шаг'}
										</CustomButton>
										{index !== 0 && <CustomButton
                                            onClick={handleBack}
                                        >
                                            Назад
                                        </CustomButton>}
									</div>
								</Box>
							</StepContent>
						</Step>
					))}
                </Stepper>}
				{finished && <Stack>
                    <Stack>
                        <Typography variant='h4' align='center'>
                            С каким результатом выполнить действие?
                        </Typography>
                    </Stack>
                </Stack>}
			</DialogContent>
			{finished && <DialogActions sx={{pt: 0}}>
                <Stack direction='row' spacing={1} sx={{width: '100%', justifyContent: 'space-between'}}>
                    <CustomButton
                        onClick={() => handleCompleteIssue(false)}
                    >
                        Отклонить
                    </CustomButton>
                    <CustomButton
                        onClick={handleSuccess}
                    >
                        Подтвердить
                    </CustomButton>
                </Stack>
            </DialogActions>}
		</Dialog>
	);
};

export default CompleteIssueModal;
