/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent, Divider, IconButton, Stack, TextField, Tooltip} from '@mui/material';
import {useStore} from 'effector-react';
import closeIcon from '@iconify/icons-eva/close-circle-outline';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {createEvent, createStore} from 'effector';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from "yup";
import GrowTransition from "../../helpers/transitions/GrowTransition";
import {GetIcon} from "../../helpers/other/GetIcon";
import CompanyCardModal from "../../features/company-card/CompanyCardModal";
import {
	addResponseCompanyCardFx,
	addResponseDebtorsFx,
	createDebtorWithFactorFx,
	createSupplierWithFactorFx
} from "../../../models/issues";
import {IIssue, UserType} from "../../../types";
import {IssueType} from "../../../types/enum/issueType";
import {fetchSupplierDebtorsFx, IDebtor} from "../../../models/suppliers";
import FormikAutocomplete from "../../helpers/forms/FormikAutocomplete";
import {$user} from "../../../models/auth";

export const setOpenRequestLimitsModal = createEvent<{ open: boolean, hasLimit: boolean }>();
export const $requestLimitsModal = createStore({
	open: false,
	hasLimit: false
}).on(setOpenRequestLimitsModal, (state, payload) => ({...state, ...payload}));

export const handleRequestLimitsModalOpen = (hasLimit: boolean) => setOpenRequestLimitsModal({open: true, hasLimit});
export const handleRequestLimitsModalClose = () =>
	setOpenRequestLimitsModal({open: false, hasLimit: false});

interface IRequestLimitsModalProps {
	factorId: string;
	reloadData: Function;
}

const RequestLimitsModal = ({
	                            factorId,
	                            reloadData
                            }: IRequestLimitsModalProps): React.ReactElement => {
	const {open, hasLimit} = useStore($requestLimitsModal);
	const {user} = useStore($user);
	const [debtors, setDebtors] = useState<IDebtor[]>([]);

	const fetchSupplierDebtors = async () => {
		const response = await fetchSupplierDebtorsFx();
		setDebtors(response);
	}

	useEffect(() => {
		const fetchPromises = [];
		if (user?.type === UserType.SUPPLIER)
			fetchPromises.push(fetchSupplierDebtors());
		Promise.all(fetchPromises).then();
	}, []);

	const RequestLimitsModalSchema = Yup.object().shape({
		text: Yup.string().required('Поле является обязательным'),
		debtors: user?.type === UserType.SUPPLIER ? Yup.array().min(1, 'Необходимо указать дебиторов') : Yup.array(),
	});

	const formik = useFormik<{ text: string, debtors: IDebtor[] }>({
		initialValues: {
			text: `Добрый день. Направляю информацию для ${hasLimit ? 'обновления' : 'расчета'} лимитов.`,
			debtors: [],
		},
		validationSchema: RequestLimitsModalSchema,
		onSubmit: async ({text, debtors}) => {
			let newIssue: IIssue;
			if (user?.type === UserType.SUPPLIER)
				newIssue = await createSupplierWithFactorFx({
					title: `${hasLimit ? 'Обновление' : 'Расчет'} лимитов`,
					description: `Запрос ${hasLimit ? 'обновления' : 'расчета'} лимитов`,
					factor: factorId,
					type: IssueType.SET_LIMIT,
				});
			else
				newIssue = await createDebtorWithFactorFx({
					title: `${hasLimit ? 'Обновление' : 'Расчет'} лимитов`,
					description: `Запрос ${hasLimit ? 'обновления' : 'расчета'} лимитов`,
					factor: factorId,
					type: IssueType.SET_LIMIT,
				});
			const issuePromises: any[] = [addResponseCompanyCardFx({
				issueId: newIssue.id,
				text
			})];
			if (user?.type === UserType.SUPPLIER)
				issuePromises.push(addResponseDebtorsFx({issueId: newIssue.id, text: '', debtors}));
			await Promise.all(issuePromises);
			reloadData();
			handleModalClose();
		},
	});

	const {handleSubmit, getFieldProps, resetForm, values} = formik;

	const handleModalClose = () => {
		handleRequestLimitsModalClose();
		resetForm();
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '400px'}}}
			onBackdropClick={handleModalClose}
			TransitionComponent={GrowTransition}
			open={open}
		>
			<DialogContent sx={{p: 0, position: 'relative'}}>
				<FormikProvider value={formik}>
					<Form
						autoComplete='off'
						noValidate
						onSubmit={handleSubmit}
						style={{height: '100%'}}
					>
						<Stack sx={{position: 'absolute', top: 8, right: 8}}>
							<Tooltip title='Закрыть' placement='top' arrow>
								<IconButton color='inherit' component='span' onClick={handleModalClose}>
									{GetIcon(closeIcon)}
								</IconButton>
							</Tooltip>
						</Stack>
						<Stack
							sx={{
								pt: 2,
								pb: 1,
								justifyContent: 'space-between',
							}}
						>
							<Stack sx={{pl: 2.5, pr: 1}}>
								<CompanyCardModal/>
								{user?.type === UserType.SUPPLIER &&
                                    <FormikAutocomplete noOptionsText='Нет доступных дебиторов' multiple
                                                        name='debtors' options={debtors}/>}
							</Stack>
							<Divider sx={{mt: 2, mb: 0.5}}/>
							<Stack
								direction='row'
								sx={{
									height: '100%',
									alignItems: 'center',
									pl: 2.5,
									pr: 1,
								}}
							>
									<TextField
										multiline
										minRows={2}
										maxRows={3}
										{...getFieldProps('text')}
										style={{
											width: '100%',
											height: '100%',
											marginRight: '5px',
											backgroundColor: 'transparent',
											outline: 'none',
											border: 'none',
											color: 'inherit',
											fontSize: '16px',
										}}
									/>
								<Stack>
									<Tooltip title='Отправить' placement='top' arrow>
										<IconButton color='inherit' type='submit'>
											{GetIcon(sendIcon)}
										</IconButton>
									</Tooltip>
								</Stack>
							</Stack>
						</Stack>
					</Form>
				</FormikProvider>
			</DialogContent>
		</Dialog>
	);
};

export default RequestLimitsModal;
