/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {$notifications, updateNotifications} from "./index";

$notifications
	.on(updateNotifications, (_, notifications) => notifications);