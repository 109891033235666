/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

export enum IssueType {
	CONVERSATION = 'conversation',
	REFUSE_NDA = 'refuseNDA',
	SIGN_NDA = 'signNDA',
	SIGN_NOTIFICATION = 'signNotification',
	SET_LIMIT = 'setLimit',
	REQUEST_FINANCING = 'requestFinancing',
	VERIFY_SHIPMENTS = 'verifyShipments'
}