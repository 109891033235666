/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {apiRequest, IData} from './index';
import {IFetchLimitListResponse} from '../types';

export const fetchLimitList = async () => {
	const response = await apiRequest<IData<IFetchLimitListResponse>>({
		url: '/api/limit/list',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};
