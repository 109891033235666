/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, createEvent, createStore, Effect, merge} from 'effector';
import {get} from 'local-storage';
import {USER_DATA, USER_TOKEN} from 'shared/variables';
import {IAuthResponse, ILoginRequestParams, ILongUser} from 'types';

interface IUserState {
	token: string | null;
	user: ILongUser | null;
}

export const defaultState = {
	token: get<string>(USER_TOKEN) || null,
	user: get<ILongUser>(USER_DATA) || null,
};

export const logout = createEvent();

export const loginFx = createEffect<ILoginRequestParams, IAuthResponse>();
export const fetchCurrentUserFx = createEffect<void, ILongUser>();

export const $authEffectsFailData = merge(
	[loginFx, fetchCurrentUserFx].map((item: Effect<any, any>) => item.failData)
);

export const $user = createStore<IUserState>(defaultState);
