/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import { DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import { Children } from 'shared/types';
//@ts-ignore
import moduleStyles from './CustomDialogTitle.module.scss';

const styles = moduleStyles as any;

interface CustomDialogTitleProps {
	children: Children | string;
	onClose: () => void;
}

const CustomDialogTitle = ({ children, onClose }: CustomDialogTitleProps) => (
	<DialogTitle className={styles.title}>
		{children}
		{onClose && (
			<IconButton
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: theme => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
		)}
	</DialogTitle>
);

export default CustomDialogTitle;
