/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {forwardRef} from 'react';
import {Button, Stack, Typography} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';
import {Box} from "@mui/system";
import FileIcon from "../../../helpers/other/FileIcon";
import {downloadFile, getFileExt} from "../../../../shared/functions";
//@ts-ignore
import {ReactComponent as SelectIcon} from "../../../../assets/images/buttons-icons/select.svg";
//@ts-ignore
import moduleStyles from "../../../helpers/files/AddFileModal.module.scss";

const styles = moduleStyles as any;

export interface ILoadAndParseShipmentsFormValues {
	file: File;
}

interface ILoadAndParseShipmentsFormProps {
	submitFunc: (values: ILoadAndParseShipmentsFormValues) => void;
	val: number;
}

const LoadAndParseShipmentsForm = forwardRef(
	({submitFunc, val}: ILoadAndParseShipmentsFormProps, ref) => {
		const SetDebtorFormSchema = Yup.object().shape({
			file: Yup.mixed().required('Поле является обязательным'),
		});

		const formik = useFormik<ILoadAndParseShipmentsFormValues>({
			initialValues: {
				file: null as unknown as File,
			},
			validationSchema: SetDebtorFormSchema,
			validateOnChange: false,
			validateOnBlur: false,
			onSubmit: (values: ILoadAndParseShipmentsFormValues) => submitFunc(values),
		});

		const {setFieldValue, handleSubmit, values} = formik;

		React.useImperativeHandle(ref, () => formik);

		return (
			<FormikProvider value={formik}>
				<Form style={{width: '100%'}} autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={2}>
						<Box className={styles.content}>
							<Box sx={{padding: '0 !important'}} className={styles.selectFileContainer}>
								<Box sx={{overflow: 'hidden'}} className={styles.selectFileInfo}>
									{values.file && <FileIcon ext={getFileExt(values.file)}/>}
									<Typography sx={{
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap'
									}}>{values.file ? values.file.name : 'Файл не выбран'}</Typography>
								</Box>
								<input
									id={`import-button-file-add-financing${val}`}
									type='file'
									onChange={event => {
										if (event.currentTarget.files)
											setFieldValue('file', event.currentTarget.files[0]);
									}}
								/>
								{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
								<label htmlFor={`import-button-file-add-financing${val}`}>
							<span className={styles.selectFileBtn}>
								<SelectIcon/>
								<div>Выбрать</div>
							</span>
								</label>
							</Box>
						</Box>
					</Stack>
				</Form>
				<Button sx={{mt: '16px'}} onClick={() => {
					fetch(`template.xlsx`)
					.then((response) => response.blob())
					.then((blob) => {
						downloadFile(blob, 'Шаблон_импорта_поставок_из_таблицы.xlsx')
					})}}>
					Скачать шаблон поставок
				</Button>
			</FormikProvider>
		);
	}
);

export default LoadAndParseShipmentsForm;
