/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {$modals, toggleModal} from './index';

$modals.on(toggleModal, (state, {
	componentName,
	open,
	externalId = undefined,
	replaceMode,
	issueId,
	responseId,
	attachmentId
}) => ({
	...state,
	[componentName]: {
		...state[componentName],
		open,
		externalId,
		replaceMode,
		issueId,
		responseId,
		attachmentId
	},
}));
