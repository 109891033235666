/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {merge} from 'effector';
import {openErrorSnackbar} from 'components/helpers/snackbars/ErrorSnackbar';

import './attachments/init';
import './customizer/init';
import './auth/init';
import './profile/init';
import './suppliers/init';
import './factors/init';
import './debtors/init';
import './issues/init';
import './limit/init';
import './notifications/init';
import './shipments/init';
import './diadoc/init';
import './docPy/init';
import './modals/init';

import {$authEffectsFailData} from './auth';
import {$issuesEffectsFailData} from './issues';
import {$debtorsEffectsFailData} from './debtors';
import {$factorsEffectsFailData} from './factors';
import {$profileEffectsFailData} from './profile';
import {$suppliersEffectsFailData} from './suppliers';

const $effectsFailData = merge([
	$authEffectsFailData,
	$debtorsEffectsFailData,
	$factorsEffectsFailData,
	$issuesEffectsFailData,
	$profileEffectsFailData,
	$suppliersEffectsFailData,
]);
$effectsFailData.watch(error => openErrorSnackbar(error.message));
