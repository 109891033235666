/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {
	fetchAnotherUserCompanyCardFx,
	fetchAnotherUserInfoFx,
	fetchProfileFilesFx,
	fetchUserCompanyCardFx,
	fetchUserInfoFx
} from 'models/profile';
import {
	ICompanyCard,
	IIDRequest,
	IIssueWithExpandUsers,
	ILimitBetweenFactorAnd,
	ILongUser,
	ISmallFile,
	UserType
} from 'types';
import {useNavigate, useParams} from "react-router-dom";
import {useStore} from "effector-react";
//@ts-ignore
import moduleStyles from './Profile.module.scss';
import AddFileModal from "../../components/helpers/files/AddFileModal";
import ChangePasswordModal from "../../components/pages/profile/user-info/ChangePasswordModal";
import CustomButton from "../../components/styled/customButton/CustomButton";
import ChangeCompanyCardModal from "../../components/pages/profile/user-info/ChangeCompanyCardModal";
import InfoContainer from "../../components/pages/profile/user-info/InfoContainer";
import CompanyCardSlots from "../../components/features/company-card/CompanyCardSlots";
import FilesContainer from "../../components/pages/profile/user-info/FilesContainer";
import AvailableActions from "../../components/pages/profile/user-info/AvailableActions";
import {calculateActions} from "../../components/pages/profile/user-info/AvailableActions.content";
import {$user, fetchCurrentUserFx} from "../../models/auth";
import {fetchLimitListFx} from "../../models/limit";
import {fetchIssuesListFx} from "../../models/issues";
import RequestLimitsModal from "../../components/pages/limits/RequestLimitsModal";
import RequestFinancingModal from "../../components/pages/profile/shipments/RequestFinancingModal";
import SignNotificationRequestModal from "../../components/pages/profile/user-info/SignNotificationRequestModal";

const styles = moduleStyles as any;

const ProfilePage = ({isContactor = false}): React.ReactElement => {
	const navigate = useNavigate();
	const {id} = useParams();

	const {user: currentUser} = useStore($user);

	const [user, setUser] = useState<null | ILongUser>(null);
	const [files, setFiles] = useState<ISmallFile[]>([]);
	const [companyCard, setCompanyCard] = useState<null | ICompanyCard>(null);
	const [issues, setIssues] = useState<null | IIssueWithExpandUsers[]>(null);
	const [limits, setLimits] = useState<null | Array<ILimitBetweenFactorAnd>>(null);

	const fetchUserInfo = async () => {
		let response;
		if (isContactor)
			response = await fetchAnotherUserInfoFx({id} as IIDRequest);
		else response = await fetchUserInfoFx();
		setUser(response);
	};

	const fetchProfileFiles = async () => {
		if (!isContactor) {
			const response = await fetchProfileFilesFx();
			setFiles(response.docs);
		}
	};

	const fetchUserCompanyCard = async () => {
		let response;
		if (isContactor)
			response = await fetchAnotherUserCompanyCardFx({id} as IIDRequest);
		else response = await fetchUserCompanyCardFx();
		setCompanyCard(response);
	};

	const fetchLimits = async () => {
		if (isContactor) {
			const response = await fetchLimitListFx();
			setLimits(response.docs);
		}
	};

	const fetchCurrentUser = async () => {
		if (!isContactor) {
			await fetchCurrentUserFx();
		}
	};

	const fetchIssues = async () => {
		if (isContactor) {
			let params = {participantId: id, authorId: currentUser?.id};
			if (currentUser?.type === UserType.FACTOR)
				params = {participantId: currentUser?.id, authorId: id};
			const response = await fetchIssuesListFx(params);
			setIssues(response.docs);
		}
	};

	const reloadData = () => {
		const fetchPromises = [fetchUserInfo(), fetchProfileFiles(), fetchUserCompanyCard(), fetchLimits(), fetchIssues(), fetchCurrentUser()];
		Promise.all(fetchPromises).then();
	};

	useEffect(reloadData, [id]);

	return (
		<>
			<CustomButton onClick={() => navigate(-1)}>Вернуться</CustomButton>
			{!isContactor && (
				<>
					<AddFileModal fetchUpdatedFiles={reloadData}/>
					<ChangePasswordModal fetchUserInfo={fetchUserInfo}/>
					{companyCard &&
                        <ChangeCompanyCardModal companyCard={companyCard} fetchUserCompanyCard={fetchUserCompanyCard}/>}
				</>
			)}
			{isContactor && (
				<>
					{id && <RequestLimitsModal factorId={id} reloadData={reloadData}/>}
					<RequestFinancingModal/>
					{/* @ts-ignore*/}
					{currentUser && ('debtors' in currentUser) && currentUser.debtors && <SignNotificationRequestModal
                        debtors={currentUser.debtors.filter(debtor => debtor.notificationSigned === false)}
                        fetchUserInfo={reloadData}/>}
				</>
			)}
			<Box sx={{height: '100vh'}}>
				<Box className={styles.pageContainer}>
					<Box className={styles.firstBlocks}>
						<Box>
							{user && companyCard &&
                                <InfoContainer user={user} companyCard={companyCard} isContactor={isContactor}/>}
						</Box>
						<Box>
							{isContactor
								? issues && limits && user && currentUser &&
                                <AvailableActions
                                    availableActions={calculateActions(currentUser, issues, limits, user, reloadData)}/>
								: user && companyCard &&
                                <CompanyCardSlots user={user} companyCard={companyCard} isContractor={isContactor}
                                                  fetchUserCompanyCard={fetchUserCompanyCard}/>
							}
						</Box>
					</Box>
					<Box sx={{mt: '12px', height: 'fit-content'}}>
						{isContactor ? (user && companyCard &&
                                <CompanyCardSlots user={user} companyCard={companyCard} isContractor={isContactor}
                                                  fetchUserCompanyCard={fetchUserCompanyCard}/>) :
							<FilesContainer files={files} fetchProfileFiles={fetchProfileFiles}/>}
					</Box>
				</Box>
			</Box>
		</>
	);
};

export default ProfilePage;
