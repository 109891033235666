/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
// @ts-ignore
import {ReactComponent as SharedIcon} from '../../../assets/images/files/shared.svg';
// @ts-ignore
import {ReactComponent as CsvIcon} from '../../../assets/images/files/csv.svg';
// @ts-ignore
import {ReactComponent as DocxIcon} from '../../../assets/images/files/docx.svg';
// @ts-ignore
import {ReactComponent as JpegIcon} from '../../../assets/images/files/jpeg.svg';
// @ts-ignore
import {ReactComponent as JsonIcon} from '../../../assets/images/files/json.svg';
// @ts-ignore
import {ReactComponent as PdfIcon} from '../../../assets/images/files/pdf.svg';
// @ts-ignore
import {ReactComponent as XlsIcon} from '../../../assets/images/files/xls.svg';
// @ts-ignore
import {ReactComponent as XmlIcon} from '../../../assets/images/files/xml.svg';
// @ts-ignore
import {ReactComponent as XsdIcon} from '../../../assets/images/files/xsd.svg';
// @ts-ignore
import {ReactComponent as SigIcon} from '../../../assets/images/files/sig.svg';

interface FileIconProps {
	ext: string;
}

const FileIcon = ({ext}: FileIconProps): React.ReactElement => {
	if (ext === 'csv') return <CsvIcon/>;
	if (ext === 'docx') return <DocxIcon/>;
	if (ext === 'jpeg') return <JpegIcon/>;
	if (ext === 'json') return <JsonIcon/>;
	if (ext === 'pdf') return <PdfIcon/>;
	if (ext === 'xls') return <XlsIcon/>;
	if (ext === 'xml') return <XmlIcon/>;
	if (ext === 'xsd') return <XsdIcon/>;
	if (ext === 'sig') return <SigIcon/>;
	return <SharedIcon/>;
};

export default FileIcon;
