/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {apiRequest} from "./index";

export const parseFileToJson = async (data: { form: FormData }) => {
	const response = await apiRequest<any>({
		url: '/api/docPy/excel_to_json',
		data: data.form,
		method: 'post',
	});
	return response.data;
};

export const parseJsonToFile = async (data: { data: any }) => {
	const response = await apiRequest<Blob>({
		url: '/api/docPy/json_to_excel',
		data: data.data,
		method: 'post',
		responseType: 'blob'
	}, true);

	return response.data;
};
