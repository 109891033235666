/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Container} from '@mui/material';
import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import {$customizer} from 'models/customizer';
import Header from './header/Header';
import MainWrapper from './styled/MainWrapper';
import PageWrapper from './styled/PageWrapper';

const DashboardLayout = (): React.ReactElement => (
		<MainWrapper>
			<Header/>
			<PageWrapper>
				<Container
					// @ts-ignore
					maxWidth='xxl'
					sx={{
						maxWidth: '99vw'
					}}
				>
					<Box sx={{height: '100%'}}>
						<Outlet/>
					</Box>
				</Container>
			</PageWrapper>
		</MainWrapper>
	);

export default DashboardLayout;
