/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Snackbar} from '@mui/material';
import {createEvent, createStore} from 'effector';
import {useStore} from 'effector-react';

export const setOpenSuccessSnackbar = createEvent<boolean>();
export const setMessageSuccessSnackbar = createEvent<string>();
export const $successSnackbar = createStore({
	open: false,
	message: '',
})
	.on(setOpenSuccessSnackbar, (state, open) => ({...state, open}))
	.on(setMessageSuccessSnackbar, (state, message) => ({...state, message}));

export const openSuccessSnackbar = (message: string) => {
	setOpenSuccessSnackbar(true);
	setMessageSuccessSnackbar(message);
};

const SuccessSnackbar = (): React.ReactElement => {
	const {open, message} = useStore($successSnackbar);

	const handleClose = () => {
		setOpenSuccessSnackbar(false);
		setMessageSuccessSnackbar('');
	};

	return (
		<Snackbar
			anchorOrigin={{vertical: 'top', horizontal: 'center'}}
			open={open}
			message={message}
			autoHideDuration={3000}
			onClose={handleClose}
			sx={{
				'.MuiPaper-root': {
					color: 'grey.A100',
					backgroundColor: 'success.dark',
				},
				'.MuiSnackbarContent-message': {
					textAlign: 'center',
				},
			}}
		/>
	);
};

export default SuccessSnackbar;
