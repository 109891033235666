/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {apiRequest, IData} from "./index";
import {IIssueFileResponseFullStringParams} from "../types";
import {updateNotifications} from "../models/notifications";

export const addFileRequestToDiadoc = async ({data}: { data: FormData }) => {
	const response = await apiRequest<IData<IIssueFileResponseFullStringParams>>({
		url: `/api/diadoc/send`,
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchDiadocOrgs = async () => {
	const response = await apiRequest<IData<any>>({
		url: `/api/diadoc/orgs`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data.Organizations;
};

export const fetchDiadocPartners = async ({orgId}: { orgId: string }) => {
	const response = await apiRequest<IData<any>>({
		url: `/api/diadoc/${orgId}/partners`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data.Counteragents;
};

export const fetchDiadocDocuments = async (data: { boxId: string, partnerBoxId: string, filterCategory: string, fromDocumentDate: string, toDocumentDate: string }) => {
	const response = await apiRequest<IData<any>>({
		url: `/api/diadoc/documents`,
		data,
		method: 'POST',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchDiadocDocumentTypes = async (data: { boxId: string }) => {
	const response = await apiRequest<IData<any>>({
		url: `/api/diadoc/${data.boxId}/documentTypes`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};