/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import PaginationTable from 'components/helpers/table/PaginationTable';
import {Box} from "@mui/system";
import {Stack, Typography} from "@mui/material";
import {DateTime} from "luxon";
import fileDownloadIcon from "@iconify-icons/gg/software-download";
import fileRemoveIcon from "@iconify-icons/gg/file-remove";
import NotFoundCard from "../../components/helpers/not-found/NotFoundCard";
import {fetchSignedAttachmentsFx} from "../../models/attachments";
import {deleteFileFx, getFileFx} from "../../models/profile";
import {downloadFile} from "../../shared/functions";
import EditFileModal from "../../components/pages/profile/files/EditFileModal";


const DocumentsPage = (): React.ReactElement => {
	const [documents, setDocuments] = useState<any>([]);

	const fetchAttachments = async () => {
		const response = await fetchSignedAttachmentsFx();
		setDocuments(response);
	}

	useEffect(() => {
		const fetchPromises = [fetchAttachments()];
		Promise.all(fetchPromises).then();
	}, []);

	return (
		<Box sx={{height: '100%'}}>
			<Stack sx={{mb: 3}}>
				<Typography variant='h2'>Документы</Typography>
			</Stack>
			{!documents?.length ? (
				<NotFoundCard name='Документы'/>
			) : (
				<PaginationTable
					options={[
						{
							key: 'filename',
							title: 'Имя файла',
							sx: {textAlign: 'center'},
							isPopover: true
						},
						{
							key: 'uploadDate',
							title: 'Дата создания',
							sx: {textAlign: 'center'},
							isPopover: true,
							reduce: (option: string) => DateTime.fromISO(option).toFormat('dd.MM.yyyy')
						},
						{key: 'actions', title: '', sx: {textAlign: 'center'}}
					]}
					rows={documents}
					rowsActions={[
						{
							tooltip: 'Скачать файл',
							onClick: async item => {
								const blob = await getFileFx({fileId: String(item.id)});
								downloadFile(blob, item.filename);
							},
							icon: fileDownloadIcon,
						},
						{
							id: 'edit-file-component',
							component: EditFileModal,
							props: {fetchAttachments},
						},
						{
							tooltip: 'Удалить файл',
							onClick: async item => {
								await deleteFileFx({fileId: String(item.id)});
								await fetchAttachments();
							},
							icon: fileRemoveIcon,
						},
					]}
				/>)}
		</Box>
	);
};

export default DocumentsPage;