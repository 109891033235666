/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {useField, useFormikContext} from 'formik';
import {Autocomplete, Chip, createFilterOptions, TextField, Typography} from '@mui/material';
import {SxProps} from "@mui/system";

const defaultOptionLabel = (option: { name: string }) => (option ? option.name : '');

const FormikAutocomplete = (props: {
	name: string;
	options: any;
	groupByFunc?: (option: any) => string;
	noOptionsText?: string;
	getOptionLabel?: (option: any) => string;
	disabled?: boolean;
	multiple?: boolean;
	limit?: number;
	textFieldProps?: { label: string };
	sx?: SxProps;
	fullWidth?: boolean;
	filterSelectedOptions?: boolean;
	isEqual?: (option: any, value: any) => boolean;
}): React.ReactElement => {
	const {
		name,
		groupByFunc,
		noOptionsText = 'Нет вариантов',
		disabled,
		options,
		getOptionLabel,
		textFieldProps,
		multiple = false,
		limit = 300,
		sx = {},
		fullWidth = true,
		filterSelectedOptions = true,
		isEqual = (option: any, value: any) => option.name === value.name
	} = props;

	const {isSubmitting} = useFormikContext();
	const fieldProps = useField(name);
	const [field, meta, helpers] = fieldProps;

	const showError = meta.touched && !!meta.error;

	const filterOptions = createFilterOptions<{ name: string }>({limit});
	return (
		<Autocomplete
			sx={sx}
			groupBy={groupByFunc}
			filterOptions={filterOptions}
			multiple={multiple}
			fullWidth={fullWidth}
			clearText='Очистить'
			noOptionsText={noOptionsText}
			filterSelectedOptions={filterSelectedOptions}
			openText='Открыть'
			disabled={disabled || isSubmitting}
			options={options}
			isOptionEqualToValue={isEqual}
			getOptionLabel={getOptionLabel || defaultOptionLabel}
			{...field}
			onChange={(_, value) => {
				helpers.setValue(value || (multiple ? [] : ''));
			}}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => (
					<Chip label={<Typography
						sx={{fontSize: '1rem'}}>{option.name}</Typography>} {...getTagProps({index})} />
				))
			}
			onBlur={() => helpers.setTouched(true)}
			renderInput={params => (
				<TextField
					{...params}
					error={showError}
					helperText={showError && meta.error}
					inputProps={{...params.inputProps}}
					{...textFieldProps}
				/>
			)}
		/>
	);
};

export default FormikAutocomplete;
