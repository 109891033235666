/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	addFileToShipmentFx,
	addShipmentFx,
	addShipmentsFx,
	fetchSupplierDebtorShipmentsFx,
	getFileFromShipmentFx
} from "./index";
import {
	addFileToShipment,
	addShipment,
	addShipments,
	fetchSupplierDebtorShipmentsList,
	getFileFromShipment
} from "../../api";

fetchSupplierDebtorShipmentsFx.use(fetchSupplierDebtorShipmentsList);
addShipmentFx.use(addShipment);
addShipmentsFx.use(addShipments);
addFileToShipmentFx.use(addFileToShipment);
getFileFromShipmentFx.use(getFileFromShipment)