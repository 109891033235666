/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect} from "effector";

export const parseFileToJsonFx = createEffect<{ form: FormData }, any>();
export const parseJsonToFileFx = createEffect<{ data: any }, any>();