/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {
	IAddFileParams,
	ICompanyCard,
	ICompanyCardSlotFile,
	IDiadocForm,
	IFetchProfileFilesResponse,
	IFile,
	IIDRequest,
	ILongUser,
	IPatchedFile,
	IPatchFileParams,
	IPatchUserCompanyCardParams,
	IPatchUserInfoParams,
} from 'types';
import {apiRequest, IData} from './index';

export const fetchUserInfo = async () => {
	const response = await apiRequest<IData<ILongUser>>({
		url: '/api/profile',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchAnotherUserInfo = async ({id}: IIDRequest) => {
	const response = await apiRequest<IData<ILongUser>>({
		url: `/api/profile/${id}`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const patchUserInfo = async ({data}: IPatchUserInfoParams) => {
	const response = await apiRequest<IData<ILongUser>>({
		url: '/api/profile',
		method: 'PATCH',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addDiadocAccount = async (data: IDiadocForm) => {
	const response = await apiRequest<IData<any>>({
		url: '/api/profile/diadoc',
		method: 'POST',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data;
};

export const deleteNotification = async ({id}: { id: number }) => {
	const response = await apiRequest<IData<any>>({
		url: `/api/profile/notification/${id}`,
		method: 'DELETE',
	});
	updateNotifications(response.data.notifications);
	return response.data;
};

export const fetchProfileFiles = async () => {
	const response = await apiRequest<IFetchProfileFilesResponse>({
		url: '/api/profile/files',
		method: 'GET',
	});
	return response.data;
};

export const getFile = async ({fileId}: { fileId: string }) => {
	const response = await apiRequest<Blob>({
		url: `/api/profile/file/${fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	return response.data;
};

export const addFile = async (data: IAddFileParams) => {
	const response = await apiRequest<IFile>({
		url: '/api/profile/file',
		method: 'POST',
		data: data.formData,
	});
	return response.data;
};

export const patchFile = async ({fileId, data}: IPatchFileParams) => {
	const response = await apiRequest<IPatchedFile>({
		url: `/api/profile/file/${fileId}`,
		method: 'PATCH',
		data,
	});
	return response.data;
};

export const deleteFile = async ({fileId}: { fileId: string }) => {
	const response = await apiRequest<''>({
		url: `/api/profile/file/${fileId}`,
		method: 'DELETE',
	});
	return response.status;
};

export const fetchUserCompanyCard = async () => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: '/api/profile/companyCard',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchAnotherUserCompanyCard = async ({id}: IIDRequest) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/profile/${id}/companyCard`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const patchUserCompanyCard = async (data: IPatchUserCompanyCardParams) => {
	const response = await apiRequest<IData<ICompanyCard>>({
		url: `/api/profile/companyCard`,
		method: 'PATCH',
		data,
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addFileToSlotCompanyCard = async ({
	                                               slotId,
	                                               data,
                                               }: {
	slotId: string;
	data: FormData;
}) => {
	const response = await apiRequest<ICompanyCardSlotFile>({
		url: `/api/profile/companyCard/slot/${slotId}`,
		method: 'POST',
		data,
	});
	return response.data;
};

export const getFileFromSlotCompanyCard = async ({userId, slotId}: { userId: string, slotId: string }) => {
	const response = await apiRequest<Blob>({
		url: `/api/profile/${userId}/companyCard/slot/${slotId}`,
		method: 'GET',
		responseType: 'blob',
	});
	return response.data;
};

export const deleteFileFromSlotCompanyCard = async ({slotId}: { slotId: string }) => {
	const response = await apiRequest<''>({
		url: `/api/profile/companyCard/slot/${slotId}`,
		method: 'DELETE',
	});
	return response.status;
};

export const fetchNDA = async () => {
	const response = await apiRequest<Blob>({
		url: `/api/profile/nda`,
		method: 'GET',
		responseType: 'arraybuffer',
	});
	return response.data;
};
