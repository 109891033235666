/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useMemo, useRef} from 'react';
import {Divider, Stack, Typography} from '@mui/material';
import {SxProps} from '@mui/system';
import _ from 'lodash';
import {DateTime} from 'luxon';
import {useStore} from "effector-react";
import CustomButton from 'components/styled/customButton/CustomButton';
import DateBlock from './DateBlock';
import MessageItem from './MessageItem';
import {
	IIssueDebtorsResponse,
	IIssueShipmentsResponse,
	IIssueWithExpandUsers,
	IResponsesListItem,
	UserType
} from '../../../types';
import {handleCompleteIssueModalOpen} from "./chat-actions/CompleteIssueModal";
import {IssueType} from "../../../types/enum/issueType";
import {$user} from "../../../models/auth";
import {$customizer} from "../../../models/customizer";
import AddSignAndSendFile from "./AddSignAndSendFile";
import SignAndSendFile from "./SignAndSendFile";

const groupResponsesByDate = (responses: IResponsesListItem[]) => {
	const groupedMessages = _.groupBy(responses, item =>
		DateTime.fromISO(item.createdAt).toFormat('yyyy-MM-dd')
	);
	const sortedDates = Object.keys(groupedMessages)
		.map(date => DateTime.fromISO(date))
		.sort((a, b) => a.toMillis() - b.toMillis());
	return sortedDates.map(date => groupedMessages[date.toFormat('yyyy-MM-dd')]);
};

const sortResponsesByTime = (responses: IResponsesListItem[]) =>
	responses.sort(
		(a, b) => DateTime.fromISO(a.createdAt).toMillis() - DateTime.fromISO(b.createdAt).toMillis()
	);

interface IMessageListProps {
	sx?: SxProps;
	chatInfo: IIssueWithExpandUsers;
	responsesList: IResponsesListItem[];
	fetchResponsesList: () => void;
}

const MessageList = ({sx, chatInfo, responsesList, fetchResponsesList}: IMessageListProps): React.ReactElement => {
	const refBottomLine = useRef<null | HTMLDivElement>(null);

	const {activeMode} = useStore($customizer);
	const {user} = useStore($user);

	const completionData = {
		[IssueType.CONVERSATION]: null,
		[IssueType.SIGN_NDA]: null,
		[IssueType.REFUSE_NDA]: null,
		[IssueType.SIGN_NOTIFICATION]: null,
		[IssueType.VERIFY_SHIPMENTS]: responsesList.findIndex(response => response.type === 'shipments') === -1 ? null : ((responsesList.filter(response => response.type === 'shipments')[0]) as IIssueShipmentsResponse).shipments,
		[IssueType.REQUEST_FINANCING]: responsesList.findIndex(response => response.type === 'shipments') === -1 ? null : ((responsesList.filter(response => response.type === 'shipments')[0]) as IIssueShipmentsResponse).shipments,
		[IssueType.SET_LIMIT]: responsesList.findIndex(response => response.type === 'debtors') === -1 ? null : ((responsesList.filter(response => response.type === 'debtors')[0]) as IIssueDebtorsResponse).debtors
	}

	const buttonNames = {
		[IssueType.CONVERSATION]: 'Завершить диалог',
		[IssueType.SIGN_NDA]: 'Перейти к подписанию НДА',
		[IssueType.REFUSE_NDA]: 'Завершить диалог',
		[IssueType.SIGN_NOTIFICATION]: chatInfo.author.type === UserType.DEBTOR ? 'Завершить': 'Перейти к подписи Уведомления',
		[IssueType.VERIFY_SHIPMENTS]: user?.type === UserType.DEBTOR ? 'Утвердить поставки и перейти к подписанию' : 'Утвердить поставки',
		[IssueType.REQUEST_FINANCING]: chatInfo.additionalStepsDone ? 'Подтвердить финансирование' : 'Утвердить финансирования',
		[IssueType.SET_LIMIT]: 'Установить лимиты и перейти к подписанию'
	}

	const transformedMessagesList = useMemo(
		() => groupResponsesByDate(responsesList),
		[responsesList]
	);

	useEffect(() => {
		if (refBottomLine && refBottomLine.current)
			refBottomLine.current?.scrollIntoView({block: 'start'});
	}, [responsesList]);

	return (
		<Stack sx={{p: 2, pr: 0, overflowY: 'scroll', ...sx}}>
			{transformedMessagesList.map((groupByDate, index) => (
				<React.Fragment key={index}>
					<DateBlock date={DateTime.fromISO(groupByDate[0].createdAt)}/>
					<Stack>
						{sortResponsesByTime(groupByDate).map((item, idx, arr) => (
							<MessageItem prevItem={idx === 0 ? undefined : arr[idx - 1]} item={item} chatInfo={chatInfo}
							             key={item.id} user={user} activeMode={activeMode}/>
						))}
					</Stack>
				</React.Fragment>
			))}
			<div ref={refBottomLine}/>
			<Divider sx={{m: 1, borderWidth: 1}}/>
			<Stack direction='column' alignItems='center' spacing={1.5}>
				{/*{(!!chatInfo.diadocAuthorUrl && !!chatInfo.diadocParticipantUrl) && (*/}
				{/*	<Card sx={{*/}
				{/*		width: '100%',*/}
				{/*		borderRadius: '5px',*/}
				{/*		borderStyle: 'solid',*/}
				{/*		borderWidth: '1px',*/}
				{/*		borderColor: 'rgba(255, 255, 255, 0.23)'*/}
				{/*	}}>*/}
				{/*		<CardContent sx={{textAlign: 'center', m: 0, p: 0}}>*/}
				{/*			<Typography>*/}
				{/*				Доступ к загруженному документу осуществляется через Диадок*/}
				{/*			</Typography>*/}
				{/*			<Typography sx={{m: 1}} color='error.main'>*/}
				{/*				Внимание! Не забудьте подписать документ*/}
				{/*			</Typography>*/}
				{/*		</CardContent>*/}
				{/*		<CardActions sx={{justifyContent: 'center', m: 0, p: 0}}>*/}
				{/*			<Link target='_blank'*/}
				{/*			      href={chatInfo[`diadoc${chatInfo.author.id === user?.id ? 'Author' : 'Participant'}Url`]}>*/}
				{/*				Перейти*/}
				{/*			</Link>*/}
				{/*		</CardActions>*/}
				{/*	</Card>*/}
				{/*)}*/}
				{/*{(user?.diadocConnected && !(!!chatInfo.diadocAuthorUrl && !!chatInfo.diadocParticipantUrl)) && <SendFileDiadoc issueId={chatInfo.id}/>}*/}
				{/*<SignAndSendFile issueId={chatInfo.id} fetchResponsesList={fetchResponsesList}/>*/}
				<AddSignAndSendFile fetchResponsesList={fetchResponsesList}/>
				{(
					(!chatInfo.finished && user?.type === UserType.FACTOR && (chatInfo.author.generated || (chatInfo.debtor && chatInfo.debtor.generated))) && (
						<Typography sx={{fontSize: '0.85rem', color: 'lightcoral'}}>Внимание! Загрузить необходимо файл с физической подписью дебитора</Typography>
					)
				)}
				{(
					!chatInfo.finished
					&& chatInfo.acknowledgmentAuthor === null
					&& chatInfo.author.generated) && (
					<CustomButton onClick={() => handleCompleteIssueModalOpen(completionData[chatInfo.type], true)}>{`Завершить диалог от имени ${chatInfo.author.name}`}</CustomButton>
				)}
				{(
					!chatInfo.finished
					&& !(chatInfo.acknowledgmentAuthor === false || chatInfo.acknowledgmentParticipant === false)
					&& (((chatInfo.acknowledgmentParticipant === true || user?.type === UserType.FACTOR) && user?.type !== UserType.DEBTOR) || (((chatInfo.acknowledgmentAuthor === true || user?.type === UserType.DEBTOR))))
					&& chatInfo[`acknowledgment${chatInfo.author.id === user?.id ? 'Author' : 'Participant'}`] === null
					&& !chatInfo.additionalStepsInProgress
					&& !(chatInfo.type === IssueType.REFUSE_NDA)
					&& !(user?.type !== UserType.DEBTOR && chatInfo.type === IssueType.VERIFY_SHIPMENTS)) && (
					<CustomButton onClick={() => handleCompleteIssueModalOpen(completionData[chatInfo.type])}>{buttonNames[chatInfo.type]}</CustomButton>
				)}
				{(
					!chatInfo.finished
					&& responsesList.filter(response => response.type === 'signedFile' && 'final' in response && response.final).length === 0
					&& chatInfo.acknowledgmentAuthor
					&& chatInfo.acknowledgmentParticipant
					&& user?.type === UserType.FACTOR) && (
					<SignAndSendFile issueId={chatInfo.id}
					                 fetchResponsesList={fetchResponsesList}/>
				)}
			</Stack>
		</Stack>
	);
};

export default MessageList;
