/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {forwardRef, useImperativeHandle} from 'react';
import {Stack, TextField} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import * as Yup from 'yup';
import {IDebtor} from "../../../../../models/suppliers";
import {ILimit} from "../../../../../types";

export interface ISetDebtorFormValues {
	verifiedAmount: number;
	deferralPeriod: number;
	gracePeriod: number;
}

interface ISetDebtorFormProps {
	debtor: IDebtor;
	submitFunc: (values: ISetDebtorFormValues) => void;
}

const SetDebtorForm = forwardRef(
	({debtor, submitFunc}: ISetDebtorFormProps, ref) => {
		const SetDebtorFormSchema = Yup.object().shape({
			verifiedAmount: Yup.number().required('Поле является обязательным'),
			deferralPeriod: Yup.number().required('Поле является обязательным'),
			gracePeriod: Yup.number().required('Поле является обязательным')
		});

		const formik = useFormik<ISetDebtorFormValues>({
			initialValues: {
				verifiedAmount: (debtor.supplyAmount as ILimit).amount / 100,
				deferralPeriod: debtor.deferralPeriod,
				gracePeriod: debtor.gracePeriod
			},
			validationSchema: SetDebtorFormSchema,
			validateOnChange: false,
			validateOnBlur: false,
			onSubmit: (values: ISetDebtorFormValues) => submitFunc(values),
		});

		const {touched, errors, handleSubmit, getFieldProps} = formik;

		useImperativeHandle(ref, () => ({...formik}));

		return (
			<FormikProvider value={formik}>
				<Form style={{width: '100%'}} autoComplete='off' noValidate onSubmit={handleSubmit}>
					<Stack spacing={2}>
						<TextField
							type='number'
							fullWidth
							label='Сумма финансирования'
							{...getFieldProps('verifiedAmount')}
							error={Boolean(touched.verifiedAmount && errors.verifiedAmount)}
							helperText={touched.verifiedAmount && errors.verifiedAmount}
						/>
						<TextField
							type='number'
							fullWidth
							label='Срок отсрочки платежа'
							{...getFieldProps('deferralPeriod')}
							error={Boolean(touched.deferralPeriod && errors.deferralPeriod)}
							helperText={touched.deferralPeriod && errors.deferralPeriod}
						/>
						<TextField
							type='number'
							fullWidth
							label='Срок льготного периода'
							{...getFieldProps('gracePeriod')}
							error={Boolean(touched.gracePeriod && errors.gracePeriod)}
							helperText={touched.gracePeriod && errors.gracePeriod}
						/>
					</Stack>
				</Form>
			</FormikProvider>
		);
	}
);

export default SetDebtorForm;
