/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect} from "effector/compat";
import {ILimit} from "../../types";

export interface IShipment {
	_id: string;
	shipmentCode: string;
	monetaryClaim: string | ILimit;
	firstPayment: string | ILimit;
	agreementPaymentDate: string;
	contractPaymentDate: string;
	actShipmentDate: string;
	actNumber: string;
	contractNumber: string;
	contractDate: string;
	invoiceNumber?: string | undefined;
	invoiceDate?: string | undefined;
	createdAt: string;
	status: boolean;
}

export interface IShipmentWithFiles extends IShipment {
	act?: File;
	invoice?: File | undefined;
}

export interface IShipmentAddFile {
	id: string;
	formData: FormData;
}

export interface IShipmentGetFile {
	shipmentId: string;
	fileId: string;
}

export type IShipmentAddParams = Omit<IShipment, '_id' | 'createdAt'>;

export const fetchSupplierDebtorShipmentsFx = createEffect<{ id: string }, IShipmentWithFiles[]>();
export const addShipmentFx = createEffect<{ debtorId: string, data: IShipmentAddParams }, IShipment>();
export const addShipmentsFx = createEffect<{ debtorId: string, data: (IShipmentAddParams & ({ debtorInn: string }))[] }, IShipment[]>();
export const addFileToShipmentFx = createEffect<{ debtorId: string, data: IShipmentAddFile }, void>();
export const getFileFromShipmentFx = createEffect<IShipmentGetFile, Blob>();