/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, CircularProgress} from '@mui/material';
import {SxProps} from '@mui/system';

interface ISpinnerProps {
	size?: string;
	sx?: SxProps;
	color?: string;
}

const Spinner = ({size = '3rem', sx, color}: ISpinnerProps): React.ReactElement => (
	<Box sx={{display: 'grid', placeItems: 'center', overflow: 'hidden', ...sx}}>
		<CircularProgress size={size} sx={{color}}/>
	</Box>
);

export default Spinner;
