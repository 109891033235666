/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {IFetchUsersListResponse} from '../../types';

export const fetchDebtorsListFx = createEffect<void, IFetchUsersListResponse>();
export const checkInnValidityFx = createEffect<string, boolean>();

export const $debtorsEffectsFailData = merge(
	[fetchDebtorsListFx].map((item: Effect<any, any>) => item.failData)
);
