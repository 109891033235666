/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useState} from 'react';
import {Card, Divider, Drawer, Stack, Typography} from '@mui/material';
import {createEvent, createStore} from 'effector';
import {useStore} from 'effector-react';
import {IIssueWithExpandUsers, IResponsesListItem} from 'types';
import {io, Socket} from "socket.io-client";
import MessageForm from './MessageForm';
import MessageList from './MessageList';
import {fetchIssueFx, fetchResponsesListFx} from '../../../models/issues';
import Spinner from '../../helpers/other/Spinner';
import CompleteIssueModal from './chat-actions/CompleteIssueModal';
import SetLimitModal from './chat-actions/SetLimitModal';
import AddResponseCompanyCardModal from './chat-actions/AddResponseCompanyCardModal';
import {IssueType} from "../../../types/enum/issueType";
import {$user} from "../../../models/auth";
import AddFileModal from "../../helpers/files/AddFileModal";

interface IChatProps {
	open: boolean;
	issueId: null | string;
	reloadData: Function;
	refreshNeeded: boolean;
}

export const setOpenChat = createEvent<boolean>();
export const setIssueId = createEvent<null | string>();
export const setReloadData = createEvent<Function>();
export const setRefreshNeeded = createEvent<boolean>();
export const $chat = createStore<IChatProps>({
	open: false,
	issueId: null,
	reloadData: () => {
	},
	refreshNeeded: false
})
	.on(setOpenChat, (state, open) => ({...state, open}))
	.on(setIssueId, (state, issueId) => ({...state, issueId}))
	.on(setReloadData, (state, reloadData) => ({...state, reloadData}))
	.on(setRefreshNeeded, (state, refreshNeeded) => ({...state, refreshNeeded}));

export const openChat = (issueId: null | string, reloadData = () => {
}) => {
	setOpenChat(true);
	setReloadData(reloadData);
	setIssueId(issueId);
};

export const refreshChat = () => {
	setRefreshNeeded(true);
}

const Chat = (): React.ReactElement => {
	const {open, issueId, reloadData, refreshNeeded} = useStore($chat);
	const {token} = useStore($user);
	const fetchIssuePending = useStore(fetchIssueFx.pending);
	const fetchResponsesListPending = useStore(fetchResponsesListFx.pending);

	const [chatInfo, setChatInfo] = useState<null | IIssueWithExpandUsers>(null);
	const [responsesList, setResponsesList] = useState<null | IResponsesListItem[]>(null);
	const [socket, setSocket] = useState(null as unknown as Socket);

	const fetchResponsesList = () => {
		if (open && issueId)
			socket.emit('getResponses', {issueId});
	};

	useEffect(() => {
		fetchResponsesList();
		fetchIssue().then();
		setRefreshNeeded(false);
	}, [refreshNeeded]);

	const fetchIssue = async (timeout = 300) => {
		if (open && issueId) {
			setTimeout(async () => {
				const response = await fetchIssueFx({issueId});
				setChatInfo(response);
			}, timeout);
		}
	};

	useEffect(() => {
		if (open)
			setSocket(io(`${process.env.REACT_APP_KND_BACKEND_URL}:${process.env.REACT_APP_KND_BACKEND_PORT}`, {
				transportOptions: {
					polling: {
						extraHeaders: {
							Authorization: token,
						}
					}
				}
			}));
		fetchIssue().then();
	}, [open, issueId]);

	useEffect(() => {
		if (socket) {
			socket.on('sendResponses', (response) => setResponsesList(response.docs));
			fetchResponsesList();
		}
	}, [socket]);

	return (
		<Drawer
			PaperProps={{style: {borderBottomRightRadius: '75px', overflowY: 'hidden'}}}
			open={open}
			onClose={() => {
				socket.disconnect();
				setOpenChat(false);
				reloadData();
				setTimeout(() => {
					setChatInfo(null);
					setResponsesList(null);
				}, 300);
			}}
			transitionDuration={300}
		>
			<AddFileModal fetchUpdatedFiles={fetchResponsesList}/>
			<Stack
				sx={{
					p: 2.5,
					pr: 0,
					pt: chatInfo?.type === 'signNDA' ? 1.5 : 2.5,
					width: '520px',
					height: '100%',
					alignItems: 'center',
				}}
			>
				{!responsesList || !chatInfo || fetchResponsesListPending || fetchIssuePending ? (
					<Stack sx={{height: '100%', display: 'grid', placeItems: 'center'}}>
						<Spinner size='3rem'/>
					</Stack>
				) : (
					<>
						<SetLimitModal chatInfo={chatInfo} fetchIssue={fetchIssue}
						               fetchResponsesList={fetchResponsesList}/>
						<AddResponseCompanyCardModal
							chatInfo={chatInfo}
							fetchResponsesList={fetchResponsesList}
						/>
						<CompleteIssueModal chatInfo={chatInfo} fetchIssue={fetchIssue}
						                    fetchResponsesList={fetchResponsesList}/>
						{chatInfo.type === IssueType.SET_LIMIT && (
							<Typography sx={{fontSize: '1.5rem'}}>
								<strong>
									{chatInfo.limitHuman
										? `Установленный лимит: ${chatInfo.limitHuman}`
										: 'Лимит не установлен'}
								</strong>
							</Typography>
						)}
						<Card
							sx={{
								width: '100%',
								height: '100%',
								m: 0,
								p: 0,
								boxShadow: 'none',
							}}
						>
							<Stack sx={{height: '100%'}}>
								{responsesList.length ? (
									<MessageList
										sx={{height: '93%'}}
										chatInfo={chatInfo}
										responsesList={responsesList}
										fetchResponsesList={fetchResponsesList}
									/>
								) : (
									<Stack sx={{height: '100%', display: 'grid', placeItems: 'center'}}>
										<Typography variant='body2'>
											<strong>Сообщения отсутствуют</strong>
										</Typography>
									</Stack>
								)}
								{(!chatInfo.finished) && (
									<>
										<Divider/>
										<MessageForm
											sx={{height: '7%'}}
											chatInfo={chatInfo}
											fetchIssue={fetchIssue}
											fetchResponsesList={fetchResponsesList}
										/>
									</>
								)}
							</Stack>
						</Card>
					</>
				)}
			</Stack>
		</Drawer>
	);
};

export default Chat;
