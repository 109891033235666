/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useMemo, useState} from 'react';
import {
	Paper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
} from '@mui/material';
//@ts-ignore
import {isFunction} from "lodash";
//@ts-ignore
import moduleStyles from './PaginationTable.module.scss';
import {Column, PaginationTableProps, Row, RowAction} from './PaginationTable.types';

const styles = moduleStyles as any;
const visibleRowsCount = 8;

const cellContent = (
	row: Row,
	column: Column,
	innerCell = false,
	rowsActions: Array<RowAction> = []
) => {
	const value = row[column.key];
	const content = column.format ? column.format(value) : value;
	const {key} = column;
	const textAlign = column.align || 'center';
	let action =
		column.isAction && rowsActions.length ? rowsActions.find(ra => ra.key === column.key)! : null;
	if (!!action && 'active' in action && isFunction(action.active) && !action?.active(row))
		action = null;

	if (innerCell) {
		return (
			<Stack
				data-inner-cell
				key={key}
				className={styles.innerTableCell}
				sx={{textAlign, p: action && '0.5rem !important'}}
			>
				{action ? action.element(row) : content}
			</Stack>
		);
	}

	return (
		<TableCell key={key} align={textAlign} className={styles.tableCell}>
			{content}
		</TableCell>
	);
};

const NewPaginationTable = ({
	                            columns,
	                            rows,
	                            rowsActions,
	                            rowOnClick,
                            }: PaginationTableProps): React.ReactElement => {
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState<number>(10);

	const withActions = useMemo<boolean>(
		() => !!(columns.filter(c => c.isAction).length && rowsActions && rowsActions.length),
		[columns, rowsActions]
	);

	const iterableRows = useMemo<Array<Row>>(
		() => rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
		[rows, page, rowsPerPage]
	);

	const filteredColumns = useMemo<{ data: Array<Column>; actions: Array<Column> }>(
		() => ({data: columns.filter(c => !c.isAction), actions: columns.filter(c => c.isAction)}),
		[columns]
	);

	const changePageAndRowsPerPage = (_rowsPerPage: number, _page: number) => {
		setRowsPerPage(_rowsPerPage);
		setPage(_page);
	};

	useEffect(() => {
		changePageAndRowsPerPage(10, 0);
	}, [columns, rows]);

	return (
		<Paper className={styles.paper}>
			<TableContainer className={styles.tableContainer}>
				<Table stickyHeader aria-label='sticky table'>
					<TableHead>
						<TableRow>
							{columns.map(column => (
								<TableCell
									key={column.key}
									align='center'
									className={styles.tableCellHeader}
								>
									{column.title}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{iterableRows.map(row => {
							if (withActions) {
								return (
									<TableRow tabIndex={-1} key={row.id}>
										<TableCell
											sx={{height: '1.24rem', p: 0}}
											colSpan={filteredColumns.data.length}
											className={styles.hoveredTableRow}
											onClick={() => rowOnClick ? rowOnClick(row) : null}
										>
											<Stack direction='row'>
												{filteredColumns.data.map(column =>
													cellContent(row, column, true)
												)}
											</Stack>
										</TableCell>
										<TableCell
											sx={{height: '1.24rem', p: 0}}
											colSpan={filteredColumns.actions.length}
										>
											<Stack direction='row'>
												{filteredColumns.actions.map(column =>
													cellContent(row, column, true, rowsActions)
												)}
											</Stack>
										</TableCell>
									</TableRow>
								);
							}
							return (
								<TableRow
									hover
									tabIndex={-1}
									key={row.id}
									className={styles.hoveredTableRow}
									onClick={() => rowOnClick ? rowOnClick(row) : null}
								>
									{columns.map(column => cellContent(row, column))}
								</TableRow>
							);
						})}
						{iterableRows.length < visibleRowsCount &&
							[...Array(visibleRowsCount - iterableRows.length)].map((row, i) => (
								<TableRow tabIndex={-1} key={i}>
									{columns.map(column => (
										<TableCell key={column.key} className={styles.tableCell}/>
									))}
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 15, 20]}
				component='div'
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event: unknown, newPage: number) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
					changePageAndRowsPerPage(+event.target.value, 0);
				}}
			/>
		</Paper>
	);
};

export default NewPaginationTable;
