/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Card, Paper, Stack, Typography} from '@mui/material';
//@ts-ignore
import moduleStyles from './AvailableActions.module.scss';
import CustomButton from "../../../styled/customButton/CustomButton";
import {AvailableAction} from "./AvailableActions.types";

const styles = moduleStyles as any;

const AvailableActions = ({availableActions}: { availableActions: AvailableAction[] }) => (
	<Card className={styles.card}>
		<Box className={styles.title}>
			{availableActions.length ? (
				<Typography>Доступные действия</Typography>
			) : (
				<Typography>Доступные действия отсутствуют</Typography>
			)}
		</Box>
		<Stack spacing={1.5} sx={{mt: '1rem'}}>
			{availableActions.filter(coreAction => coreAction.visible === undefined || coreAction.visible).map(item => (
				<Paper key={item.id} variant='outlined' className={styles.paper}>
					<Typography>{item.title}</Typography>
					<Stack direction='row' spacing={1}>
						{item.actions.filter(action => action.visible === undefined || action.visible).map(action => (
							<CustomButton
								key={action.id}
								onClick={action.onClick}
								disabled={action.disabled}
							>
								<span>{action.title}</span>
							</CustomButton>
						))}
					</Stack>
				</Paper>
			))}
		</Stack>
	</Card>
);

export default AvailableActions;
