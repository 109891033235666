/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {fetchDebtorsForFactorFx, fetchDebtorsInFactorsFx, fetchFactorsListFx} from './index';
import {fetchDebtorsForFactor, fetchDebtorsInFactors, fetchFactorsList} from '../../api';

fetchFactorsListFx.use(fetchFactorsList);
fetchDebtorsInFactorsFx.use(fetchDebtorsInFactors);
fetchDebtorsForFactorFx.use(fetchDebtorsForFactor);
