/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect, useMemo, useState} from 'react';
import {Container, Stack, Typography} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import CustomButton from 'components/styled/customButton/CustomButton';
import {useStore} from "effector-react";
import {signaturePage} from '../shared/content/signature';
import Spinner from '../components/helpers/other/Spinner';
import {createWithAdminFx, fetchIssuesListFx} from '../models/issues';
import {openChat} from '../components/features/chat/Chat';
import {$user, fetchCurrentUserFx} from "../models/auth";
import {fetchNDAFx, patchUserInfoFx} from "../models/profile";
import {blobToFile, useOnceCall} from "../shared/functions";
import AddSignAndSendFile, {handleAddSignAndSendFileModalOpen} from "../components/features/chat/AddSignAndSendFile";

type ISignatureTypes = 'nda';

const SignaturePage = (): React.ReactElement => {
	const navigate = useNavigate();
	const location = useLocation();
	const {user} = useStore($user);

	const signatureType = useMemo<ISignatureTypes>(
		() => location.pathname.replace('/signature/', '') as ISignatureTypes,
		[location]
	);

	const [orBlob, setOrBlob] = useState<Blob | null>(null);
	const [blob, setBlob] = useState<string | null>(null);

	const blob2Base64 = (blob1: Blob): Promise<string> =>
		new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob1);
			// @ts-ignore
			reader.onload = () => resolve(reader.result.toString());
			reader.onerror = error => reject(error);
		})

	useOnceCall(() => {
		if (blob === null)
			fetchNDAFx().then(async response => {
				const pdfBlob = new Blob([response], {type: 'application/pdf'});
				setOrBlob(pdfBlob);
				const base64 = await blob2Base64(pdfBlob);
				setBlob(base64);
			});
	});

	useEffect(() => {
		if (user?.ndaSigned)
			navigate('/dashboard', {replace: true});
	}, [user])

	const signNDA = () => {
		if (orBlob)
			handleAddSignAndSendFileModalOpen(
				'create',
				patchUserInfoFx,
				blobToFile(orBlob, 'nda.pdf'),
				'nda',
				'',
				'',
				'',
				false
			);
	}

	const openChatWithAdmin = async () => {
		const issuesList = await fetchIssuesListFx({});
		const conversationAdmin = issuesList.docs.find(
			issue => issue.type === 'refuseNDA' && !issue.finished
		);
		if (conversationAdmin) openChat(conversationAdmin.id);
		else {
			const newIssue = await createWithAdminFx({
				title: signaturePage[signatureType].issueWithAdmin.title,
				description: signaturePage[signatureType].issueWithAdmin.description,
			});
			openChat(newIssue.id);
		}
	};

	return (
		<Container maxWidth='xl'>
			{!blob ? (
				<Spinner size='5rem'/>
			) : (
				<Stack
					sx={{
						width: '100%',
						height: 'calc(100vh - 70px)',
						padding: theme => theme.spacing(0, 2, 3),
						justifyContent: 'space-between',
					}}
					spacing={2.5}
				>
					<AddSignAndSendFile fetchResponsesList={fetchCurrentUserFx}/>
					<Stack sx={{alignItems: 'center'}}>
						<Typography variant='h2'>{signaturePage[signatureType].title}</Typography>
					</Stack>
					<embed src={blob} type="application/pdf" width="100%" height="80%"/>
					<Stack direction='row' spacing={2}>
						<CustomButton onClick={openChatWithAdmin}>
							Открыть чат с администрацией
						</CustomButton>
						<CustomButton onClick={signNDA}>
							{signaturePage[signatureType].buttonContent}
						</CustomButton>
					</Stack>
				</Stack>
			)}
		</Container>
	);
};

export default SignaturePage;
