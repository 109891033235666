/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect} from 'effector';

export const fetchSignedAttachmentsFx = createEffect<void, any>();
