/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {apiRequest, IData} from './index';
import {IFetchFactorsContragentsResponse, IFetchUsersListResponse, IIDRequest, ILongUser} from '../types';

export const fetchFactorsList = async () => {
	const response = await apiRequest<IData<IFetchUsersListResponse>>({
		url: '/api/factor/list',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchDebtorsInFactors = async () => {
	const response = await apiRequest<IData<IFetchFactorsContragentsResponse>>({
		url: '/api/limit/factorsContragents',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchDebtorsForFactor = async ({id}: IIDRequest) => {
	const response = await apiRequest<IData<ILongUser[]>>({
		url: `/api/limit/debtorsForFactor/${id}`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};
