/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {SxProps} from '@mui/system';
import {IconButton, Stack, Tooltip} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {GetIcon} from '../../helpers/other/GetIcon';
import {IIssueWithExpandUsers} from '../../../types';
import {addResponseToIssueFx} from '../../../models/issues';
import AddAttachmentModal from './AddAttachmentModal';

interface IMessageFormProps {
	sx?: SxProps;
	chatInfo: IIssueWithExpandUsers;
	fetchIssue: (timeout: number) => void;
	fetchResponsesList: () => void;
}

const MessageForm = ({
	                     sx,
	                     chatInfo,
	                     fetchIssue,
	                     fetchResponsesList,
                     }: IMessageFormProps): React.ReactElement => {
	const formik = useFormik({
		initialValues: {
			text: '',
		},
		onSubmit: async ({text}) => {
			if (chatInfo && text) {
				await addResponseToIssueFx({id: chatInfo?.id, text});
				fetchResponsesList();
				await setFieldValue('text', '');
			}
		},
	});

	const {handleSubmit, values, handleChange, setFieldValue} = formik;

	return (
		<Stack sx={{py: 2, px: 1, ...sx}}>
			<FormikProvider value={formik}>
				<Form autoComplete='off' noValidate onSubmit={handleSubmit} style={{height: '100%'}}>
					<Stack direction='row' sx={{height: '100%', alignItems: 'center'}}>
						<Stack sx={{mr: 0.5}}>
							<AddAttachmentModal
								chatInfo={chatInfo}
								fetchResponsesList={fetchResponsesList}
							/>
						</Stack>
						{/* eslint-disable-next-line jsx-a11y/no-autofocus */}
						<input autoFocus
						       placeholder='Сообщение...'
						       name='text'
						       value={values.text}
						       onChange={handleChange}
						       style={{
							       width: '100%',
							       height: '100%',
							       marginRight: '5px',
							       backgroundColor: 'transparent',
							       outline: 'none',
							       border: 'none',
							       color: 'inherit',
							       fontSize: '16px',
						       }}
						/>
						<Stack direction='row'>
							<Tooltip title='Отправить' placement='top' arrow>
								<IconButton color='inherit' type='submit'>
									{GetIcon(sendIcon)}
								</IconButton>
							</Tooltip>
							{/*<ChatActions chatInfo={chatInfo} fetchIssue={fetchIssue}/>*/}
						</Stack>
					</Stack>
				</Form>
			</FormikProvider>
		</Stack>
	);
};

export default MessageForm;
