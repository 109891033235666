/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef} from 'react';
import {Stack, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useStore} from 'effector-react';
import Modal, {IModalRef} from 'components/helpers/modals/Modal';
import {IFormikRef} from 'types';
import CustomButton from 'components/styled/customButton/CustomButton';
import {addSupplierDebtorFx} from "../../../models/suppliers";
import AddDebtorForm, {IAddSupplierDebtorFormValues} from "./AddDebtorForm";

interface IAddDebtorModalProps {
	addDebtorFunc: (params: IAddSupplierDebtorFormValues) => void;
}

const AddDebtorModal = ({
	                        addDebtorFunc,
                        }: IAddDebtorModalProps): React.ReactElement => {
	const addDebtorPending = useStore(addSupplierDebtorFx.pending);

	const modalRef = useRef<IModalRef>(null);
	const formikRef = useRef<IFormikRef>(null);

	const handleModalOpen = () => {
		if (modalRef.current) modalRef.current.handleModalOpen();
	};
	const handleModalClose = () => {
		if (modalRef.current) modalRef.current.handleModalClose();
	};

	const submitFunc = async (values: IAddSupplierDebtorFormValues) => {
		addDebtorFunc(values);
		if (formikRef.current) formikRef.current.resetForm();
		handleModalClose();
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
	};

	const modalContent = {
		toggleChildren: (
			<CustomButton onClick={handleModalOpen}>
				Добавить дебитора
			</CustomButton>
		),
		contentChildren: (
			<Stack spacing={2}>
				<Stack>
					<Typography align='center' variant='h4'>
						Добавить дебитора
					</Typography>
				</Stack>
				<AddDebtorForm ref={formikRef} submitFunc={submitFunc}/>
			</Stack>
		),
		actionsChildren: (
			<Stack direction='row' sx={{mt: 1, width: '100%', justifyContent: 'space-between'}}>
				<CustomButton onClick={handleModalClose}>
					Отменить
				</CustomButton>
				<CustomButton
					onClick={handleSubmit}
				>
					Добавить
				</CustomButton>
			</Stack>
		),
	};

	return (
		<Modal
			ref={modalRef}
			toggleChildren={modalContent.toggleChildren}
			contentChildren={modalContent.contentChildren}
			actionsChildren={modalContent.actionsChildren}
		/>
	);
};

export default AddDebtorModal;
