/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useRef} from 'react';
import {Box, Dialog, DialogActions, DialogContent} from '@mui/material';
import {useStore} from "effector-react";
// @ts-ignore
import {ReactComponent as PatchIcon} from '../../../../assets/images/buttons-icons/patch.svg';
// @ts-ignore
import moduleStyles from './ChangeCompanyCardModal.module.scss';
import {closeChangeCompanyCardModal} from "../../../../models/modals/functions";
import CustomDialogTitle from "../../../helpers/other/CustomDialogTitle";
import CustomButton from "../../../styled/customButton/CustomButton";
import CompanyCardEditForm from "../../../features/company-card/CompanyCardEditForm";
import {$modals} from "../../../../models/modals";
import {ICompanyCard, IFormikRef} from "../../../../types";

const styles = moduleStyles as any;

const ChangeCompanyCardModal = ({
	                                companyCard,
	                                fetchUserCompanyCard
                                }: { companyCard: ICompanyCard, fetchUserCompanyCard: Function }): React.ReactElement => {
	const {ChangeCompanyCardModal: modalState} = useStore($modals);
	const formikRef = useRef<IFormikRef>(null);

	const closeModal = () => {
		closeChangeCompanyCardModal();
	};

	const handleSubmit = () => {
		if (formikRef.current) formikRef.current.handleSubmit();
		closeModal();
	};

	return (
		<Dialog
			PaperProps={{sx: {width: '100%', maxWidth: '700px'}}}
			onClose={closeModal}
			aria-labelledby='customized-dialog-title'
			open={modalState.open}
		>
			<CustomDialogTitle onClose={closeModal}>
				Изменить данные карточки компании
			</CustomDialogTitle>
			<DialogContent sx={{p: 0, ml: 5, width: '90%'}}>
				<CompanyCardEditForm ref={formikRef} companyCard={companyCard}
				                     fetchUserCompanyCard={fetchUserCompanyCard}/>
			</DialogContent>
			<DialogActions className={styles.actions}>
				<CustomButton withStartIcon type='submit' onClick={handleSubmit}>
					<PatchIcon/>
					<Box>
						<span>Изменить</span>
					</Box>
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
};

export default ChangeCompanyCardModal;
