/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Icon, IconifyIcon} from '@iconify/react';
// import { ICustomIcon } from '../../slate/panel/icons';

// type GetIconProps = IconifyIcon | ICustomIcon;
type GetIconProps = IconifyIcon;

export const GetIcon = (props: GetIconProps): React.ReactElement => {
	const {width = 24, height = 24} = props;
	return <Icon style={{width: '24px', height: '24px'}} icon={props} width={width} height={height}/>;
};
