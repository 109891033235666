/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useState} from 'react';
import {Box, Dialog, DialogActions, DialogContent, Stack, Typography,} from '@mui/material';
import {Icon} from '@iconify/react';
import fileIcon from '@iconify/icons-eva/file-fill';
import infoIcon from "@iconify-icons/gg/info";
import CustomButton from 'components/styled/customButton/CustomButton';
import GrowTransition from '../../helpers/transitions/GrowTransition';
import {IDebtor} from "../../../models/suppliers";
import Debtors from "../../pages/profile/debtors/Debtors";
import {GetIcon} from "../../helpers/other/GetIcon";
//@ts-ignore
import {ReactComponent as InfoIcon} from "../../../assets/images/files-actions/info.svg";

interface IDebtorsModalProps {
	debtorsFromProps: IDebtor[];
	updated: boolean;
}

const DebtorsModal = ({debtorsFromProps, updated}: IDebtorsModalProps): React.ReactElement => {
	const [modalOpen, setModalOpen] = useState(false);

	const handleModalOpen = () => setModalOpen(true);
	const handleModalClose = () => setModalOpen(false);

	return (
		<>
			<Stack direction='row' sx={{my: 0.3}}>
				<Box
					sx={{
						width: 30,
						height: 30,
						backgroundColor: '#8cd3ff',
						borderRadius: '50%',
						display: 'grid',
						placeItems: 'center',
						mr: 0.5,
					}}
				>
					<Icon icon={fileIcon} width={18} height={18}/>
				</Box>
				<Stack sx={{mr: 5}}>
					<Typography variant='body2' sx={{fontSize: '14px', lineHeight: 1.3}}>
						Список дебиторов
					</Typography>
					<CustomButton variant='exception1' onClick={handleModalOpen}>
						Открыть
					</CustomButton>
				</Stack>
				{updated && (
					<Stack sx={{m: 'auto 0px auto auto'}} direction='row'>
						<InfoIcon/>
						<Typography sx={{mt: 0.5,ml: 0.5, fontSize: '1rem', fontWeight: 500}} color='primary' variant='body1'>
							Данные обновлены
						</Typography>
					</Stack>
				)}
			</Stack>
			<Dialog
				PaperProps={{sx: {maxWidth: '75vw', width: '75vw', position: 'absolute', top: 0}}}
				onBackdropClick={handleModalClose}
				TransitionComponent={GrowTransition}
				open={modalOpen}
			>
				<DialogContent sx={{py: 2.5}}>
					<Debtors readonly debtors={debtorsFromProps}/>
				</DialogContent>
				<DialogActions sx={{mt: 0}}>
					<Stack direction='row' spacing={1} sx={{width: '100%'}}>
						<CustomButton onClick={handleModalClose}>
							Закрыть
						</CustomButton>
					</Stack>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DebtorsModal;
