/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

// @ts-ignore
import {ReactComponent as FactorsIcon} from 'assets/images/tabs/factors.svg';
// @ts-ignore
import {ReactComponent as FactorsActiveIcon} from 'assets/images/tabs/active/factors.svg';
// @ts-ignore
import {ReactComponent as DebitorsIcon} from 'assets/images/tabs/debtors.svg';
// @ts-ignore
import {ReactComponent as DebitorsActiveIcon} from 'assets/images/tabs/active/debtors.svg';
// @ts-ignore
import {ReactComponent as FinancingIcon} from 'assets/images/tabs/financing.svg';
// @ts-ignore
import {ReactComponent as FinancingActiveIcon} from 'assets/images/tabs/active/financing.svg';
// @ts-ignore
import {ReactComponent as SuppliersIcon} from 'assets/images/tabs/suppliers.svg';
// @ts-ignore
import {ReactComponent as SuppliersActiveIcon} from 'assets/images/tabs/active/suppliers.svg';
// @ts-ignore
import {ReactComponent as RequestForFinancingIcon} from 'assets/images/tabs/request-for-financing.svg';
// @ts-ignore
import {ReactComponent as RequestForFinancingActiveIcon} from 'assets/images/tabs/active/request-for-financing.svg';
import {Tab} from './TableContainer.types';

export const initialTabs: Array<Omit<Tab, 'active'>> = [
	{
		id: 'financing',
		icon: <FinancingIcon/>,
		title: 'Финансирования',
		activeIcon: <FinancingActiveIcon/>,
	},
	{
		id: 'factors',
		title: 'Факторы',
		icon: <FactorsIcon/>,
		activeIcon: <FactorsActiveIcon/>,
	},
	{
		id: 'debtors',
		icon: <DebitorsIcon/>,
		title: 'Дебиторы',
		activeIcon: <DebitorsActiveIcon/>,
	},
	{
		id: 'suppliers',
		icon: <SuppliersIcon/>,
		title: 'Поставщики',
		activeIcon: <SuppliersActiveIcon/>,
	},
	{
		id: 'myDebtors',
		icon: <DebitorsIcon/>,
		title: 'Мои Дебиторы',
		activeIcon: <DebitorsActiveIcon/>,
	},
	{
		id: 'shipments',
		icon: <DebitorsIcon/>,
		title: 'Поставки',
		activeIcon: <DebitorsActiveIcon/>,
	},
	{
		id: 'incomeFiles',
		icon: <DebitorsIcon/>,
		title: 'Входящие файлы',
		activeIcon: <DebitorsActiveIcon/>,
	},
	{
		id: 'outcomeFiles',
		icon: <DebitorsIcon/>,
		title: 'Исходящие файлы',
		activeIcon: <DebitorsActiveIcon/>,
	},
];
