/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Card, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';

interface INotFoundCardProps {
	name: string;
	variant?: Variant;
}

const NotFoundCard = ({name, variant}: INotFoundCardProps): React.ReactElement => (
	<Card sx={{m: '15px', p: 2}}>
		<Typography variant={variant} align='center'>
			{name} отсутствуют
		</Typography>
	</Card>
);

NotFoundCard.defaultProps = {
	variant: 'h4',
};

export default NotFoundCard;
