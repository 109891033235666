/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Breakpoint, useMediaQuery, useTheme} from '@mui/material';

type DirEnum = 'down' | 'up';

interface IMHiddenProps {
	breakpoint: Breakpoint;
	dir: DirEnum;
	children: React.ReactElement;
}

const MHidden = ({breakpoint, dir, children}: IMHiddenProps): React.ReactElement | null => {
	const theme = useTheme();

	const hiddenUp = useMediaQuery(theme.breakpoints.up(breakpoint));
	const hiddenDown = useMediaQuery(theme.breakpoints.down(breakpoint));

	if (dir === 'down') {
		return hiddenDown ? null : children;
	}

	if (dir === 'up') {
		return hiddenUp ? null : children;
	}

	return null;
};

export default MHidden;
