/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect} from 'effector';
import {IFetchLimitListResponse} from '../../types';

export const fetchLimitListFx = createEffect<void, IFetchLimitListResponse>();
