/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

// eslint-disable-next-line max-classes-per-file
import React, {useEffect, useState} from 'react';
import {Box} from "@mui/system";
import {Stack, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import './FinancingPage.css';
import {DateTime} from "luxon";
import {useStore} from "effector-react";
import CustomButton from 'components/styled/customButton/CustomButton';
import {fillFinancingProcessFeed, fillFinancingRequestFeed} from 'components/styled/interactionFeed/feeds';
import {downloadResponseFileFx, fetchIssueFx, fetchResponsesListFx} from "../../models/issues";
import Shipments from "../../components/pages/profile/shipments/Shipments";
import {IIssueFileResponse, IIssueShipmentsResponse, IIssueWithExpandUsers, ISmallFile, UserType} from "../../types";
import CompleteIssueModal from "../../components/features/chat/chat-actions/CompleteIssueModal";
import {$user} from "../../models/auth";
import InteractionFeed from "../../components/styled/interactionFeed/InteractionFeed";
import FilesContainer from "../../components/pages/profile/user-info/FilesContainer";
import {IssueType} from "../../types/enum/issueType";

const FinancingPage = (): React.ReactElement => {
	const navigate = useNavigate();
	const {id = ''} = useParams();
	const {user} = useStore($user);
	const [issue, setIssue] = useState<IIssueWithExpandUsers>(null as unknown as IIssueWithExpandUsers);
	const [childIssue, setChildIssue] = useState<IIssueWithExpandUsers>(null as unknown as IIssueWithExpandUsers);
	const [files, setFiles] = useState<IIssueFileResponse[]>(null as unknown as IIssueFileResponse[])
	const [shipments, setShipments] = useState<any>([]);

	const fetchIssue = async () => {
		const response = await fetchIssueFx({issueId: id});
		setIssue(response);
		if (response.childIssue && user?.type !== UserType.SUPPLIER) {
			const childResponse = await fetchIssueFx({issueId: response.childIssue});
			setChildIssue(childResponse);
		}
	}

	const fetchResponses = async () => {
		const response = await fetchResponsesListFx({issueId: id});
		setFiles(response.docs.filter(chatResponse => chatResponse.type.toLowerCase().includes('file')) as IIssueFileResponse[]);
		setShipments((response.docs.filter(chatResponse => chatResponse.type === 'shipments')[0] as IIssueShipmentsResponse).shipments);
	}

	const refresh = () => {
		const fetchPromises = [fetchIssue(), fetchResponses()];
		Promise.all(fetchPromises).then();
	}

	useEffect(() => {
		refresh()
	}, [id]);

	const downloadResponseFile = (item: IIssueFileResponse) =>
		downloadResponseFileFx({
			issueId: issue.id,
			responseId: item.id,
			fileId: item.file._id,
		});

	return (
		<Box sx={{height: '100%'}}>
			<Stack sx={{p: 1}} direction='row'>
				<CustomButton onClick={() => navigate(-1)}>Вернуться</CustomButton>
				{issue && <Typography sx={{m: 0, ml: 2, pt: 0, lineHeight: 1.2}} variant='h2'>
					{`Запрос финансирования от ${issue.author?.name} (${DateTime.fromISO(issue.createdAt).toFormat('dd.MM.yyyy')})`}
                </Typography>}
			</Stack>
			{user && ((issue && issue.type === IssueType.REQUEST_FINANCING && issue.additionalStepsInProgress && issue.additionalStepsDone === false && [UserType.DEBTOR, UserType.FACTOR].includes(user?.type!)) ? childIssue : issue) && shipments &&
                <CompleteIssueModal
                    chatInfo={(issue.type === IssueType.REQUEST_FINANCING && issue.additionalStepsInProgress && issue.additionalStepsDone === false && [UserType.DEBTOR, UserType.FACTOR].includes(user?.type!)) ? childIssue : issue}
                    fetchIssue={fetchIssue} fetchResponsesList={fetchResponses}/>}
			{user && issue && shipments &&
                <InteractionFeed shipments={shipments} issue={issue} refresh={refresh}
                                 feeds={[fillFinancingRequestFeed(issue, user), fillFinancingProcessFeed(issue, user)]}/>}
			{issue && shipments && <Shipments readonly shipments={shipments} originalData={issue.savedData}/>}
			{files && <FilesContainer readonly files={files.map(fileResponse => ({
				...fileResponse.file,
				id: fileResponse.id,
				file: {_id: fileResponse.file._id}
			})) as unknown as ISmallFile[]} fileDownload={downloadResponseFile}/>}
		</Box>
	);
};

export default FinancingPage;
