/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect} from 'react';
import {Box, Card, Divider, Stack, Typography} from '@mui/material';
import {useStore} from 'effector-react';
import {$user, fetchCurrentUserFx} from 'models/auth';
// @ts-ignore
import moduleStyles from './Header.module.scss';
import NotificationsDropdown from './NotificationsDropdown';
// @ts-ignore
import { ReactComponent as AppLogoIcon } from '../../../assets/images/logo.svg';
import ProfileDropdown from "./ProfileDropdown";

const styles = moduleStyles as any;

const userType = {
	'supplier': 'Поставщик',
	'factor': 'Фактор',
	'debtor': "Дебитор"
}

const Header = () => {
	const {user} = useStore($user);

	useEffect(() => {
		if (!user || !('type' in user)) fetchCurrentUserFx().then();
	}, []);

	return (
		<Box className={styles.container}>
			<Card className={styles.card}>
				<Box>
					<Box className='d-flex-align-center'>
						<Box className={styles.logo}>
							<AppLogoIcon/>
						</Box>
						<Box className={styles.userRole}>
							<Divider orientation='vertical'/>
							{/*// @ts-ignore*/}
							<Typography>{userType[user?.type]}</Typography>
						</Box>
					</Box>
					<Stack direction='row' alignItems='center' spacing={2.5}>
						<Box className={styles.notificationIcon}>
							<NotificationsDropdown/>
						</Box>
						<Box className={styles.userLogoIcon}>
							<ProfileDropdown/>
						</Box>
					</Stack>
				</Box>
			</Card>
		</Box>
	);
};

export default Header;
