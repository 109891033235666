/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import {ruRU} from '@mui/material/locale';
import {Children} from 'shared/types';
import breakpoints from './Breakpoints';
import components from './Override';
//@ts-ignore
import variables from '../assets/styles/_mui-variables.scss';

const baseTheme = {
	palette: {
		primary: {
			main: variables.primaryMain,
			light: variables.primaryLight,
			dark: variables.primaryDark,
		},
	},
	breakpoints: {
		values: breakpoints,
	},
	components,
};

interface IThemeConfigProps {
	children: Children;
}

const ThemeConfig = ({children}: IThemeConfigProps): React.ReactElement => {
	const theme = createTheme(baseTheme, ruRU);
	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeConfig;
