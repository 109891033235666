/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {motion} from 'framer-motion';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Button, Container, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {get} from 'local-storage';
import {USER_TOKEN} from 'shared/variables';
// @ts-ignore
import {ReactComponent as Illustration404} from 'assets/images/illustration_404.svg';

const RootStyle = styled(Box)(({theme}) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
	paddingTop: theme.spacing(15),
	paddingBottom: theme.spacing(10),
}));

const ErrorPage = (): React.ReactElement => (
	<RootStyle>
		<Container>
			<Box sx={{maxWidth: 480, margin: 'auto', textAlign: 'center'}}>
				<motion.div
					initial={{scale: 0.5}}
					animate={{scale: 1}}
					transition={{duration: 0.3}}
				>
					<Typography variant='h3' paragraph>
						Страница не существует
					</Typography>
					<Typography sx={{color: 'text.secondary'}}>
						Запрошенная страница не существует.
					</Typography>
					<Typography sx={{color: 'text.secondary'}}>
						Проверьте правильность введённого адреса.
					</Typography>
				</motion.div>

				<motion.div animate={{scale: [0.5, 1.2, 1]}} transition={{duration: 0.5}}>
					<Box
						component={Illustration404}
						sx={{width: 'auto', height: 260, mx: 'auto', my: {xs: 5, sm: 10}}}
					/>
				</motion.div>

				<motion.div
					initial={{scale: 0.5}}
					animate={{scale: 1}}
					transition={{duration: 0.3}}
				>
					<Button
						to={!get(USER_TOKEN) ? '/login' : '/dashboard'}
						size='large'
						variant='contained'
						component={RouterLink}
					>
						Вернуться
					</Button>
				</motion.div>
			</Box>
		</Container>
	</RootStyle>
);

export default ErrorPage;
