/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

export const signaturePage = {
	nda: {
		title: 'Подпись документа НДА',
		buttonContent: 'Подписать документ НДА',
		issueWithAdmin: {
			title: 'Изменения к документу НДА',
			description: 'Обсуждение изменений по документу НДА',
		},
		slateValue: [
			{
				type: 'абзац',
				children: [
					{
						text: 'Документ НДА',
					},
				],
			},
		],
	},
};
