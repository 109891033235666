/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Box, Dialog, DialogContent, Divider, IconButton, Stack, Tooltip, Typography,} from '@mui/material';
import sendIcon from '@iconify-icons/gg/arrow-right-o';
import {Form, FormikProvider, useFormik} from 'formik';
import {Icon} from '@iconify/react';
import fileIcon from '@iconify/icons-eva/file-fill';
import {
	addAttachedSignature,
	Certificate,
	createAttachedSignature,
	getAllUserCertificates
} from "@gaarutyunov/crypto-pro-fork";
import {DateTime} from "luxon";
import {createEvent, createStore, Effect} from "effector";
import {useStore} from "effector-react";
import GrowTransition from '../../helpers/transitions/GrowTransition';
import {GetIcon} from '../../helpers/other/GetIcon';
import {formatBytes} from '../../../shared/functions';
import FormikAutocomplete from "../../helpers/forms/FormikAutocomplete";
import {openErrorSnackbar} from "../../helpers/snackbars/ErrorSnackbar";
import {addResponseToIssueFx} from "../../../models/issues";

export const setOpenAddSignAndSendFileModal = createEvent<{
	signFuncName: string,
	apiFunc: Effect<any, any>,
	open: boolean,
	certs: Certificate[],
	file: File,
	field: string,
	issueId: string,
	issueResponseId: string,
	attachmentId: string,
	showInput: boolean;
}>();
export const $addSignAndSendFile = createStore({
	signFuncName: '',
	apiFunc: null as unknown as Effect<any, any>,
	open: false,
	certs: [] as Certificate[],
	file: null as unknown as File,
	field: '',
	issueId: '',
	issueResponseId: '',
	attachmentId: '',
	showInput: true
}).on(setOpenAddSignAndSendFileModal, (state, payload) => (payload));

export const handleAddSignAndSendFileModalOpen = (signFuncName: string, apiFunc: Effect<any, any>, file: File, field: string, issueId: string, issueResponseId: string, attachmentId: string, showInput = true) => {
	getAllUserCertificates().then(certs => setOpenAddSignAndSendFileModal({
		signFuncName,
		apiFunc,
		open: true,
		certs,
		file,
		field,
		issueId,
		issueResponseId,
		attachmentId,
		showInput
	}));
};
export const handleAddSignAndSendFileModalClose = () =>
	setOpenAddSignAndSendFileModal({
		signFuncName: '',
		apiFunc: null as unknown as Effect<any, any>,
		open: false,
		certs: [],
		file: null as unknown as File,
		field: '',
		issueId: '',
		issueResponseId: '',
		attachmentId: '',
		showInput: true
	});

const AddSignAndSendFile = ({fetchResponsesList}: { fetchResponsesList: () => void; }): React.ReactElement => {
	const {
		signFuncName,
		apiFunc,
		open,
		certs,
		file,
		field,
		issueId,
		issueResponseId,
		attachmentId,
		showInput
	} = useStore($addSignAndSendFile);

	const formik = useFormik<{ cert: null | Certificate, message: string }>({
		initialValues: {
			cert: null,
			message: ''
		},
		onSubmit: async ({cert, message}) => {
			if (file) {
				let signedData = await file.text();
				try {
					if (cert && process.env.REACT_APP_USE_DIGITAL_SIGNATURE === 'true') {
						if (signFuncName === 'create')
							signedData = await createAttachedSignature(cert?.thumbprint, await file.arrayBuffer());
						else
							signedData = await addAttachedSignature(cert?.thumbprint, await file.text());
					}
					// eslint-disable-next-line no-new
					const signedFile = new File([signedData], signFuncName === 'create' ? `${file.name}.sig` : file.name, {
						type: "multipart/signed",
					});
					const formData = new FormData();
					formData.append('file', signedFile);
					formData.append('field', field);
					await apiFunc({issueId, issueResponseId, attachmentId, data: formData});
					if (message)
						await addResponseToIssueFx({id: issueId, text: message});
					fetchResponsesList();
					handleAddSignAndSendFileModalClose();
				} catch (e) {
					let m = 'Неизвестная ошибка с ЭЦП'
					if (e instanceof Error) m = e.message
					openErrorSnackbar(m);
				}
			}
		},
	});

	const {handleSubmit, getFieldProps} = formik;

	return (
		<Dialog
			PaperProps={{sx: {width: showInput ? '850px' : 'auto'}}}
			onBackdropClick={handleAddSignAndSendFileModalClose}
			TransitionComponent={GrowTransition}
			open={open}
		>
			<DialogContent sx={{p: 0, pr: 3, position: 'relative'}}>
				<FormikProvider value={formik}>
					<Form
						autoComplete='off'
						noValidate
						onSubmit={handleSubmit}
						style={{height: '100%'}}
					>
						<Stack
							sx={{
								pt: 2,
								pb: 1,
								justifyContent: 'space-between',
							}}
						>
							{process.env.REACT_APP_USE_DIGITAL_SIGNATURE === 'true' &&
							<FormikAutocomplete textFieldProps={{label: 'Сертификат'}} sx={{p: 1, pt: 0}}
							                    getOptionLabel={option => `${option.name} (действителен до ${DateTime.fromISO(option.validTo).toFormat('dd.MM.yyyy')})`}
							                    name='cert' options={certs}/>}
							<Stack sx={{pl: 2.5, pr: 1}}>
								<Stack direction='row' sx={{my: 0.3, alignItems: 'center'}}>
									<Box
										sx={{
											width: 40,
											height: 40,
											backgroundColor: '#0088cc',
											borderRadius: '50%',
											display: 'grid',
											placeItems: 'center',
											mr: 1,
										}}
									>
										<Icon icon={fileIcon} width={24} height={24}/>
									</Box>
									<Stack sx={{overflow: 'hidden'}}>
										<Typography sx={{fontSize: '1.25rem', overflow: 'hidden',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis'}} variant='body1'>{file?.name}</Typography>
										<Typography
											variant='caption'
											sx={{fontSize: '1rem', lineHeight: 1.3}}
										>
											{formatBytes(file?.size)}
										</Typography>
									</Stack>
								</Stack>
							</Stack>
							<Divider sx={{mt: 2, mb: 0.5}}/>
							<Stack
								direction='row'
								sx={{
									height: '100%',
									alignItems: 'center',
									pl: 2.5,
									pr: 1,
								}}
							>
								{showInput && <input
									{...getFieldProps('message')}
									placeholder='Сообщение...'
									style={{
										width: '100%',
										height: '100%',
										marginRight: '5px',
										backgroundColor: 'transparent',
										outline: 'none',
										border: 'none',
										color: 'inherit',
										fontSize: '16px',
									}}
								/>}
								{!showInput && <Typography sx={{fontSize: '1em'}}>
									Со всем согласен
								</Typography>}
								<Stack>
									<Tooltip title='Отправить' placement='top' arrow>
										<IconButton color='inherit' type='submit'>
											{GetIcon(sendIcon)}
										</IconButton>
									</Tooltip>
								</Stack>
							</Stack>
						</Stack>
					</Form>
				</FormikProvider>
			</DialogContent>
		</Dialog>
	);
};

export default AddSignAndSendFile;
