/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {
	addDiadocAccount,
	addFile,
	addFileToSlotCompanyCard,
	deleteFile,
	deleteFileFromSlotCompanyCard,
	deleteNotification,
	fetchAnotherUserCompanyCard,
	fetchAnotherUserInfo,
	fetchNDA,
	fetchProfileFiles,
	fetchUserCompanyCard,
	fetchUserInfo,
	getFile,
	getFileFromSlotCompanyCard,
	patchFile,
	patchUserCompanyCard,
	patchUserInfo,
} from 'api';
import {
	addDiadocAccountFx,
	addFileFx,
	addFileToSlotCompanyCardFx,
	deleteFileFromSlotCompanyCardFx,
	deleteFileFx,
	deleteNotificationFx,
	fetchAnotherUserCompanyCardFx,
	fetchAnotherUserInfoFx,
	fetchNDAFx,
	fetchProfileFilesFx,
	fetchUserCompanyCardFx,
	fetchUserInfoFx,
	getFileFromSlotCompanyCardFx,
	getFileFx,
	patchFileFx,
	patchUserCompanyCardFx,
	patchUserInfoFx,
} from './index';

fetchUserInfoFx.use(fetchUserInfo);
fetchAnotherUserInfoFx.use(fetchAnotherUserInfo);
patchUserInfoFx.use(patchUserInfo);
addDiadocAccountFx.use(addDiadocAccount);
deleteNotificationFx.use(deleteNotification);
fetchProfileFilesFx.use(fetchProfileFiles);
getFileFx.use(getFile);
addFileFx.use(addFile);
patchFileFx.use(patchFile);
deleteFileFx.use(deleteFile);
fetchUserCompanyCardFx.use(fetchUserCompanyCard);
fetchAnotherUserCompanyCardFx.use(fetchAnotherUserCompanyCard);
patchUserCompanyCardFx.use(patchUserCompanyCard);
addFileToSlotCompanyCardFx.use(addFileToSlotCompanyCard);
getFileFromSlotCompanyCardFx.use(getFileFromSlotCompanyCard);
deleteFileFromSlotCompanyCardFx.use(deleteFileFromSlotCompanyCard);
fetchNDAFx.use(fetchNDA)
