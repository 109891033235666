/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {createEffect, Effect, merge} from 'effector';
import {IFetchFactorsContragentsResponse, IFetchUsersListResponse, IIDRequest, ILongUser} from '../../types';

export const fetchFactorsListFx = createEffect<void, IFetchUsersListResponse>();
export const fetchDebtorsInFactorsFx = createEffect<void, IFetchFactorsContragentsResponse>();
export const fetchDebtorsForFactorFx = createEffect<IIDRequest, ILongUser[]>();

export const $factorsEffectsFailData = merge(
	[fetchFactorsListFx, fetchDebtorsInFactorsFx].map((item: Effect<any, any>) => item.failData)
);
