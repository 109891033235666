/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import * as Yup from 'yup';
import React, {forwardRef, useImperativeHandle} from 'react';
import {Form, FormikProvider, useFormik} from 'formik';
import {Stack, TextField} from '@mui/material';
import {ISmallFile} from 'types';

export interface IEditFileFormValues {
	name: string;
	description: string;
}

interface IEditFileFormProps {
	submitFunc: (values: IEditFileFormValues) => void;
	file: ISmallFile;
}

const EditFileForm = forwardRef(({submitFunc, file}: IEditFileFormProps, ref) => {
	const EditFileFormSchema = Yup.object().shape({
		name: Yup.string().required('Поле является обязательным'),
		description: Yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			name: file.filename || '',
			description: file?.metadata?.description || '',
		},
		validationSchema: EditFileFormSchema,
		onSubmit: values => submitFunc(values),
	});

	const {errors, touched, handleSubmit, getFieldProps} = formik;

	useImperativeHandle(ref, () => ({...formik}));

	return (
		<FormikProvider value={formik}>
			<Form autoComplete='off' noValidate onSubmit={handleSubmit}>
				<Stack spacing={2.5}>
					<TextField
						fullWidth
						label='Имя файла'
						{...getFieldProps('name')}
						error={Boolean(touched.name && errors.name)}
						helperText={touched.name && errors.name}
					/>
					<TextField
						fullWidth
						label='Описание'
						{...getFieldProps('description')}
						error={Boolean(touched.description && errors.description)}
						helperText={touched.description && errors.description}
					/>
				</Stack>
			</Form>
		</FormikProvider>
	);
});

export default EditFileForm;
