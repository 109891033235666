/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import clsx from 'clsx';
import {Box, Card, Stack, Tab, Typography} from '@mui/material';
// @ts-ignore
import {openChat} from 'components/features/chat/Chat';
// @ts-ignore
import CompleteIssueModal, {
	handleCompleteIssueModalOpen
} from 'components/features/chat/chat-actions/CompleteIssueModal';
// @ts-ignore
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {useStore} from "effector-react";
// @ts-ignore
import {ReactComponent as PendingIcon} from '../../../assets/images/interaction-feed/pending.svg';
// @ts-ignore
import {ReactComponent as CompleteIcon} from '../../../assets/images/interaction-feed/complete.svg';
// @ts-ignore
import {ReactComponent as ArrowIcon} from '../../../assets/images/interaction-feed/arrow.svg';
// @ts-ignore
import moduleStyles from './InteractionFeed.module.scss';
import {Step} from './InteractionFeed.types';
import CustomButton from "../customButton/CustomButton";
import {IShipment} from "../../../models/shipments";
import {IIssueWithExpandUsers, UserType} from "../../../types";
import {$user} from "../../../models/auth";

const styles = moduleStyles as any;

const InteractionFeed = ({
	                         shipments,
	                         issue,
	                         feeds,
	                         refresh,
                         }: { shipments: IShipment[], issue: IIssueWithExpandUsers, feeds: Array<Array<Step>>, refresh: () => void }): React.ReactElement => {
	const [value, setValue] = React.useState(issue.finished ? '1' : '0');
	const {user} = useStore($user);
	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};
	return (
		<Card className={styles.card}>
			<TabContext value={value}>
				<Stack direction='row'>
					<Box className={styles.title}>
						<Typography>Лента взаимодействий</Typography>
					</Box>
					<TabList sx={{margin: 'auto', paddingRight: '3rem'}} onChange={handleChange} centered>
						<Tab sx={{
							backgroundColor: value === '0' ? '#e6e6e6' : 'white',
							borderTopLeftRadius: '25px',
							borderBottomLeftRadius: '25px',
							fontFamily: 'SF Pro Display, sans-serif'
						}} label="Процесс финансирования" value="0"/>
						<Tab sx={{
							backgroundColor: value === '1' ? '#e6e6e6' : 'white',
							borderTopRightRadius: '25px',
							borderBottomRightRadius: '25px',
							fontFamily: 'SF Pro Display, sans-serif'
						}} label="Процесс возврата" value="1" disabled={!issue.finished}/>
					</TabList>
					<CustomButton style={{marginRight: '3rem'}} onClick={() => openChat(issue.id)}>Открыть
						чат</CustomButton>
				</Stack>
				{feeds.map((feed, idx) =>
					<TabPanel sx={{pr: 0, pl: 0, pb: 0}} value={idx.toString(10)}>
						<Box className={styles.list}>
							{feed.map((step, i) => (
								<Box key={step.id}>
									<Stack direction='row'>
										<Box sx={{position: 'relative', pr: '7px', pt: '9px'}}>
											<Box
												className={clsx(
													styles.stepContainer,
													step.complete && styles.stepContainerComplete
												)}
											>
												<Box className={styles.stepIcon}>
													{step.complete ? <CompleteIcon/> : <PendingIcon/>}
												</Box>
												<Stack alignItems='center'>
													<Typography component='span'>{step.id}</Typography>
													<Typography>шаг</Typography>
												</Stack>
											</Box>
										</Box>
										<Stack sx={{ml: 0.5, mt: 1, justifyContent: 'center'}}>
											{i !== feed.length - 1 && <ArrowIcon/>}
											{step.action && <CustomButton
                                                onClick={issue.additionalStepsDone === false && ([UserType.DEBTOR, UserType.FACTOR].includes(user?.type!) && 'childIssue' in issue) ? () => openChat(issue.childIssue!, refresh) : () => handleCompleteIssueModalOpen(shipments)}>Выполнить</CustomButton>}
										</Stack>
									</Stack>
									<Box className={clsx(styles.stepTitle, step.complete && styles.stepTitleComplete)}>
										<Typography>{step.title}</Typography>
									</Box>
								</Box>
							))}
						</Box>
					</TabPanel>
				)}
			</TabContext>
		</Card>
	);
}

export default InteractionFeed;
