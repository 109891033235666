/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {Link as RouterLink, useParams} from 'react-router-dom';
import {Box, Button, Container, Typography} from '@mui/material';
import {styled} from '@mui/system';
import {get} from 'local-storage';
import {USER_TOKEN} from 'shared/variables';
// @ts-ignore
import {ReactComponent as JobDone} from 'assets/images/JobDone.svg';
import {patchUserInfoFx} from "../models/profile";

const RootStyle = styled(Box)(({theme}) => ({
	display: 'flex',
	minHeight: '100%',
	alignItems: 'center',
}));

const TgAuth = (): React.ReactElement => {
	const {chatId} = useParams();

	useEffect(() => {
		if (chatId)
			patchUserInfoFx({data: {tgChat: chatId}});
	}, []);

	return (
		<RootStyle>
			<Container>
				<Box sx={{margin: 'auto', textAlign: 'center'}}>
					<motion.div
						initial={{scale: 0.5}}
						animate={{scale: 1}}
						transition={{duration: 0.3}}
					>
						<Typography variant='h3' paragraph>
							{chatId ? 'Ваш телеграм аккаунт успешно привязан к Платформе.' : 'Возникла ошибка. Обратитесь к Администрации.'}
						</Typography>
					</motion.div>

					<motion.div animate={{scale: [0.5, 1.2, 1]}} transition={{duration: 0.5}}>
						<Box
							component={JobDone}
							sx={{width: 'auto', height: 460, mx: 'auto', my: {xs: 5, sm: 10}}}
						/>
					</motion.div>

					<motion.div
						initial={{scale: 0.5}}
						animate={{scale: 1}}
						transition={{duration: 0.3}}
					>
						<Button
							to={!get(USER_TOKEN) ? '/login' : '/dashboard'}
							size='large'
							variant='contained'
							component={RouterLink}
						>
							Вернуться
						</Button>
					</motion.div>
				</Box>
			</Container>
		</RootStyle>
	);
}

export default TgAuth;
