/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {Stack, Typography} from '@mui/material';
import React from 'react';
import {DateTime} from 'luxon';

interface IDateBlockProps {
	date: DateTime;
}

const DateBlock = ({date}: IDateBlockProps): React.ReactElement => (
	<Stack sx={{alignItems: 'center', py: 1}}>
		<Typography sx={{fontSize: '1.25rem'}}>
			<strong>{date.toLocaleString(DateTime.DATE_FULL)}</strong>
		</Typography>
	</Stack>
);

export default DateBlock;
