/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import React from 'react';
import {Card, Stack, Typography} from '@mui/material';
import NotFoundCard from 'components/helpers/not-found/NotFoundCard';
import PaginationTable from 'components/helpers/table/PaginationTable';
import CustomButton from 'components/styled/customButton/CustomButton';
import {DateTime} from "luxon";
import {IShipment} from "../../../../models/shipments";

interface IShipments {
	selectable?: boolean;
	readonly?: boolean;
	shipments: IShipment[];
	returnFunc?: () => void;
	originalData?: IShipment[];
}

const Shipments = ({
	                   selectable = false,
	                   readonly = false,
	                   shipments,
	                   returnFunc = undefined,
	                   originalData = shipments
                   }: IShipments): React.ReactElement => (<Card sx={{p: 3}}>
	{!readonly && (<CustomButton onClick={returnFunc}>Вернуться</CustomButton>)}
	<Stack
		sx={{
			mb: 3,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			marginLeft: '-8rem'
		}}
	>
		<Typography variant='h2'>Поставки</Typography>
	</Stack>
	{!shipments?.length ? (
		<NotFoundCard name='Поставки'/>
	) : (
		<PaginationTable
			selectable={selectable}
			options={[
				{
					key: 'monetaryClaimHuman',
					title: 'Сумма уступленного денежного требования, руб.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'firstPaymentHuman',
					title: 'Размер первого платежа',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'agreementPaymentDate',
					title: 'Дата оплаты по соглашению',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'actShipmentDate',
					title: 'Дата отгрузки согласно накладной/акта и др.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'actNumber',
					title: 'Номер накладной/акта и др.',
					sx: {textAlign: 'center'},
					isPopover: true,
				},
				{
					key: 'status',
					title: 'Статус поставки',
					sx: {textAlign: 'center'},
					isPopover: true,
					active: !readonly
				},
			]} rows={originalData.map(shipment => ({
			...shipment,
			id: shipment._id,
			disabled: shipments?.filter(pickedShipment => pickedShipment.actNumber === shipment.actNumber).length === 0,
			actShipmentDate: DateTime.fromISO(shipment.actShipmentDate).toFormat('dd.MM.yyyy'),
			agreementPaymentDate: DateTime.fromISO(shipment.agreementPaymentDate).toFormat('dd.MM.yyyy'),
			// eslint-disable-next-line no-nested-ternary
			status: shipment.status === null ? 'Готова к финансированию' : (shipment.status ? 'Профинансирована' : 'В процессе финансирования')
		}))}/>
	)}
</Card>);

export default Shipments;
