/*
 * Авторство (c) 2022.
 * Разработчик - Стрельцов Алексей.
 * РП - Маркин Николай.
 * Все права принадлежат ООО "БРК".
 */

import {updateNotifications} from 'models/notifications';
import {apiRequest, IData} from './index';
import {IFetchUsersListResponse} from '../types';
import {
	IShipment,
	IShipmentAddFile,
	IShipmentAddParams,
	IShipmentGetFile,
	IShipmentWithFiles
} from "../models/shipments";
import {IDebtor, IDebtorAddParams} from "../models/suppliers";

export const fetchSuppliersList = async () => {
	const response = await apiRequest<IData<IFetchUsersListResponse>>({
		url: '/api/supplier/list',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchSupplierDebtorsList = async () => {
	const response = await apiRequest<IData<IDebtor[]>>({
		url: '/api/supplier/debtor/list',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchSupplierFactorsList = async () => {
	const response = await apiRequest<IData<IFetchUsersListResponse>>({
		url: '/api/supplier/factor/list',
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addSupplierDebtor = async (data: IDebtorAddParams) => {
	const response = await apiRequest<IData<IDebtor>>({
		url: '/api/supplier/debtor/add',
		method: 'POST',
		data
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const fetchSupplierDebtorShipmentsList = async (params: { id: string }) => {
	const response = await apiRequest<IData<IShipmentWithFiles[]>>({
		url: `/api/supplier/debtor/${params.id}/shipment/list`,
		method: 'GET',
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addShipment = async (params: { debtorId: string, data: IShipmentAddParams }) => {
	const response = await apiRequest<IData<IShipment>>({
		url: `/api/supplier/debtor/${params.debtorId}/shipment/add`,
		method: 'POST',
		data: params.data
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addShipments = async (params: { debtorId: string, data: (IShipmentAddParams & ({ debtorInn: string }))[] }) => {
	const response = await apiRequest<IData<IShipment[]>>({
		url: `/api/supplier/debtor/${params.debtorId}/shipment/multiple`,
		method: 'POST',
		data: {data: params.data}
	});
	updateNotifications(response.data.notifications);
	return response.data.data;
};

export const addFileToShipment = async (params: { debtorId: string, data: IShipmentAddFile }) => {
	const response = await apiRequest<any>({
		url: `/api/supplier/debtor/${params.debtorId}/shipment/${params.data.id}/file`,
		method: 'POST',
		data: params.data.formData
	});
	return response.data;
};

export const getFileFromShipment = async (data: IShipmentGetFile) => {
	const response = await apiRequest<Blob>({
		url: `/api/supplier/shipment/${data.shipmentId}/file/${data.fileId}`,
		method: 'GET',
		responseType: 'blob',
	});
	return response.data;
};


